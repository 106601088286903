import { Button, Grid, LinearProgress, makeStyles, Paper, TextField, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import React, { useCallback, useState, useEffect } from 'react'
import axios from 'axios'
import { parseJwt, expiresIn } from '../modules/jwt'
import { useSignIn } from 'react-auth-kit'
import Alert from '@material-ui/lab/Alert'
import { useIsAuthenticated } from 'react-auth-kit';
import { useHistory, useLocation } from 'react-router'
import { useGetBasket } from '../contexts/BasketContext'
import img from '../assets/images/login.png'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(8)
    },
    paper: {
        padding: theme.spacing(5),
        paddingTop: 0,
        borderRadius: '6px',
        position: 'relative'
    },
    button: {
        backgroundColor: green[500],
        color: 'white'
    },
    buttonBar: {
        marginTop: theme.spacing(4)
    },
    progress: {
        width: '100%'
    },
    img: {
        [theme.breakpoints.down("sm")]: {
            height: '100px',
        },
        [theme.breakpoints.up("sm")]: {
            height: '150px',
        },
        display: 'block',
        margin: '1rem auto',
        borderRadius: '8px',
        boxShadow: 'rgb(89 179 190 / 21%) 0px 0px 20px 11px'

    },
    inputContainer: {
        textAlign: 'center'
    }

}))

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}



function Login() {


    const classes = useStyles()
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [loading, setloading] = useState(false);
    const [loginError, setloginError] = useState(false)
    const [emailError, setemailError] = useState({ value: false, message: '' });
    const [passwordError, setpasswordError] = useState({ value: false, message: '' });

    const signIn = useSignIn()
    const isAuthenticated = useIsAuthenticated()()
    const history = useHistory()

    const { getBasket } = useGetBasket()
    let query = useQuery();


    useEffect(() => {
        if (isAuthenticated) {
            history.push('/')
        }

    }, []);

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                await getBasket()
                const redirectTo = query.get("redirectTo");
                if (redirectTo) {
                    history.push(redirectTo)
                } else {
                    history.push('/')
                }
            }
        })()
    }, [getBasket, history, isAuthenticated, query]);

    const handelClick = useCallback(
        () => {
            history.push('/signUp')
        },
        [],
    )

    const handelClickForgottenPassword = () => {
        history.push('/forgotten-password')
    }

    const handelClickLogin = useCallback(
        async () => {
            try {
                setloginError(false)
                if (!email) {
                    setemailError({ value: true, message: 'Champ requis' })
                }
                if (!password) {
                    setemailError({ value: true, message: 'Champ requis' })
                }
                setloading(true)
                const res = await axios.post(process.env.REACT_APP_BASE_API + '/login', { email, password })

                if (res.status === 200) {
                    const tokendecoded = parseJwt(res.data.token)
                    const tokenExpiresIn = expiresIn(tokendecoded.exp)

                    signIn({
                        token: res.data.token,
                        expiresIn: tokenExpiresIn,
                        tokenType: "Bearer",
                        authState: tokendecoded.data
                    })

                }
            } catch (e) {
                setloginError(true)
                setemail('')
                setpassword('')
            }
            setloading(false)
        },
        [email, password, signIn],
    )


    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                {loading ? <LinearProgress className={classes.progress} /> : null}
                <Grid container direction='column'>
                    <Typography color='primary' align='center' style={{ marginTop: '1rem' }} variant="h4" gutterBottom>
                        Bienvenue
                    </Typography>
                </Grid>


                <Grid container spacing={2} justify='center'>
                    <Grid item xs={12} md={6} className={classes.inputContainer}>
                        <TextField error={emailError.value} helperText={emailError.message || ''} label="E-mail" value={email} onChange={(e) => setemail(e.target.value)}></TextField>
                    </Grid>


                    <Grid item xs={12} md={6} className={classes.inputContainer}>
                        <TextField type='password' error={passwordError.value} helperText={passwordError.message || ''} label="Mot de passe" value={password} onChange={(e) => setpassword(e.target.value)}></TextField>
                    </Grid>

                </Grid>
                <Grid container style={{ marginTop: '2rem' }} >
                    <Grid item xs={12}>
                        <Typography style={{ cursor: 'pointer' }} onClick={handelClick} variant="caption" display="block" gutterBottom>
                            <span style={{ fontWeight: 'bold', color: '#3a7297' }}>Créer un compte</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ cursor: 'pointer' }} onClick={handelClickForgottenPassword} variant="caption" display="block" gutterBottom>
                            <span style={{ fontWeight: 'bold', color: '#3a7297' }}>Mot de passe oublié ?</span>
                        </Typography>
                    </Grid>
                </Grid>
                {loginError ? <Alert style={{ marginTop: '1rem' }} severity="error">E-mail ou mot de passe invalide</Alert> : null}
                <Grid container justify='flex-end' className={classes.buttonBar}>
                    <Button color={'primary'} disabled={!email || !password} onClick={handelClickLogin} variant='contained'>Connexion</Button>
                </Grid>

            </Paper>
        </div>
    )
}

export default Login
