import React, { useState, useEffect } from 'react'
import { AppBar, Box, Grid, Paper, Tab, Tabs } from '@material-ui/core'
import ContactModelEditor from './ContactModelEditor';
import { useGetContactModels } from '../../contexts/ContactModelsContext';




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}


function ClientContactControl() {


    const [selectedTab, setselectedTab] = useState(0)

    const { getContactModels } = useGetContactModels()

    useEffect(() => {
        (async () => {
            await getContactModels()
        })()
    }, [])
    const handleChange = (e, newTab) => {
        setselectedTab(newTab)
    }

    return (
        <Grid container justify="center" >
            <Grid item xs={12} sm={10}>
                <Paper elevation={0}>

                    <AppBar position="static">
                        <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Valdation commande" />
                            <Tab label="Valdation devis" />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={selectedTab} index={0}>

                        <ContactModelEditor modelName={'OrderRegistrationCLient'} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>

                        <ContactModelEditor modelName={'EstimateRegistrationCLient'} />
                    </TabPanel>



                </Paper>
            </Grid>
        </Grid>
    )
}

export default ClientContactControl