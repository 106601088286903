import React, { useCallback, useEffect, useContext, useState, useMemo } from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import DatePicker from "react-datepicker";
import { CompactPicker } from 'react-color';

import { DataGrid, GridFilterToolbarButton, getGridDateOperators, getGridStringOperators } from '@material-ui/data-grid';
import { Backdrop, Button, Checkbox, CircularProgress, Divider, Fade, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, LinearProgress, makeStyles, MenuItem, Paper, Popper, Radio, RadioGroup, Select, Tooltip, Typography } from '@material-ui/core';
import { useGet, usePost } from '../../modules/request';
import { OrderContext, useGetMyOrder, useGetOrders, useUpdateOrder } from '../../contexts/OrderContext';

import GetAppIcon from '@material-ui/icons/GetApp';
import DialogShowClientInformation from '../Dialog/DialogShowClientInformation'
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { useAuthUser } from 'react-auth-kit'
import { useHistory, useLocation } from 'react-router';
import { displayFormatedPrice } from '../../modules/utils';

import "react-datepicker/dist/react-datepicker.css";
import ModalGenInvoice from '../Dialog/ModalGenInvoice';
import DialogShowBasketsDetails from '../Dialog/DialogShowBasketsDetails';
import { useMaterialDetails } from '../../hooks/useMaterialDetails';

const useStyle = makeStyles((theme) => ({
    root: {
        '& .MuiDataGrid-filterPanelOperators': {
            display: 'none',
        },
    },
    buttonAddPanels: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    }
}))

function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
}

const stringColumnType = {
    extendType: 'string',
    filterOperators: getGridStringOperators()
        .filter((operator) => operator.value === 'contains')
        .map((operator) => {
            return {
                ...operator,

            };
        }),
};

const AddPanelModal = ({ open, handleClose, handleNesting, panels, setPanels, ordersToNesting, orderColors, setOrderColors, colorsAuto, setColorsAuto, isNesting, orientation, setOrientation }) => {

    const classifyContentsByMaterialAndThickness = (data) => {
        const result = {};

        data.forEach(order => {
            order.content.filter((c) => c.type !== 'accessory').forEach(item => {
                // Créer l'identifiant unique pour la combinaison materialId et thicknessId
                const id = `${item.materialName}|${item.shadeName}|${item.thickness}`;

                // Initialiser le groupe s'il n'existe pas
                if (!result[id]) {
                    result[id] = { id, contents: [] };
                }

                // Ajouter l'élément au groupe correspondant
                result[id].contents.push(item);
            });
        });

        // Convertir le résultat en tableau
        return Object.values(result);
    }

    const [pickerVisibleFor, setPickerVisibleFor] = useState(null);

    const { getDetails } = useMaterialDetails();

    const classes = useStyle();

    const handleAddPanel = (id) => {

        let newPanels = [...panels];
        newPanels = newPanels.map(panel => panel.id === id ? { ...panel, pannels: [...panel.pannels, { height: '', width: '', quantity: 1 }] } : panel)

        setPanels(newPanels);

    };

    const handleChange = (id, index, field, value) => {
        let newPanels = [...panels];
        newPanels = newPanels.map(panel => panel.id === id ? { ...panel, pannels: panel.pannels.map((p, i) => i === index ? { ...p, [field]: value } : p) } : panel)

        setPanels(newPanels);
    };

    const handleRemovePanel = (id, index) => {
        let newPanels = [...panels];
        newPanels = newPanels.map(panel => panel.id === id ? { ...panel, pannels: panel.pannels.filter((p, i) => i !== index) } : panel)

        setPanels(newPanels);
    };

    const isCalpinerDisabled = () =>
        panels.some(
            (panel) => panel.pannels.some(p => p.height === undefined || p.width === undefined)
        );

    const handleColorChange = (color, orderId) => {
        setOrderColors({ ...orderColors, [orderId]: color.hex });
    };

    const togglePicker = (orderId) => {
        setPickerVisibleFor(orderId);
    };
    useEffect(() => {

        const contentsByMaterialAndThickness = classifyContentsByMaterialAndThickness(ordersToNesting);

        const pannels = [];
        contentsByMaterialAndThickness.forEach(({ id }) => {
            pannels.push({
                id,
                pannels: [
                    { height: 1220, width: 3000, quantity: 100 }
                ]
            })
        })

        setPanels(pannels)

    }, [ordersToNesting]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            {isNesting && <LinearProgress />}
            <DialogContent>
                <Grid container justify='center'>
                    <Typography align="center" variant="h6" gutterBottom>
                        Ajouter des panneaux
                    </Typography>

                </Grid>

                <Grid container>
                    {panels.map(({ id, pannels }) => {
                        const [materialName, shadeName, thicknessName] = id.split('|')

                        //const { material, shade, thickness } = getDetails({ materialId, shadeId, thicknessId });
                        return <Grid key={id} item style={{ marginBottom: '1rem' }}>
                            <Typography variant="button">{`${materialName} - ${shadeName} - ${thicknessName} mm`}</Typography>
                            {
                                pannels.map((panel, index) =>
                                (
                                    <Grid container key={index} spacing={2} alignItems="center">
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Hauteur"
                                                type="number"
                                                value={panel.height}
                                                onChange={(e) => handleChange(id, index, 'height', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Largeur"
                                                type="number"
                                                value={panel.width}
                                                onChange={(e) => handleChange(id, index, 'width', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Quantité"
                                                type="number"
                                                value={panel.quantity}
                                                onChange={(e) => handleChange(id, index, 'quantity', +e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Grid container>
                                                <IconButton
                                                    onClick={() => handleAddPanel(id, index)}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                                <IconButton
                                                    disabled={pannels.length < 2}
                                                    onClick={() => handleRemovePanel(id, index)}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Grid>


                                        </Grid>

                                    </Grid>
                                ))
                            }
                        </Grid>
                    }
                    )}
                </Grid>

                <Divider className={classes.divider} />

                <Typography align="center" variant="h6" gutterBottom>
                    Couleurs
                </Typography>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={colorsAuto}
                            onChange={(e) => setColorsAuto(e.target.checked)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Couleurs auto"
                />

                {!colorsAuto && ordersToNesting.map(order => (
                    <Grid
                        key={order.id}
                        container
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        style={{ marginBottom: '10px' }}
                    >
                        <Grid item>
                            <Typography variant="button" gutterBottom>{order.ref}</Typography>
                        </Grid>
                        <Grid item style={{ position: 'relative' }}>
                            <div
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    backgroundColor: orderColors[order.ref] || '#000000',
                                    cursor: 'pointer',
                                }}
                                onClick={() => togglePicker(order.ref)}
                            />

                        </Grid>



                    </Grid>
                ))}

                <Typography align="center" variant="h6" gutterBottom>
                    Orientation
                </Typography>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="orientation" name="orientation" value={orientation} onChange={(e) => setOrientation(e.target.value)}>
                        <FormControlLabel value="a" control={<Radio />} label="Auto" />
                        <FormControlLabel value="h" control={<Radio />} label="Horizontal" />
                        <FormControlLabel value="v" control={<Radio />} label="Vertical" />
                    </RadioGroup>
                </FormControl>
                <Backdrop className={classes.backdrop} open={pickerVisibleFor} onClick={() => setPickerVisibleFor(null)}>
                    <CompactPicker
                        color={orderColors[pickerVisibleFor] || '#000000'}
                        onChangeComplete={(color) => handleColorChange(color, pickerVisibleFor)}
                    />
                </Backdrop>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fermer
                </Button>
                <Button disabled={isCalpinerDisabled()} onClick={handleNesting} color="primary" variant="contained">
                    Calpiner
                </Button>
            </DialogActions>
        </Dialog>
    );
}


function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function OrdersControl({
    mode = 'admin'
}) {
    const history = useHistory()
    const auth = useAuthUser()

    useEffect(() => {
        if (mode === 'admin' && auth()?.role !== 0) {
            history.push('/')
        }
    }, []);

    const [orders, dispatch] = useContext(OrderContext);
    const [page, setPage] = useState(0);
    const [clientToShow, setclientToShow] = useState(null)
    const [orderToShow, setorderToShow] = useState(null)
    const [showModalClientInfos, setshowModalClientInfos] = useState(false)
    const [showModalFormsInfos, setshowModalFormsInfos] = useState(false)
    const [ordersToNesting, setOrdersToNesting] = useState([]);
    const [isNesting, setIsNesting] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [panels, setPanels] = useState([]);
    const [orientation, setOrientation] = useState('a');
    const [orderColors, setOrderColors] = useState({});
    const [colorsAuto, setColorsAuto] = useState(true);
    const [filterModel, setFilterModel] = useState([]);
    const [sortingModel, setSortingModel] = useState([]);
    const [invoicesCreated, setInvoicesCreated] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isTransfertToInvoice, setIsTransfertToInvoice] = useState(false);
    const [showModalGenInvoice, setShowModalGenInvoice] = useState(false);
    const [ordersToInvoice, setOrdersToInvoice] = useState([]);
    const [ordersToSheatProd, setOrdersToSheatProd] = useState([]);
    const [isSheatProd, setIsSheatProd] = useState(false)
    const [openMaterialDetails, setOpenMaterialDetails] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [materialdetails, setMaterialdetails] = useState(null)

    const query = useQuery();
    const classes = useStyle()
    const get = useGet()
    const post = usePost()
    const { updateOrder } = useUpdateOrder();
    const [snackbar, dispatchSnackbar] = useContext(SnackbarContext)
    const { getDetails } = useMaterialDetails();


    const isUser = useMemo(() => mode === 'user', [mode])

    const options = { filterModel, page: page + 1, pageSize: 25 }

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setColorsAuto(true);
        setOrderColors({});
        setOrientation('a');
        setPanels([]);
        setOrdersToNesting([]);

    };

    useEffect(() => {

        if (!colorsAuto) {
            const keys = Object.keys(orderColors)
            const newOrderColors = {};

            ordersToNesting.forEach(order => {
                const key = keys.find(key => key === order.ref)
                if (key) {
                    newOrderColors[order.ref] = orderColors[key]
                } else {
                    newOrderColors[order.ref] = '#CCCCCC'
                }
            })
            setOrderColors(newOrderColors)
        }

    }, [colorsAuto])

    const hanclickShowInfoClient = useCallback(
        (client) => {
            setclientToShow(client)
            setshowModalClientInfos(true)
        },
        [],
    )
    const handleCloseInfoClient = useCallback(
        () => {
            setshowModalClientInfos(false)
        },
        [],
    )

    const handelClickShowInfoForms = (order) => {
        setorderToShow(order)
        setshowModalFormsInfos(true)
    }



    const handleCloseInfoForms = useCallback(
        () => {
            setshowModalFormsInfos(false)
            setorderToShow(null)
        },
        [],
    )

    const handleCloseGenInvoice = () => setShowModalGenInvoice(false);


    const handleNesting = async () => {

        try {
            setIsNesting(true);
            const datas = {
                ordersToNesting,
                orientation,
                panels
            };

            if (!colorsAuto) {
                datas.orderColors = orderColors;
            }

            const response = await post('/calculate/nesting', datas, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'imbrication.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Calpinage effectué',
                    type: 'success',
                    duration: 4000
                }
            });
        } catch (e) {
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Erreur lors du calpinage',
                    type: 'error',
                    duration: 4000
                }
            });
        } finally {
            setIsNesting(false);
            setOpenModal(false);
            setOrientation('a');
            setPanels([]);
            setOrderColors({});
            setOrdersToNesting([]);
        }
    }


    const handleCheckNesting = (event, order) => {
        if (event.target.checked) {
            setOrdersToNesting([...ordersToNesting, order])
        } else {
            setOrdersToNesting([...ordersToNesting.filter(o => o.id !== order.id)])
        }
    }

    const handleTransfertToInvoice = async () => {

        try {
            setIsTransfertToInvoice(true);

            const createdInvoices = [];

            for (const order of ordersToInvoice) {
                const response = await post(`/order/${order.id}/invoice`);
                if (response?.data?.message) {
                    createdInvoices.push(response.data.message)
                }
            }

            setInvoicesCreated(createdInvoices);

            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Passage en commande effectué',
                    type: 'success',
                    duration: 4000
                }
            });
            setShowModalGenInvoice(true);
        } catch (e) {
            console.log(e);
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Erreur lors de l\'édition',
                    type: 'error',
                    duration: 4000
                }
            });
        } finally {
            setIsTransfertToInvoice(false);
            setOrdersToInvoice([]);
            getOrder(options)
        }
    }

    const downloadPDF = async (invoice) => {
        try {
            setIsDownloading(true);
            const response = await post('/calculate/invoice', { invoiceId: invoice._id }, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `facture-${invoice.ref}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);


            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Edition effectué',
                    type: 'success',
                    duration: 4000
                }
            });
        } catch (e) {
            console.log(e);
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Erreur lors de l\'édition',
                    type: 'error',
                    duration: 4000
                }
            });
        } finally {
            setIsDownloading(false);


        }
    }

    const handleGenerateSheatProd = async () => {
        try {
            setIsSheatProd(true);
            for (const order of ordersToSheatProd) {
                const response = await get(`/calculate/sheatprod/${order.id}`, {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `fiche-production-${order.ref}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }



            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Edition effectué',
                    type: 'success',
                    duration: 4000
                }
            });
        } catch (e) {
            console.log(e);
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Erreur lors de l\'édition',
                    type: 'error',
                    duration: 4000
                }
            });
        } finally {
            setIsSheatProd(false);
            setOrdersToSheatProd([])

        }
    }



    const handleChecktoInvoice = (event, order) => {
        if (event.target.checked) {
            setOrdersToInvoice([...ordersToInvoice, order])
        } else {
            setOrdersToInvoice([...ordersToInvoice.filter(o => o.id !== order.id)])
        }
    }

    const handleCheckSheatProd = (event, order) => {
        if (event.target.checked) {
            setOrdersToSheatProd([...ordersToSheatProd, order])
        } else {
            setOrdersToSheatProd([...ordersToSheatProd.filter(o => o.id !== order.id)])
        }
    }


    const renderNestingHeaderName = () => {
        return <>
            {ordersToNesting.length ? <div>
                <IconButton onClick={handleOpenModal} color="secondary" variant="contained" size="small">
                    <CheckIcon color='primary' />

                </IconButton>
            </div> : 'Calpiner'}
        </>
    }

    const renderToInvoiceHeaderName = () => {
        return <>
            {ordersToInvoice.length ? <div>
                <IconButton onClick={handleTransfertToInvoice} color="secondary" variant="contained" size="small">
                    <CheckIcon color='primary' />
                </IconButton>
            </div> : 'Transfert en facture'}
        </>
    }

    const renderSheatProdHeaderName = () => {
        return <>
            {isSheatProd ? <CircularProgress size={15} /> : ordersToSheatProd.length ? <div>
                <IconButton onClick={handleGenerateSheatProd} color="secondary" variant="contained" size="small">
                    <CheckIcon color='primary' />
                </IconButton>
            </div> : 'Fiche Production'}
        </>
    }

    const handleEditCellChangeCommitted = ({ id, field, props }) => {

        let value = props.value;
        if (field === 'deliveryPrice') {
            value = +value;
        }
        updateOrder(id, { [field]: value })
    }

    const handleEnterMaterialsdetails = (e, materials) => {
        setOpenMaterialDetails(true)
        setAnchorEl(e.target);
        setMaterialdetails(materials)

    }

    const handleLeaveMaterialsdetails = () => {
        setOpenMaterialDetails(false)
        setAnchorEl(null);
        setMaterialdetails(null)
    }

    const columnsdef = [
        {
            field: "addToNesting",
            headerName: renderNestingHeaderName(),
            sortable: false,
            width: 100,
            hide: isUser,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: params => {
                let disableNesting = false;
                if (params.row?.source === 'pliageonline') {
                    disableNesting = params.row?.content?.every(basket => ![1, 2, 3].includes(+basket?.idExt))
                }
                return !disableNesting ? <Checkbox
                    onChange={(event) => handleCheckNesting(event, params.row)}
                    checked={ordersToNesting.some(order => order.id === params?.row?.id)}
                    color="primary"
                />
                    : null;
            }
        },
        {
            field: "addToSheatProd",
            headerName: renderSheatProdHeaderName(),
            sortable: false,
            width: 100,
            hide: isUser,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: params => {
                let disableSheatProd = false;
                if (params.row?.source === 'pliageonline') {
                    disableSheatProd = params.row?.content?.every(basket => ![1, 2, 3].includes(+basket?.idExt))
                }
                return !disableSheatProd ? <Checkbox
                    onChange={(event) => handleCheckSheatProd(event, params.row)}
                    checked={ordersToSheatProd.some(order => order.id === params?.row?.id)}
                    color="primary"
                />
                    : null;
            }
        },
        {
            field: "toInvoice",
            headerName: renderToInvoiceHeaderName(),
            sortable: false,
            flex: 1,
            hide: isUser,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: params =>
                !params.row?.invoice ? <Checkbox
                    onChange={(event) => handleChecktoInvoice(event, params.row)}
                    checked={ordersToInvoice.some(order => order.id === params?.row?.id)}
                    color="primary"
                /> : null

        },
        { field: 'ref', headerName: 'REF', disableColumnMenu: true, flex: 1, valueGetter: ({ row }) => row.clientRef || row.ref },
        {
            field: 'materials',
            headerName: 'Matière',
            disableColumnMenu: true,
            flex: 1,
            renderCell: ({ row }) => {
                const result = [];
                row.content.filter((c) => c.type !== 'accessory').forEach(item => {

                    const id = `${item.materialName}|${item.shadeName}|${item.thickness}`;

                    if (!result.includes(id)) {
                        result.push(id);
                    }

                });



                const materials = result.map(id => {
                    const [material, shade, thickness] = id.split('|');
                    //const { material, shade, thickness } = getDetails({ materialId, shadeId, thicknessId });
                    return `${material} ${shade} ${thickness}`
                });

                if (result.length > 1) {

                    return <div style={{ cursor: "pointer" }} onMouseEnter={(e) => handleEnterMaterialsdetails(e, materials)} onMouseLeave={handleLeaveMaterialsdetails} ><p>Multi</p></div>

                }
                return <div style={{ cursor: "pointer" }} onMouseEnter={(e) => handleEnterMaterialsdetails(e, materials)} onMouseLeave={handleLeaveMaterialsdetails} ><p>{materials}</p></div>

            }
        },
        {
            field: "downloadInvoice",
            headerName: 'Facture',
            sortable: false,
            flex: 1,
            hide: !isUser,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: params =>
                params.row?.invoice?.ref ? isDownloading ? <CircularProgress size={15} /> : <IconButton onClick={() => downloadPDF(params.row.invoice)} color="secondary" variant="contained" size="small"><GetAppIcon /></IconButton> : null
        },
        {
            field: 'invoiceRef',
            headerName: 'REF facture',
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params) => {
                return params.row?.invoice?.ref || '-';
            },
        },
        {
            field: 'source',
            headerName: 'Source',
            disableColumnMenu: true,
            hide: isUser,
            width: 100,
            renderCell: (params) => {
                return params.row.source === 'pliageonline' ? 'P' : 'L';
            },
        },
        {
            field: "orderDate",
            headerName: "Date",
            type: 'dateTime',
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    convertDate(params.row.orderDate)
                );
            },
        },
        {
            field: "content",
            headerName: "Contenu",
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            menu: false,
            disableClickEventBubbling: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Grid container alignItems='center' justify='space-between'>
                        <Grid item style={{ cursor: "pointer" }} onClick={() => handelClickShowInfoForms(params.row)}>
                            {`${params.row.content.length} modèle(s)`}
                        </Grid>

                    </Grid>
                );
            }
        },
        {
            field: "user.discount",
            headerName: "Remise (%)",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            type: 'number',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    params?.row?.client?.discount || 0
                );
            },

        },
        {
            field: "client",
            headerName: "Client",
            sortable: true,
            flex: 1,
            hide: isUser,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Grid container alignItems='center' justify="space-between">
                        <Grid item style={{ cursor: "pointer" }} onClick={() => hanclickShowInfoClient(params.row.client)}>
                            {`${params.row.client.lastName} ${params.row.client.firstName}`}
                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: "amount",
            headerName: "Prix HT commande",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            type: 'number',
            renderCell: (params) => {
                return (
                    <div>{`${params.row.amount} €`}</div>
                );
            },

        },
        {
            field: "deliveryPrice",
            headerName: "Prix HT livraison",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            editable: true,
            type: 'number',
            renderCell: (params) => {
                return (
                    <div>{`${params.row.deliveryPrice} €`}</div>
                );
            },

        },
        {
            field: "totalPriceWithVat",
            headerName: "Prix TTC",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            type: 'number',
            renderCell: (params) => {
                return (
                    <div>{`${params.row.totalPriceWithVat} €`}</div>
                );
            },

        },
    ];

    const { getOrder } = useGetOrders();
    const { getMyOrder } = useGetMyOrder();
    const getRows = useMemo(() => orders.map(order =>
    ({
        id: order._id,
        ref: order.ref,
        orderDate: order.orderDate,
        content: order.baskets,
        client: order.user,
        amount: displayFormatedPrice(order.amount),
        deliveryPrice: displayFormatedPrice(order.deliveryPrice) || null,
        paymentProcessed: order.paymentProcessed,
        totalPriceWithVat: displayFormatedPrice(order.total),
        pi: order.pi,
        deliveryMethode: order.deliveryMethode,
        source: order.source,
        invoice: order.invoice || null,
        clientRef: order.clientRef,

    })
    ), [orders]);

    useEffect(() => {

        (async () => {
            if (mode === 'user') {
                getMyOrder({ filterModel, page: page + 1, pageSize: 25 });
            } else {

                const options = { filterModel, page: page + 1, pageSize: 25 }
                if (query.get('source')) {
                    options.source = query.get('source');
                }
                await getOrder(options);
            }

        })()
    }, [page, filterModel, query]);

    useEffect(() => {

        (async () => {

            getOrder({ sortingModel, page: page + 1, pageSize: 25 });

        })()
    }, [page, sortingModel]);

    function OrderDatePicker({ item, applyValue }) {
        return <>
            <InputLabel shrink >Date</InputLabel>
            <div style={{ marginTop: '16px' }}>
                <DatePicker selected={item.value} onChange={(date) => applyValue({ ...item, value: date })} />
            </div>


        </>
    }

    const NoOperatorInputComponent = (props) => {
        const { applyValue, item, ...others } = props;
        return <>
            <InputLabel shrink >Source</InputLabel>
            <Select

                value={item.value}
                onChange={(e) => applyValue({ ...item, value: e.target.value })}

            >
                <MenuItem key="logiweb" value="logiweb" >
                    Logiweb
                </MenuItem>
                <MenuItem key="pliageonline" value="pliageonline" >
                    pliageonline
                </MenuItem>

            </Select>
        </>

    };

    const columns = () => {
        if (columnsdef.length > 0) {

            const orderDateColumn = columnsdef.find((column) => column.field === 'orderDate');
            const orderDateColIndex = columnsdef.findIndex((col) => col.field === 'orderDate');

            const sourceColumn = columnsdef.find((column) => column.field === 'source');
            const sourceColIndex = columnsdef.findIndex((col) => col.field === 'source');

            const orderDateFilterOperators = getGridDateOperators().map(
                (operator) => ({
                    ...operator,
                    InputComponent: OrderDatePicker,
                }),
            );

            const sourceFilterOperators = getGridStringOperators().filter(
                (operator) => {
                    return operator.value === 'equals'
                }
            ).map(
                (operator) => ({
                    ...operator,
                    InputComponent: NoOperatorInputComponent,
                }),
            );


            const mappedColumns = columnsdef.map((dataColumn) => {
                const mappedColumn = {
                    ...dataColumn,
                };

                if (mappedColumn.field === 'ref' || mappedColumn.field === 'client' || mappedColumn.field === 'materials' || mappedColumn.field === 'invoiceRef') {
                    mappedColumn.type = 'str';
                }
                return mappedColumn;
            });
            mappedColumns[orderDateColIndex] = {
                ...orderDateColumn,
                filterOperators: orderDateFilterOperators,
            };

            mappedColumns[sourceColIndex] = {
                ...sourceColumn,
                filterOperators: sourceFilterOperators,
            };


            return [...mappedColumns];
        }
        return [];
    };

    const handleFilterModelChange = (params) => {
        const filters = params.filterModel.items.map((item) => {
            return {
                columnField: item.columnField,
                operatorValue: item.operatorValue,
                value: item.value,
            };
        });

        setFilterModel(filters);
    };


    const handleSortingModelChange = (params) => {
        const sorting = params.sortModel.map((item) => {
            return {
                field: item.field,
                sort: item.sort,
            };
        });

        setSortingModel(sorting);
    };

    const meterialDetailsList = useMemo(() => {
        if (materialdetails?.length) {
            return <ul>{materialdetails.map(m => <li>{m}</li>)}</ul>
        }
        return null
    }, [materialdetails]);


    return (
        <Grid container justify="center" className={classes.root}>

            <Grid item xs={12} sm={11}>

                <Paper elevation={0} style={{ paddingTop: '1rem' }}>
                    <Typography align="center" variant="h5">Commandes</Typography>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            pageSize={25}
                            pagination
                            filterMode="server"
                            //sortingMode="server"
                            rows={getRows}
                            columns={columns()}
                            onEditCellChangeCommitted={handleEditCellChangeCommitted}
                            localeText={{
                                toolbarFilters: 'Filtrer',
                                filterOperatorContains: 'contient',
                                filterOperatorIs: 'égale',
                                filterOperatorEquals: 'égale',
                                filterOperatorNot: 'différent',
                                filterOperatorAfter: '>',
                                filterOperatorOnOrAfter: '>=',
                                filterOperatorBefore: '<',
                                filterOperatorOnOrBefore: '<='

                            }}
                            components={!isUser && {
                                Toolbar: GridFilterToolbarButton,
                            }}
                            onPageChange={(params) => setPage(params.page)}
                            onFilterModelChange={handleFilterModelChange}
                            onSortModelChange={handleSortingModelChange}
                            columnTypes={{ str: stringColumnType }}
                        />
                    </div>

                </Paper>
            </Grid>
            <DialogShowClientInformation open={showModalClientInfos} handleClose={handleCloseInfoClient} client={clientToShow} />
            {orderToShow && <DialogShowBasketsDetails type={'order'} open={showModalFormsInfos} handleClose={handleCloseInfoForms} estimate={orderToShow} />}
            <ModalGenInvoice open={showModalGenInvoice} setOpen={handleCloseGenInvoice} invoicesCreated={invoicesCreated} />
            {openModal && <AddPanelModal
                open={openModal}
                handleClose={handleCloseModal}
                handleNesting={handleNesting}
                panels={panels}
                setPanels={setPanels}
                ordersToNesting={ordersToNesting}
                orderColors={orderColors}
                setOrderColors={setOrderColors}
                colorsAuto={colorsAuto}
                setColorsAuto={setColorsAuto}
                isNesting={isNesting}
                orientation={orientation}
                setOrientation={setOrientation}
            />}
            {openMaterialDetails && <Popper open={openMaterialDetails} anchorEl={anchorEl} placement="top" transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper style={{ padding: '1px 20px 1px 1px', background: "grey", color: 'white', position: 'relative', top: '-15px' }}>
                            {meterialDetailsList}
                        </Paper>
                    </Fade>
                )}
            </Popper>}
        </Grid>
    )
}

export default OrdersControl
