import React, { useContext, useReducer } from 'react';
import { useGet, usePost, usePut } from '../modules/request';
import { BasketContext } from './BasketContext';


const EstimateContext = React.createContext([[], () => { }]);

const initialState = []

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ESTIMATES":
      return action.payload;
    case "SET_ESTIMATE":
      return state.map(estimate => estimate._id !== action.payload.id ? estimate : action.payload.estimate);
    case "UPDATE_ESTIMATE":
      return state.map(estimate => estimate._id !== action.payload.id ? estimate : { ...estimate, [action.payload.key]: [action.payload.value] });
    case "REMOVE_ESTIMATE":
      return state.filter(estimate => estimate._id !== action.payload.id);
    default:
      throw new Error();
  }
};


const useGetEstimates = () => {
  const [state, dispatch] = useContext(EstimateContext);
  const get = useGet();
  const put = usePut();

  return {
    getEstimates: async (options) => {

      const filters = options.filterModel;
      const filtersJSON = JSON.stringify(filters);

      let query = `?filters=${encodeURIComponent(filtersJSON)}`;

      const res = await get(`/estimate${query}`)
      dispatch({
        type: 'SET_ESTIMATES',
        payload: res.data.message
      })
    },
  };
}

const useGetMyEstimates = () => {
  const [state, dispatch] = useContext(EstimateContext);
  const get = useGet()
  return {
    getMyEstimate: async ({ page, field, value }) => {
      const res = await get('/estimate/my')
      dispatch({
        type: 'SET_ESTIMATES',
        payload: res.data.message
      })
    },
  };
}

const usePostEstimate = () => {
  const [state, dispatch] = useContext(BasketContext);
  const post = usePost()
  return {
    postEstimate: async (data) => {
      try {
        const res = await post('/estimate', data)
        dispatch({
          type: 'SET_BASKET',
          payload: []
        })
        return res.data.message
      } catch (e) {
        return false
      }
    },
  };
}

const useUpdateEstimate = () => {
  const [, dispatch] = useContext(EstimateContext);
  const put = usePut();

  return {
    updateEstimate: async (id, datas) => {
      try {
        const response = await put(`/estimate/${id}`, datas);
        dispatch({
          type: 'SET_ESTIMATE',
          payload: { id, estimate: response.data.message },
        });
        return response.data.message;
      } catch (e) {
        return false;
      }
    },
  };
};


function EstimateProvider(props) {
  const [estimates, dispatch] = useReducer(reducer, initialState);

  return (
    <EstimateContext.Provider value={[estimates, dispatch]}>
      {props.children}
    </EstimateContext.Provider>
  );
};

export { EstimateContext, EstimateProvider, useGetEstimates, useGetMyEstimates, usePostEstimate, useUpdateEstimate };