import * as yup from 'yup';
import siret from 'siret'
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/



yup.addMethod(yup.string, "siret", function (errorMessage) {
    return this.test(`siret`, errorMessage, function (value) {
        const { path, createError } = this;

        return (
            siret.isSIRET(value) || createError({ path, message: errorMessage })
        );
    });
});

const MaterialSchema = yup.object().shape({
    name: yup.string().min(2, 'Doit contenir au moins 2 caractères').required(),
    density: yup.number().required().positive().typeError('Doit être un nombre positif'),
});

const ShadeSchema = yup.object().shape({
    name: yup.string().min(2).required()
})

const ShadeDefaultPriceSchema = yup.object().shape({
    value: yup.number()
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
})

const ThicknessSchema = yup.object().shape({
    pierceTime: yup.number().required().positive().typeError('Doit être un nombre positif'),
    cuttingSpeed: yup.number().required().positive().typeError('Doit être un nombre positif'),
    value: yup.number().required().positive(),
    pricePerKilo: yup.number().required().positive().typeError('Doit être un nombre positif')
})


const MaterialnameSchema = yup.object().shape({
    value: yup.string().min(2, 'Doit contenir au moins 2 caractères').required(),
});

const MaterialDensitySchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif'),

})

const MaterialPriceperKgSchema = yup.object().shape({
    value: yup.number().required().positive()
})

const ShadenameSchema = yup.object().shape({
    value: yup.string().min(2, 'Doit contenir au moins 2 caractères').required()
})
const ThicknessValueSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif'),
})

const ThicknessPierceTimeSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})
const ThicknessCuttingSpeedSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif'),
})

const ThicknessPricePerKiloSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const ThicknessFoldingTimeSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const rateSchema = yup.object().shape({
    value: yup.number().required().min(0).typeError('Doit être un nombre positif ou 0')
})

const minPlanCuttingSchema = yup.object().shape({
    value: yup.number().required().min(0).typeError('Doit être un nombre positif ou 0')
})

const costPerKmSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const maxLengthSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const machineMaxWidthSchemas = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const machineMaxLengthSchemas = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const foldingRateSchemas = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const minPlanFoldingSchemas = yup.object().shape({
    value: yup.number().required().min(0).typeError('Doit être un nombre positif ou 0')
})

const maxWeightPerOperatorSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const maxLengthPerOperatorSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const nbOperatorSchema = yup.object().shape({
    value: yup.number().integer().required().positive().typeError('Doit être un nombre positif')
})

const foldingMaxWidthSchemas = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const foldingMaxLengthSchemas = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const transportMaxWidthSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const transportMaxLengthSchemas = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const vatRateSchema = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

const UserLastNameSchemas = yup.object().shape({
    value: yup.string().min(2, 'Doit contenir au moins 2 caractères').required()
})

const UserFirstNameSchemas = yup.object().shape({
    value: yup.string().min(2, 'Doit contenir au moins 2 caractères').required()
})

const UserEmailSchemas = yup.object().shape({
    value: yup.string().email('Adresse e-mail invalide').required()
})

const UserPasswordSchemas = yup.object().shape({
    value: yup.string().required('mot de passe requis').matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+!*$@%_])([-+!*$@%_\w]{8,15})$/, 'mot passe non conforme')
})

const UserPhoneSchemas = yup.object().shape({
    value: yup.string().matches(phoneRegExp, 'Numero invalide')
})

const UserHouseNumberSchemas = yup.object().shape({
    value: yup.string().min(1, 'Doit contenir au moins 1 caractères').required()
})

const UserStreetSchemas = yup.object().shape({
    value: yup.string().min(1, 'Doit contenir au moins 1 caractères').required()
})

const UserCitySchemas = yup.object().shape({
    value: yup.string().min(1, 'Doit contenir au moins 1 caractères').required()
})

const ProSocialNameSchemas = yup.object().shape({
    value: yup.string().min(1, 'Doit contenir au moins 1 caractères').required()

})

const ProSiretSchemas = yup.object().shape({
    value: yup.string().siret('Doit être un numero de siret valide').required()
})

const ProTVANameSchemas = yup.object().shape({
    value: yup.string().min(1, 'Doit contenir au moins 1 caractères').required()

})

const UserZipCodeSchemas = yup.object().shape({
    value: yup.string().min(1, 'Doit contenir au moins 1 caractères').required()

})

const DeliveryTimeSchemas = yup.object().shape({
    value: yup.number().integer().required().positive('Doit être un nombre entier positif')
})

const AtLeaseTwoChars = yup.object().shape({
    value: yup.string().min(2, 'Doit contenir au moins 2 caractères').required(),
});

const CatalogSvgDatasSchemas = yup.object().shape({
    value: yup.string().min(2, 'Doit contenir au moins 2 caractères').required()
})

const cutSizeSchemas = yup.object().shape({
    value: yup.number()
        .required()
        .min(0, 'Doit être un nombre positif ou zéro.')
})

const invoiceNextReminderDateSchemas = yup.object().shape({
    value: yup.number().integer().required().positive('Doit être un nombre entier positif')
})

const MaxWidtdMaterialSchemas = yup.object().shape({
    value: yup.number().required().positive().typeError('Doit être un nombre positif')
})

export {
    MaterialSchema,
    ShadeSchema,
    ThicknessSchema,
    MaterialnameSchema,
    MaterialDensitySchema,
    MaterialPriceperKgSchema,
    ShadenameSchema,
    ThicknessValueSchema,
    ThicknessPierceTimeSchema,
    ThicknessCuttingSpeedSchema,
    ThicknessPricePerKiloSchema,
    ThicknessFoldingTimeSchema,
    rateSchema,
    costPerKmSchema,
    maxLengthSchema,
    minPlanCuttingSchema,
    machineMaxWidthSchemas,
    machineMaxLengthSchemas,
    foldingRateSchemas,
    minPlanFoldingSchemas,
    maxWeightPerOperatorSchema,
    maxLengthPerOperatorSchema,
    foldingMaxWidthSchemas,
    foldingMaxLengthSchemas,
    nbOperatorSchema,
    transportMaxWidthSchema,
    transportMaxLengthSchemas,
    vatRateSchema,
    UserLastNameSchemas,
    UserFirstNameSchemas,
    UserEmailSchemas,
    UserPasswordSchemas,
    UserPhoneSchemas,
    UserHouseNumberSchemas,
    UserStreetSchemas,
    UserCitySchemas,
    ProSocialNameSchemas,
    ProSiretSchemas,
    ProTVANameSchemas,
    UserZipCodeSchemas,
    DeliveryTimeSchemas,
    ShadeDefaultPriceSchema,
    AtLeaseTwoChars,
    CatalogSvgDatasSchemas,
    cutSizeSchemas,
    invoiceNextReminderDateSchemas,
    MaxWidtdMaterialSchemas
}