import { Paper, makeStyles, Typography, Grid, TextField, Button, Divider, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import React, { useState } from 'react'


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(5),
        borderRadius: '6px',
        position: 'relative'
    }
}))


function ForgottenPassword() {

    const classes = useStyles();

    const [mail, setmail] = useState('');
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleClickSend = async () => {
        if (mail) {
            try {
                setLoading(true);
                await axios.post(process.env.REACT_APP_BASE_API + '/user/forgot/password', {
                    email: mail,
                })

            } catch (e) {

            } finally {
                setSuccess(true);
                setmail('');
                setLoading(false);
            }
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Grid container direction='column' spacing={4}>
                    <Grid item>
                        <Typography align='center' variant="h5" color='primary' gutterBottom>
                            Mot de passe oublié
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align='center' variant="body2" gutterBottom>
                            Entrez votre adresse mail, pour recevoir un mail vous permetant de réinitialiser votre mot de passe.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container justify='center' alignItems='center' spacing={4}>
                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth value={mail} onChange={(e) => setmail(e.target.value)} label="E-mail" />
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} sm={4}>
                                <Button disabled={loading} onClick={handleClickSend} color='primary' variant='contained'>{loading && <CircularProgress style={{ marginRight: '5px' }} size={14} />} Envoyer</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {success === true && <Alert severity="success">Un email vous a été envoyé</Alert>}
                    {success === false && <Alert severity="error">Une erreur s'est produite</Alert>}
                </Grid>



            </Paper>
        </div>
    )
}

export default ForgottenPassword