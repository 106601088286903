import React, { useReducer } from 'react';
const PropertiesContext = React.createContext([[], () => { }]);

const initialState = {}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROPERTY":
      return action.payload
    default:
      throw new Error();
  }
};

const PropertiesProvider = (props) => {
  const [properties, dispatch] = useReducer(reducer, initialState);

  return (
    <PropertiesContext.Provider value={[properties, dispatch]}>
      {props.children}
    </PropertiesContext.Provider>
  );
}

export { PropertiesProvider, PropertiesContext };