import React, { useContext, useReducer } from 'react';
import { useGet, usePost, usePut } from '../modules/request';
import { BasketContext } from './BasketContext';


const OrderContext = React.createContext([[], () => { }]);

const initialState = []

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ORDERS":
      return [...action.payload]
    case "SET_ORDER":
      return state.map(order => order._id !== action.payload.id ? order : action.payload.order);
    case "UPDATE_ORDER":
      return state.map(order => order._id !== action.payload.id ? order : { ...order, [action.payload.key]: [action.payload.value] })
    default:
      throw new Error();
  }
};


const useGetOrders = () => {
  const [, dispatch] = useContext(OrderContext);
  const get = useGet()
  return {
    getOrder: async (options) => {
      const filters = options.filterModel;
      const filtersJSON = JSON.stringify(filters);
      const source = options.source;

      let query = `?filters=${encodeURIComponent(filtersJSON)}`;
      if (source) {
        query += `&source=${source}`;
      }


      const sorting = options.filterModel;

      if (sorting) {
        const sortingJSON = JSON.stringify(sorting);
        query += `&sort=${encodeURIComponent(sortingJSON)}`;
      }

      // Ajoutez les filtres en tant que paramètre de requête à l'URL
      const res = await get(`/order${query}`);
      dispatch({
        type: 'SET_ORDERS',
        payload: res.data.message
      })
    },
  };
}

const useGetMyOrder = () => {
  const [, dispatch] = useContext(OrderContext);
  const get = useGet()
  return {
    getMyOrder: async () => {
      const res = await get('/order/my')
      dispatch({
        type: 'SET_ORDERS',
        payload: res.data.message
      })
    },
  };
}

const usePostOrders = () => {
  const [, dispatch] = useContext(BasketContext);
  const post = usePost()
  return {
    postOrder: async (data) => {
      try {
        const res = await post('/order', data)
        dispatch({
          type: 'SET_BASKET',
          payload: []
        })
        return res.data.message
      } catch (e) {
        return false
      }
    },
  };
}

const useUpdateOrder = () => {
  const [, dispatch] = useContext(OrderContext);
  const put = usePut();

  return {
    updateOrder: async (id, datas) => {
      try {
        const response = await put(`/order/${id}`, datas);
        dispatch({
          type: 'SET_ORDER',
          payload: { id, order: response.data.message },
        });
        return response.data.message;
      } catch (e) {
        return false;
      }
    },
  };
};

function OrderProvider(props) {
  const [order, dispatch] = useReducer(reducer, initialState);

  return (
    <OrderContext.Provider value={[order, dispatch]}>
      {props.children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider, useGetOrders, usePostOrders, useGetMyOrder, useUpdateOrder };