import React from 'react'
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import { Grid, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const useStyle = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6)
    }
}))

function OrderResult({ estimate, cartType }) {

    const classes = useStyle()
    const theme = useTheme();
    return (

        <Paper elevation={0} className={classes.paper}>
            <Grid container justify='center'>
                {

                    <Grid item>
                        <Typography variant="h6" gutterBottom align='center'>
                            {`Votre ${cartType === 'order' ? 'commande' : 'devis'} a été ${cartType === 'order' ? 'enregistrée' : 'enregistré'} avec succès.`}
                        </Typography>
                        <CheckBoxRoundedIcon style={{ color: green[500], fontSize: 80, margin: `${theme.spacing(4)} auto`, display: 'block' }} />
                        {cartType === 'order' &&

                            <Typography variant="body1" gutterBottom align='center'>
                                Votre commande a été enregistrée, elle sera soumise à validation technique avant le déclenchement du paiement.
                            </Typography>
                        }

                    </Grid>

                }
            </Grid>
        </Paper>
    )
}

export default OrderResult
