import { Grid, TableCell, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useCallback } from 'react'
import AutoSelectTextField from '../../Input/AutoSelectTextField'

function CatalogdrawingPannelEntitiesListValue({ data, setData, entity }) {

    const handelChange = useCallback(
        (e) => {
            const value = e.target.value
            let error = ''
            if (e.target.value > 0) {

                if (entity.asMax && value > entity.max) error = `Doit être inférrieure à ${entity.max}`
                if (entity.asMin && value < entity.min) error = `Doit être suppérieure à ${entity.min}`


                if (entity.asMin && entity.asMax && value < entity.min && value > entity.max) {
                    error = `Doit être compris entre ${entity.min} et ${entity.max}`
                }

                setData({ ...data, value: Number(e.target.value), error })
            }
            else if (e.target.value === '') {
                error = ` `
                setData({ ...data, value: '', error })
            }
        },
        [data, entity.asMax, entity.asMin, entity.max, entity.min, setData],
    )

    const getSentence = useCallback(
        () => {
            let str = ''
            if (entity.asMin) str += "min " + entity.min
            if (str) str += ' - '
            if (entity.asMax) str += "max " + entity.max
            return str
        },
        [entity.asMax, entity.asMin, entity.max],
    )

    const getValue = () => data.value

    return data &&
        <TableCell>
            <AutoSelectTextField type="tel" onFocus={(e) => e.target.select()} error={data.error} helperText={data.error} value={getValue()} onChange={handelChange} id="outlined-basic" />
        </TableCell>

}

export default CatalogdrawingPannelEntitiesListValue
