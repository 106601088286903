import './App.css';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import DashboardControl from './components/DashboardControl';
import { MaterialsContext } from './contexts/MaterialsContext';
import EndUserInterfaceMain from './components/EndUser/EndUserInterfaceMain';
import { ConfiguratorContext } from './contexts/ConfiguratorContext';

import SignUpActivate from './components/SignUpActivate';
import SignUp from './components/SignUp';
import Login from './components/Login';
import NavBar from './components/NavBar';
import Logout from './components/Logout';
import { SnackbarContext } from './contexts/SnackbarContext';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import Cart from './components/Cart';
import IsAuth from './components/Auth/IsAuth';

import IsAuthAdmin from './components/Auth/IsAuthAdmin';
import OrdersControl from './components/Control/OrdersControl';
import { OrderProvider } from './contexts/OrderContext';
import { PropertiesContext } from './contexts/PropertiesContext';
import { UserProvider } from './contexts/UserContext';

import UsersControl from './components/Control/UsersControl';
import CartValidate from './components/CartValidate';
import CatalogControl from './components/Control/CatalogControl';
import Catalog from './components/EndUser/Catalog/Catalog';

import { useGet } from './modules/request';
import ForgottenPassword from './components/ForgottenPassword';
import ForgottenPasswordReset from './components/ForgottenPasswordReset';
import ClientContactControl from './components/Control/ClientContactControl';
import { ContactModelsProvider } from './contexts/ContactModelsContext';
import CatalogSvgControl from './components/Control/CatalogSvgControl';
import Profil from './components/Profil';
import EstimatesControl from './components/Control/EstimatesControl';
import { EstimateProvider } from './contexts/EstimateContext';
import InvoicesControl from './components/Control/InvoicesControl';
import { InvoiceProvider } from './contexts/InvoiceContext';
import AccessoryControl from './components/Control/AccessoryControl';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  bar: {
    background: 'white',
    boxShadow: "rgb(17 17 26 / 10%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 8px 24px, rgb(17 17 26 / 10%) 0px 16px 48px"

  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
}))


function Alert(props) {
  return <MuiAlert elevation={0} variant="filled" {...props} />;
}

function App() {

  const classes = useStyles();
  const get = useGet()

  const [, dispach] = useContext(MaterialsContext);
  const [, dispachConfigurator] = useContext(ConfiguratorContext);
  const [, dispatchProperties] = useContext(PropertiesContext);
  const [snackbar, dispatchSnackbar] = useContext(SnackbarContext)
  const [sessionId, setSessionId] = useState(null);



  const handleClose = useCallback(
    () => {
      dispatchSnackbar({
        type: 'SET_SNACKBAR',
        payload: {
          open: false,
          content: '',
          type: 'success',
          duration: 6000
        }
      })
    },
    [dispatchSnackbar],
  )

  useEffect(() => {

    (async () => {
      const materials = await get('/material')

      dispach({
        type: 'SET_MATERIALS',
        payload: materials.data.message
      })
      const res = await get('/propertie')
      dispatchProperties({
        type: 'SET_PROPERTY',
        payload: res.data.message[0] || {}
      })
    })()
  }, []);

  const handelClickUserInterface = useCallback(
    (e) => {
      e.stopPropagation()
      dispachConfigurator({
        type: 'SET_VALUE',
        payload: {
          key: 'selectedForm',
          data: null
        }
      })
    },
    [],
  )
  return (
    <div className="App" onClick={handelClickUserInterface} >
      {process.env.REACT_APP_IFRAME_MODE ? null : <NavBar />}
      <div className={classes.main}>

        <Switch>
          <Route path="/control/users">
            <UserProvider>
              <UsersControl />
            </UserProvider>

          </Route>
          <Route path="/control/estimates">
            <EstimateProvider>
              <EstimatesControl />
            </EstimateProvider>
          </Route>
          <Route path="/control/orders">
            <IsAuthAdmin>
              <OrderProvider>
                <OrdersControl />
              </OrderProvider>
            </IsAuthAdmin>
          </Route>
          <Route path="/control/invoices">
            <IsAuthAdmin>
              <InvoiceProvider>
                <InvoicesControl />
              </InvoiceProvider>
            </IsAuthAdmin>
          </Route>
          <Route path="/control/catalog">
            <IsAuthAdmin>
              <CatalogControl />
            </IsAuthAdmin>
          </Route>
          <Route path="/control/svg">
            <IsAuthAdmin>
              <CatalogSvgControl />
            </IsAuthAdmin>
          </Route>
          <Route path="/control/accessory">
            <IsAuthAdmin>
              <AccessoryControl />
            </IsAuthAdmin>
          </Route>
          <Route path="/control/clientContact">
            <ContactModelsProvider>
              <ClientContactControl />
            </ContactModelsProvider>
          </Route>
          <Route path="/control">
            <IsAuthAdmin>
              <DashboardControl />
            </IsAuthAdmin>
          </Route>
          <Route path="/profil">
            <IsAuth>
              <UserProvider>
                <OrderProvider>
                  <Profil />
                </OrderProvider>
              </UserProvider>
            </IsAuth>
          </Route>

          <Route path="/signup/activate/:token">
            <SignUpActivate />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/forgotten-password/reset/:token">
            <ForgottenPasswordReset />
          </Route>
          <Route path="/forgotten-password">
            <ForgottenPassword />
          </Route>

          <Route path="/catalog">
            <Catalog />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/cart/validate">
            <IsAuth redirectTo={'/cart/validate'}>
              <CartValidate />
            </IsAuth>
          </Route>
          <Route path="/cart">
            <UserProvider>
              <Cart />
            </UserProvider>
          </Route>
          <Route path="/">
            <IsAuth>
              <EndUserInterfaceMain />
            </IsAuth>
          </Route>

        </Switch>

      </div>
      <Snackbar open={snackbar.open} autoHideDuration={snackbar.duration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackbar.type}>
          {snackbar.content}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
