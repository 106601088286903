import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DialogValidation({ open, handleValidate, handleCancel }) {

    return (

        <Dialog
            open={open}
            onClose={handleCancel}
        >
            <DialogContent>
                <DialogContentText >
                    Etes vous sûr de vouloir continuer?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel} color="primary" autoFocus>
                    Annuler
                </Button>
                <Button variant="contained" onClick={handleValidate} color="primary">
                    Oui
                </Button>
            </DialogActions>
        </Dialog>

    );
}