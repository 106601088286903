import React, { useCallback, useContext, useState, useRef, useMemo } from 'react';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Checkbox,
    FormControlLabel,
    TextField,
    Box
} from '@material-ui/core';

import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import { useEffect } from 'react';
import { MaterialsContext } from '../../../contexts/MaterialsContext';

function ConfiguratorMenuLength({ readOnly = false, inputError, setinputError }) {
    const [configurator, dispachConfig] = useContext(ConfiguratorContext);
    const [materials] = useContext(MaterialsContext);
    const [showInput, setShowInput] = useState(false);
    const [options, setOptions] = useState([]);
    const inputRef = useRef();

    const maxValue = useMemo(() => Math.max(...options), [options])

    const onchange = useCallback(
        (e) => {
            dispachConfig({
                type: 'SET_VALUE',
                payload: {
                    key: 'size',
                    data: e.target.value
                }
            });
        },
        [dispachConfig]
    );

    const handleCheckboxChange = (event) => {
        setinputError('')
        onchange({ target: { value: null } });
        setShowInput(event.target.checked);
    };

    const handleInputChange = (event) => {
        setinputError('')
        const value = parseInt(event.target.value, 10)

        if (value > maxValue || value < 0) {
            setinputError(`Doit être entre ${maxValue} et ${0}`)
        }
        onchange({ target: { value } });
    };

    useEffect(() => {
        if (showInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showInput]);

    useEffect(() => {
        const selectedMaterial = materials.find(m => m._id === configurator.materialId);
        setOptions(selectedMaterial?.availableWidth || []);
    }, [configurator.materialId, materials])



    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                {showInput ? (
                    <TextField
                        inputRef={inputRef}
                        label="Votre longueur"
                        type="number"
                        value={configurator.size}
                        onChange={handleInputChange}
                        fullWidth
                        error={inputError}
                        helperText={inputError}
                        disabled={readOnly || !configurator.thicknessId}
                        inputProps={{ readOnly: readOnly || false }}
                        style={{ marginBottom: '1rem' }}
                    />
                ) : (
                    <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                        <InputLabel>Longueur (mm)</InputLabel>
                        <Select
                            labelId="configurator-select-size-label"
                            id="configurator-select-size"
                            value={configurator.size}
                            onChange={onchange}
                            fullWidth
                            disabled={readOnly || !configurator.thicknessId}
                            inputProps={{ readOnly: readOnly || false }}
                        >
                            {options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showInput}
                            onChange={handleCheckboxChange}
                            disabled={readOnly || !configurator.thicknessId}
                        />
                    }
                    style={{ marginLeft: '1rem' }}
                    label="Autre"
                />
            </Box>
        </Box>
    );
}

export default ConfiguratorMenuLength;
