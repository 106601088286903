import React, { useCallback, useContext, useState } from 'react'
import { Card, CardActions, CardContent, CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core'
import CatalogImagePreview from './CatalogImagePreview'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useDeleteCatalog } from '../../contexts/CatalogContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';

const useStyle = makeStyles((theme) => ({
    card: {
        width: '100%',
    },
    cardContent: {
        '&:hover': {
            cursor: 'pointer'
        },
        height: '75%'
    },
    cardActions: {
        justifyContent: 'flex-end',
        borderTop: "2px solid #d9d9d9"
    },
    previewImage: {
        '& img': {
            display: 'block',
            margin: '0 auto',
            objectFit: 'contain',
            width: '100%',
            height: '110px',
        },
        '& svg': {
            display: 'block',
            margin: '0 auto',
            width: '100%'
        },

    },
}))

function CatalogControlCard({ catalog, setopenDialogEditCatalog, setselectedCatalog }) {

    const classes = useStyle()
    const { deleteCatalog } = useDeleteCatalog()
    const [, dispatchSnackbar] = useContext(SnackbarContext);

    const [loadingDelete, setloadingDelete] = useState(false);

    const handelDelete = useCallback(
        (catalog) => {
            (async () => {
                setloadingDelete(true)
                const res = await deleteCatalog(catalog._id)
                setloadingDelete(false)
                if (res) {
                    dispatchSnackbar({
                        type: 'SET_SNACKBAR',
                        payload: {
                            open: true,
                            content: 'Suppression réussie',
                            type: 'success',
                            duration: 6000
                        }
                    })
                } else {
                    dispatchSnackbar({
                        type: 'SET_SNACKBAR',
                        payload: {
                            open: true,
                            content: 'une erreur est survenue',
                            type: 'error',
                            duration: 6000
                        }
                    })
                }
            })()
        },
        [deleteCatalog, dispatchSnackbar],
    )


    const handelClickForm = useCallback(
        (catalog) => {
            setopenDialogEditCatalog(false)
            setselectedCatalog(catalog._id)
        },
        [setopenDialogEditCatalog, setselectedCatalog],
    )


    return (
        <Card className={classes.card} elevation={0}>

            <CardContent onClick={() => handelClickForm(catalog)} className={classes.cardContent}>
                <div className={classes.previewImage}>
                    <CatalogImagePreview image={catalog.file} />
                </div>
                <Typography color="primary" gutterBottom variant="subtitle1" align='center' style={{ marginTop: '1rem' }}>
                    {catalog.name}
                </Typography>
            </CardContent>

            <CardActions className={classes.cardActions}>
                <IconButton onClick={() => handelClickForm(catalog)} aria-label="edit">
                    <EditIcon color="secondary" fontSize="small" />
                </IconButton>
                <IconButton onClick={() => handelDelete(catalog)} aria-label="delete">
                    {loadingDelete ? <CircularProgress size={20} /> : <DeleteIcon color="secondary" fontSize="small" />}
                </IconButton>
            </CardActions>
        </Card>
    )
}

export default CatalogControlCard
