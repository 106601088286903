import React, { useState, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControlLabel, Paper, Typography, Switch, CircularProgress, Grid } from '@material-ui/core';
import UserFrom from './User/UserFrom';
import GreenButton from './Button/GreenButton';
import UserProForm from './User/UserProForm';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { UserLastNameSchemas, UserFirstNameSchemas, UserStreetSchemas, UserEmailSchemas, UserPhoneSchemas, UserZipCodeSchemas, ProTVANameSchemas, ProSiretSchemas, ProSocialNameSchemas, UserHouseNumberSchemas, UserCitySchemas } from '../modules/validation'
import axios from 'axios';
import AlertDialog from './Dialog/DialogSucessSignUp';
import { useHistory } from "react-router-dom";
import { grey } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(5),
        boxShadow: 'rgb(17 12 46 / 15%) 0px 48px 100px 0px',
        borderRadius: '6px',
        position: 'relative',
        width: '100%'
    },
    isPro: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(3)
    },
    buttons: {
        display: 'flex',
        marginTop: '2rem',
        justifyContent: 'flex-end'
    },
    gobackButton: {
        position: 'absolute',
        top: '4px',
        left: '4px',
    },
    goforwardButton: {
        position: 'absolute',
        top: '4px',
        right: '4px',
    }
}))

function SignUp() {

    const history = useHistory();

    const [userInformations, setuserInformations] = useState({
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        zipCode: '',
        houseNumber: '',
        password: ''
    });

    const [userProInformations, setUserProInformations] = useState({
        socialReason: '',
        siret: '',
        tva: '',
        street: '',
        city: '',
        zipCode: '',
        houseNumber: '',
        sameAdresse: false,
        deliveryHouseNumber: '',
        deliveryStreet: '',
        deliveryCity: '',
        deliveryZipCode: '',
        phone: '',
        mobile: ''
    })

    const [formSubmitErrors, setformSubmitErrors] = useState([]);
    const [isPro, setisPro] = useState(false)
    const [activeStep, setactiveStep] = useState(0)
    const [isLoading, setisLoading] = useState(false)
    const [openSuccesDialog, setopenSuccesDialog] = useState(false)
    const [postError, setpostError] = useState(null);

    const resetForm = useCallback(
        () => {
            setUserProInformations({
                lastName: '',
                firstName: '',
                email: '',
                phone: '',
                street: '',
                zipCode: '',
                city: '',
                houseNumber: ''
            })
            setUserProInformations({
                socialReason: '',
                siret: '',
                tva: '',
                street: '',
                city: '',
                zipCode: '',
                houseNumber: '',
                sameAdresse: false,
                deliveryHouseNumber: '',
                deliveryStreet: '',
                deliveryCity: '',
                deliveryZipCode: '',
                phone: '',
                mobile: ''
            })
        },
        [],
    )


    const handelCloseSuccessDialog = useCallback(
        () => {
            setopenSuccesDialog(false)
            history.push("/")
            resetForm()
        },
        [history, resetForm],
    )


    const handelChangeIsPro = useCallback(
        (e) => {
            setisPro(e.target.checked)

            if (!isPro) {
                setactiveStep(1)
            } else {
                setactiveStep(0)
            }

        },
        [isPro],
    )

    const handelClickGoBack = useCallback(
        () => {
            setactiveStep(activeStep - 1)
        },
        [activeStep],
    )

    const handelClickGoForward = useCallback(
        () => {
            setactiveStep(activeStep + 1)
        },
        [activeStep],
    )

    const handelClickSubmit = useCallback(
        () => {
            (async () => {
                setisLoading(true)
                setpostError(null)
                let data = {}
                if (isPro) {

                    data.pro = {
                        ...userProInformations,
                        deliveryHouseNumber: userProInformations.sameAdresse ? userProInformations.houseNumber : userProInformations.deliveryHouseNumber,
                        deliveryStreet: userProInformations.sameAdresse ? userProInformations.street : userProInformations.deliveryStreet,
                        deliveryCity: userProInformations.sameAdresse ? userProInformations.city : userProInformations.deliveryCity,
                        deliveryZipCode: userProInformations.sameAdresse ? userProInformations.zipCode : userProInformations.deliveryZipCode
                    }
                }
                data = {
                    ...userInformations,
                    isPro,
                    ...data
                }

                try {
                    await axios.post(process.env.REACT_APP_BASE_API + '/user', data)
                    setopenSuccesDialog(true)
                } catch (e) {
                    setpostError(e.response.data.message || 'Une erreure est survenue')
                }
                setisLoading(false)
            })()
        },
        [isPro, userInformations, userProInformations],
    )

    const validateForm = useCallback(
        () => {

            (async () => {
                let erros = []

                try {
                    await UserLastNameSchemas.validate({ value: userInformations.lastName })
                } catch (e) {
                    erros.push({
                        error: 'Nom invalide'
                    })
                }
                try {
                    await UserFirstNameSchemas.validate({ value: userInformations.firstName })
                } catch (e) {
                    erros.push({
                        error: 'Prénom invalide'
                    })
                }
                try {
                    await UserEmailSchemas.validate({ value: userInformations.email })
                } catch (e) {
                    erros.push({
                        error: 'E-mail invalide'
                    })
                }

                try {
                    await UserPhoneSchemas.validate({ value: userInformations.phone })
                } catch (e) {
                    erros.push({
                        error: 'Numero de téléphone invalide'
                    })
                }
                try {
                    await UserHouseNumberSchemas.validate({ value: userInformations.houseNumber })
                } catch (e) {
                    erros.push({
                        error: 'Numero de voie invalide'
                    })
                }
                try {
                    await UserStreetSchemas.validate({ value: userInformations.street })
                } catch (e) {
                    erros.push({
                        error: 'Rue Invalide'
                    })
                }
                try {
                    await UserCitySchemas.validate({ value: userInformations.city })
                } catch (e) {
                    erros.push({
                        error: 'Ville invalide'
                    })
                }
                try {
                    await UserZipCodeSchemas.validate({ value: userInformations.zipCode })
                } catch (e) {
                    erros.push({
                        error: 'Code postal invalide'
                    })
                }
                if (isPro) {
                    try {
                        await ProSocialNameSchemas.validate({ value: userProInformations.socialReason })
                    } catch (e) {
                        erros.push({
                            error: 'Raison sociale invalide'
                        })
                    }
                    try {
                        await ProSiretSchemas.validate({ value: userProInformations.siret })
                    } catch (e) {
                        erros.push({
                            error: 'Numero de siret invalide'
                        })
                    }
                    try {
                        await ProTVANameSchemas.validate({ value: userProInformations.tva })
                    } catch (e) {
                        erros.push({
                            error: 'Numero de TVA invalide'
                        })
                    }

                    try {
                        await UserStreetSchemas.validate({ value: userProInformations.street })
                    } catch (e) {
                        erros.push({
                            error: 'Rue Invalide'
                        })
                    }
                    try {
                        await UserCitySchemas.validate({ value: userProInformations.city })
                    } catch (e) {
                        erros.push({
                            error: 'Ville invalide'
                        })
                    }
                    try {
                        await UserHouseNumberSchemas.validate({ value: userProInformations.houseNumber })
                    } catch (e) {
                        erros.push({
                            error: 'Numero de voie invalide'
                        })
                    }
                    try {
                        await UserZipCodeSchemas.validate({ value: userProInformations.zipCode })
                    } catch (e) {
                        erros.push({
                            error: 'Code postale invalide'
                        })
                    }
                    if (!userProInformations.sameAdresse) {
                        try {
                            await UserStreetSchemas.validate({ value: userProInformations.deliveryStreet })
                        } catch (e) {
                            erros.push({
                                error: 'Rue Invalide'
                            })
                        }
                        try {
                            await UserCitySchemas.validate({ value: userProInformations.deliveryCity })
                        } catch (e) {
                            erros.push({
                                error: 'Ville invalide'
                            })
                        }
                        try {
                            await UserHouseNumberSchemas.validate({ value: userProInformations.deliveryHouseNumber })
                        } catch (e) {
                            erros.push({
                                error: 'Numero de voie invalide'
                            })
                        }
                        try {
                            await UserZipCodeSchemas.validate({ value: userProInformations.deliveryZipCode })
                        } catch (e) {
                            erros.push({
                                error: 'Code postale invalide'
                            })
                        }
                    }
                    try {
                        await UserPhoneSchemas.validate({ value: userProInformations.phone })
                    } catch (e) {
                        erros.push({
                            error: 'Code postal invalide'
                        })
                    }
                    if (userProInformations?.mobile) {
                        try {
                            await UserPhoneSchemas.validate({ value: userProInformations.mobile })
                        } catch (e) {
                            erros.push({
                                error: 'Code postal invalide'
                            })
                        }
                    }

                }
                setformSubmitErrors(erros)
            })()
        },
        [isPro, userInformations.city, userInformations.email, userInformations.firstName, userInformations.houseNumber, userInformations.lastName, userInformations.phone, userInformations.street, userInformations.zipCode, userProInformations.city, userProInformations.deliveryCity, userProInformations.deliveryHouseNumber, userProInformations.deliveryStreet, userProInformations.deliveryZipCode, userProInformations.houseNumber, userProInformations.mobile, userProInformations.phone, userProInformations.sameAdresse, userProInformations.siret, userProInformations.socialReason, userProInformations.street, userProInformations.tva, userProInformations.zipCode],
    )

    useEffect(() => {
        validateForm()

    }, [userInformations, validateForm]);

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container item xs={12} md={8} lg={6}>
                <Paper className={classes.paper} elevation={0}>

                    {
                        activeStep === 0 ?
                            <div>
                                <Typography align='center' variant="h5" color='primary' gutterBottom>
                                    Formulaire d'inscription
                                </Typography>

                                <UserFrom formSubmitErrors={formSubmitErrors} userInformations={userInformations} setuserInformations={setuserInformations} />
                                <div className={classes.isPro}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isPro}
                                                onChange={handelChangeIsPro}
                                                name="userIsPro"
                                                color="primary"
                                            />
                                        }
                                        label="Je suis un professionnel"
                                    />
                                </div>

                                {
                                    !isPro ?
                                        <div className={classes.buttons}>
                                            <GreenButton onClick={handelClickSubmit} disabled={formSubmitErrors.length > 0}>{isLoading ? <CircularProgress style={{ color: grey[50] }} size={20} /> : null}Soumettre</GreenButton>
                                        </div>
                                        : null
                                }
                                {isPro ? <Button onClick={handelClickGoForward} size="small" className={classes.goforwardButton} variant="contained" color="secondary" startIcon={<ChevronRightIcon />} /> : null}

                            </div>
                            :
                            <div>
                                <Typography align='center' variant="h5" gutterBottom color='primary'>
                                    Informations sur votre entreprise
                                </Typography>

                                <UserProForm userProInformations={userProInformations} setUserProInformations={setUserProInformations} />


                                <div className={classes.buttons}>
                                    <Button color="primary" variant="contained" onClick={handelClickSubmit} disabled={formSubmitErrors.length > 0} >{isLoading ? <CircularProgress style={{ color: grey[50] }} size={20} /> : null}Soumettre</Button>
                                </div>
                                <Button onClick={handelClickGoBack} size="small" className={classes.gobackButton} variant="outlined" color="secondary" startIcon={<ChevronLeftIcon />} />
                            </div>
                    }
                    {
                        postError ?
                            <Alert style={{ marginTop: '1rem' }} severity="error">{postError}</Alert>
                            : null
                    }



                </Paper>
            </Grid>
            <AlertDialog open={openSuccesDialog} setOpen={handelCloseSuccessDialog} />
        </div>
    )
}

export default SignUp
