import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, List, ListItem, makeStyles, MenuItem, Select, Slide, TextField, Typography, useTheme, useMediaQuery } from '@material-ui/core'
import React, { useCallback, useState, useEffect, useRef } from 'react'
import uniqid from 'uniqid'
import { useContext } from 'react'
import { CatalogContext, useEditCatalog } from '../../contexts/CatalogContext'
import CatalogCustomzationLine from '../Control/CatalogCustomzationLine'
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import { MaterialsContext } from '../../contexts/MaterialsContext'
import { SnackbarContext } from '../../contexts/SnackbarContext'
import { AccessoriesContext, useGetAccessories } from '../../contexts/AccessoriesContext'


const useStyles = makeStyles((theme) => ({
    customImage: {
        width: '100%',
        margin: '0 auto',
        display: 'block',
    },
    title: {
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    text: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4)
    },
    dialogActions: {
        background: '#cdcdcd'
    },
    dialogButtons: {
        marginTop: theme.spacing(4)
    },
    catalogName: {
        marginBottom: theme.spacing(4)
    },
    buttonContainer: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center'
        }
    }
}))

const entitydefaultName = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D',
    5: 'E',
    6: 'F',
    7: 'G',
    8: 'H',
    9: 'I',
    10: 'J',
    11: 'K',
    12: 'M',
    13: 'N',
    14: 'O',
    15: 'P',
    16: 'Q',
    17: 'R',
    18: 'S',
    19: 'T',
    20: 'U',
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function DialogEditCatalogForm({ handleClose, open, catalogForm, selectedCatalog }) {

    const classes = useStyles();
    const { editCatalog } = useEditCatalog()
    const [{ catalogs }, dispatch] = useContext(CatalogContext)

    const hiddenInputConfigImageRef = useRef(null)
    const [materials] = useContext(MaterialsContext)
    const [catalog, setcatalog] = useState({})
    const [file, setfile] = useState(null)
    const [excel, setExcel] = useState(null)
    const [fileConfig, setfileConfig] = useState(null)
    const [, dispatchSnackbar] = useContext(SnackbarContext)
    const [loading, setloading] = useState(false);
    const hiddenInputRef = useRef(null);
    const hiddenExcelInputRef = useRef(null)
    const [errors, seterrors] = useState({});

    const [{ accessories }] = useContext(AccessoriesContext);
    const { getAccessories } = useGetAccessories();

    useEffect(() => {
        if (!accessories || !accessories.length) {
            getAccessories()
        }
    }, []);

    const handelClickValider = useCallback(
        () => {
            (async () => {
                let error = {};
                let asError = false
                if (!catalog.name) {
                    error = { ...error, name: 'Valeur requise' }
                    asError = true
                }
                if (!catalog.file) {
                    error = { ...error, file: 'Image requis' }
                    asError = true
                }
                if (!catalog.fileConfig) {
                    error = { ...error, fileConfig: 'Image requise' }
                    asError = true
                }
                if (!catalog.thickness) {
                    error = { ...error, thickness: 'Valeur requise' }
                    asError = true
                }

                if (catalog.entities.length < 2 || !catalog.entities.some(entity => entity.type === 'line')) {
                    dispatchSnackbar({
                        type: 'SET_SNACKBAR',
                        payload: {
                            open: true,
                            content: "Vous devez ajouter au moins une ligne",
                            type: 'error',
                            duration: 6000
                        }
                    })
                    return;
                }
                if (!asError) {
                    setloading(true)
                    const res = await editCatalog({ ...catalog, entities: catalog.entities.map(entity => entity._id.match(/^A/) ? { name: entity.name, min: entity.min, max: entity.max, default: entity.default, type: entity.type, linesDep: entity.linesDep, linesDepH: entity.linesDepH, horizontal: entity.horizontal || false } : entity) }, file, fileConfig, excel)
                    setloading(false)

                    if (res) {
                        dispatchSnackbar({
                            type: 'SET_SNACKBAR',
                            payload: {
                                open: true,
                                content: 'Modification enregistrée',
                                type: 'success',
                                duration: 6000
                            }
                        })
                        handleClose()
                    } else {
                        dispatchSnackbar({
                            type: 'SET_SNACKBAR',
                            payload: {
                                open: true,
                                content: "Une erreure c'est produite",
                                type: 'error',
                                duration: 6000
                            }
                        })
                    }
                }
                seterrors(error)
            })()
        },
        [catalog, dispatchSnackbar, editCatalog, file, fileConfig, handleClose],
    )


    useEffect(() => {
        const catalog = catalogs.find(catalog => catalog._id === selectedCatalog)
        setcatalog({ ...catalog });
    }, [catalogs, selectedCatalog]);

    const getLinesLibele = useCallback(() => catalog.entities.filter(entity => entity.type === 'line' || entity.type === 'longer').map(entity => ({ id: entity._id, name: entity.name, horizontal: entity.horizontal, type: entity.type })), [catalog.entities])


    const getEntities = useCallback(
        () => {
            if (catalog.entities) {
                return catalog.entities.map((entity, index) => <Grid item xs={12}><CatalogCustomzationLine key={index} withControl={true} entity={{ string: entity.name, id: entity._id, type: entity.type }} setentityInformations={setcatalog} entityInformations={catalog} linesLibele={getLinesLibele()} /></Grid>)
            }
        },
        [catalog, getLinesLibele],
    )



    const getNextEntitydefaultName = useCallback(
        () => {
            let finded = false
            for (const entityName in entitydefaultName) {
                finded = catalog.entities.some(entity => entity.name === entitydefaultName[entityName])
                if (!finded) {
                    return entitydefaultName[entityName]
                }
            }

        }, [catalog.entities])

    const handelclickAddEnity = useCallback(
        (type) => {
            const id_temp = uniqid();
            setcatalog({ ...catalog, entities: [...catalog.entities, { _id: 'A' + id_temp, id_tmp: 'A' + id_temp, name: getNextEntitydefaultName(), type, min: type === 'angle' ? 45 : 100, max: type === 'angle' ? 145 : 1000, default: type === 'angle' ? 90 : 500, maxH: false, maxW: false }] })
        },
        [catalog, getNextEntitydefaultName],
    )

    const handelSelectConfigFile = useCallback(
        (e) => {
            const file = e.target.files[0]
            setfileConfig(file)
            const url = URL.createObjectURL(file)
            setcatalog({ ...catalog, fileConfig: url })
        },
        [catalog],
    )

    const handelclickAddConfigImage = useCallback(
        () => {
            hiddenInputConfigImageRef.current.click()
        },
        [],
    )

    const handelclickAddImage = useCallback(
        () => {
            hiddenInputRef.current.click()
        },
        [],
    )


    const handleExcelFileUploadClick = useCallback(
        () => {
            hiddenExcelInputRef.current.click()
        },
        [],
    )
    const handelSelectFile = useCallback(
        (e) => {
            const file = e.target.files[0]
            setfile(file)
            const url = URL.createObjectURL(file)
            setcatalog({ ...catalog, file: url })
        },
        [catalog],
    )

    const handelSelectExcel = useCallback(
        (e) => {
            const file = e.target.files[0]
            setExcel(file)
            setcatalog({ ...catalog, excel: file.name })
        },
        [catalog],
    )

    const handelChangeThickness = useCallback(
        (e) => {
            setcatalog({ ...catalog, thickness: e.target.value })
        },
        [catalog],
    )


    const handelChangeAccessories = useCallback(
        (e) => {
            setcatalog({ ...catalog, accessories: e.target.value })
        },
        [catalog],
    )

    const getOptions = useCallback(
        () => {
            const thicknesses = [];
            materials.forEach(material => material.shades.forEach(shade => shade.thicknesses.forEach(thickness => thicknesses.push(thickness.value))));
            return [...new Set(thicknesses)].map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)
        },
        [materials],
    )

    const getAccessoriesOptions = () => {
        return accessories?.map(accessory => <MenuItem key={accessory._id} value={accessory._id}>{accessory.name}</MenuItem>) || [];
    }

    const handelChangeName = useCallback(
        (e) => {
            setcatalog({ ...catalog, name: e.target.value })
        },
        [catalog],
    )

    const handelChangePaintDirection = useCallback(
        (e) => {
            setcatalog({ ...catalog, paintDirection: e.target.value })
        },
        [catalog],
    )



    return (
        <Dialog fullScreen onClose={handleClose} TransitionComponent={Transition} open={open}>
            <DialogTitle id="dialog-edit-catalog-form-title" className={classes.title}>Editer {catalog.name}</DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignItems="center" className={classes.catalogName} spacing={2} >
                    <Grid item xs={12} sm={2}>
                        <TextField fullWidth onChange={handelChangeName} value={catalog.name} id="item-title" label="" error={errors.name} helperText={errors.name} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl error={errors.thickness} fullWidth>
                            <InputLabel id="thickness-valides">Epaisseurs valide</InputLabel>
                            <Select
                                labelId="thickness-valide"
                                value={catalog.thickness || []}
                                onChange={handelChangeThickness}
                                fullWidth
                                multiple
                            >
                                {getOptions()}
                            </Select>
                            <FormHelperText>{errors.thickness}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl error={errors.thickness} fullWidth>
                            <InputLabel id="accessories">Accessoires</InputLabel>
                            <Select
                                labelId="accessories"
                                value={catalog?.accessories?.map(accessory => typeof accessory === 'object' ? accessory._id : accessory) || []}
                                onChange={handelChangeAccessories}
                                fullWidth
                                multiple
                            >
                                {getAccessoriesOptions()}
                            </Select>
                            <FormHelperText>{errors.thickness}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Button onClick={handleExcelFileUploadClick} variant="contained" color="secondary">
                            Choisir un fichier Excel
                        </Button>
                        {catalog?.excel && <Typography variant="caption" display="block" gutterBottom>
                            {catalog.excel}
                        </Typography>}
                        <input ref={hiddenExcelInputRef} onChange={handelSelectExcel} type='file' accept=".xlsx,.xls" hidden />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl error={errors.paintDirection} fullWidth>
                            <InputLabel id="paint">Sens peinture par défaut</InputLabel>
                            <Select
                                labelId="paint"
                                value={catalog.paintDirection}
                                onChange={handelChangePaintDirection}
                                fullWidth
                            >
                                <MenuItem value={'inside'}>Intérieure</MenuItem>
                                <MenuItem value={'outside'}>Extérieure</MenuItem>
                            </Select>
                            <FormHelperText>{errors.paintDirection}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container justify='center' className={classes.catalogName} spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Grid container direction='column' justify='center' alignItems='center'>
                            <Grid xs={12} item style={{ marginBottom: '1rem' }} >
                                <Button onClick={handelclickAddImage} variant="contained" color="secondary" startIcon={<ImageIcon />}>
                                    Choisir une image de présentation
                                </Button>
                                {
                                    errors.file &&
                                    <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
                                        {errors.file}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {
                                    catalog.file ? <img class={classes.customImage} src={file ? catalog.file : `${process.env.REACT_APP_BASE_API}/download/image/${catalog.file}`} alt="" />
                                        : null
                                }
                            </Grid>
                        </Grid>
                        <input ref={hiddenInputRef} onChange={handelSelectFile} id='fileid' type='file' multiple hidden />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container direction='column' justify='center' alignItems='center'>
                            <Grid xs={12} item style={{ marginBottom: '1rem' }}>
                                <Button onClick={handelclickAddConfigImage} variant="contained" color="secondary" startIcon={<ImageIcon />}>
                                    Choisir une image de configuration
                                </Button>
                                {
                                    errors.fileConfig &&
                                    <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
                                        {errors.fileConfig}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {
                                    catalog.fileConfig ? <img class={classes.customImage} src={file ? catalog.fileConfig : `${process.env.REACT_APP_BASE_API}/download/image/${catalog.fileConfig}`} alt="" />
                                        : null
                                }
                            </Grid>
                        </Grid>
                        <input ref={hiddenInputConfigImageRef} onChange={handelSelectConfigFile} id='fileid' type='file' multiple hidden />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>

                    {getEntities()}

                </Grid>
                <Grid container justify='flex-end' className={classes.dialogButtons}>
                    <Grid item xs={6} className={classes.buttonContainer} >
                        <Button onClick={() => handelclickAddEnity('line')} variant="contained" color="primary" startIcon={<AddIcon />}>
                            Ajouter une ligne
                        </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.buttonContainer}>
                        <Button onClick={() => handelclickAddEnity('angle')} variant="contained" color="secondary" startIcon={<AddIcon />}>
                            Ajouter un angle
                        </Button>
                    </Grid>
                    <input ref={hiddenInputRef} onChange={handelSelectFile} id='fileid' type='file' multiple hidden />
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Grid container justify='flex-end'>
                    <Button onClick={handleClose} variant="contained" color="primary" style={{ marginRight: '2rem' }}>
                        Fermer
                    </Button>

                    <Button onClick={handelClickValider} variant="contained">
                        Valider
                    </Button>

                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default DialogEditCatalogForm
