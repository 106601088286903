import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';
import React, { useContext, useCallback, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import { MaterialsContext } from '../../../contexts/MaterialsContext';


const useStyles = makeStyles(() => ({
    materialName: {
        background: '#3c3c3c',
        padding: '10px',
        borderRadius: '5px',
    },
}))

function ConfiguratorMenuMaterialSector({ readOnly }) {
    const [materials, dispach] = useContext(MaterialsContext);

    const classes = useStyles()

    const configurator = useContext(ConfiguratorContext)[0]
    const dispachConfig = useContext(ConfiguratorContext)[1]

    const getStyle = (imagename) => {
        return imagename ? { marginTop: '2px', height: 'auto', borderRadius: '5px', backgroundImage: `url('${process.env.REACT_APP_BASE_API}/download/image/${imagename}')`, backgroundSize: 'cover', color: 'white' } : { color: 'white' }
    }

    const getOptions = useCallback(
        () => {
            return materials.map(material => <MenuItem style={getStyle(material.image)} key={material._id} value={material._id}><span className={classes.materialName}>{material.name}</span></MenuItem>)
        },
        [classes.materialName, materials],
    )
    const getMaterialImageNameFromId = useCallback((materialId) => materials.find(material => material._id === materialId)?.image, [materials])

    const onchange = useCallback(
        (e) => {
            dispachConfig({
                type: 'SET_VALUES',
                payload: {
                    data: {
                        materialId: e.target.value,
                        materialImageName: getMaterialImageNameFromId(e.target.value),
                        shadeId: null,
                        shadeImageName: null,
                        shadeColor: null,
                        thicknessId: null
                    }
                }
            })
        },
        [dispachConfig, getMaterialImageNameFromId],
    )



    return (
        <FormControl fullWidth style={{ marginBottom: '1rem' }} >
            <InputLabel id="demo-simple-select-outlined-label">Matière</InputLabel>
            <Select
                labelId="configurator-select-material-label"
                id="configurator-select-material"
                style={getStyle(configurator.materialImageName)}
                value={configurator.materialId}
                onChange={onchange}
                label="Matériaux"
                fullWidth
                disabled={readOnly}
                inputProps={{ readOnly: readOnly || false }}

            >
                {getOptions()}
            </Select>
        </FormControl>
    )
}

export default ConfiguratorMenuMaterialSector
