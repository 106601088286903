import React, { useCallback, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ConfiguratorMenuMaterialSector from './ConfiguratorMenuMaterialSector';
import ConfigurationManuShadeSelector from './ConfigurationManuShadeSelector';
import ConfigurationMenuThicknessSelector from './ConfigurationMenuThicknessSelector';
import { useHistory } from 'react-router';
import { CloudUpload, Settings } from '@material-ui/icons';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';

import DropDXF from '../../DropDXF';
import { CircularProgress } from '@material-ui/core';
import ConfiguratorMenuLength from './ConfiguratorMenuLength';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: '6px',
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    position: 'relative'

  },
  stepper: {
    borderRadius: '6px'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonGreen: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: '#00ad0a',
    color: 'white'
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  buttonConfigurator: {
    height: '125px',
    background: theme.palette.primary.main,
    color: 'white',
    width: '100%',
    fontSize: '1.2rem'
  },
  buttonDropDXF: {
    height: '125px',
    background: theme.palette.secondary.main,
    color: 'white',
    width: '100%',
    fontSize: '1.2rem'
  },
}));






function ConfiguratorMenuStepper() {

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedMenu, setselectedMenu] = React.useState(null);
  const [startDXF, setstartDXF] = React.useState(false);
  const [inputError, setinputError] = useState('');
  const steps = getSteps();
  const history = useHistory()
  const [configurator, dispatch] = useContext(ConfiguratorContext)
  const [dxfLoading, setdxfLoading] = useState(false);


  const handleClickValidate = useCallback(
    () => {
      history.push('/catalog')
    },
    [history],
  )

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const disableButton = useCallback(
    () => {
      return inputError || !configurator.materialId || !configurator.shadeId || !configurator.thicknessId || !configurator.nbPiece || !configurator.size
    },
    [configurator.materialId, configurator.nbPiece, configurator.shadeId, configurator.size, configurator.thicknessId, inputError],
  )



  function getSteps() {
    return ['Créer votre pièce'];
  }

  function getStepContent(step) {
    switch (step) {

      case 0:
        return <>
          <div style={{ marginTop: '1rem' }}>
            <ConfiguratorMenuMaterialSector />
            <ConfigurationManuShadeSelector />
            <ConfigurationMenuThicknessSelector />
            <ConfiguratorMenuLength inputError={inputError} setinputError={setinputError} />
          </div>
        </>;

      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>

      <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>

            <StepContent>
              <Typography align='center' variant='h6'>{label}</Typography>
              <Typography>{getStepContent(index)}</Typography>

              <div className={classes.actionsContainer}>
                <div>
                  {
                    <>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                        color="primary"
                        variant="outlined"
                        startIcon={<KeyboardArrowUpIcon />}
                      >
                        Retour
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickValidate}
                        className={classes.button}
                        endIcon={<KeyboardArrowRightIcon />}
                        disabled={disableButton()}
                      >
                        Valider
                      </Button>
                    </>
                  }

                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>


      <DropDXF setdxfLoading={setdxfLoading} setActiveStep={setActiveStep} startDXF={startDXF} setstartDXF={setstartDXF} />
    </div>
  )
}

export default ConfiguratorMenuStepper
