import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';
import React, { useContext, useCallback, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import { MaterialsContext } from '../../../contexts/MaterialsContext';

const useStyles = makeStyles(() => ({
    shadeName: {
        background: '#3c3c3c',
        padding: '10px',
        borderRadius: '5px',
    },
}))

function ConfigurationManuShadeSelector({ readOnly }) {
    const [materials, dispach] = useContext(MaterialsContext);
    const configurator = useContext(ConfiguratorContext)[0]
    const dispachConfig = useContext(ConfiguratorContext)[1]

    const classes = useStyles()

    const getStyle = (shadeColor) => {
        return shadeColor ? { background: shadeColor } : null
    }

    const getOptions = useCallback(
        () => {
            return materials.find(material => material._id === configurator.materialId)?.shades.map(shade => <MenuItem style={{ height: 'auto', color: 'white', ...getStyle(shade.color) }} key={shade._id} value={shade._id}><span className={classes.shadeName}>{shade.name}</span></MenuItem>)
        },
        [configurator.materialId, materials],
    )

    const getShadeImageNameFromId = useCallback((shadeId) => materials.find(material => material._id === configurator.materialId)?.shades.find(shade => shade._id === shadeId)?.image, [configurator.materialId, materials])
    const getShadeColorFromId = useCallback((shadeId) => materials.find(material => material._id === configurator.materialId)?.shades.find(shade => shade._id === shadeId)?.color, [configurator.materialId, materials])

    const onchange = useCallback(
        (e) => {
            dispachConfig({
                type: 'SET_VALUES',
                payload: {
                    data: {
                        shadeId: e.target.value,
                        shadeImageName: getShadeImageNameFromId(e.target.value) || null,
                        shadeColor: getShadeColorFromId(e.target.value) || null,
                        thicknessId: null,
                    }
                }
            })
        },
        [dispachConfig, getShadeColorFromId, getShadeImageNameFromId],
    )

    return (
        <FormControl fullWidth style={{ marginBottom: '1rem' }} >
            <InputLabel id="configurator-select-shade-label">RAL</InputLabel>
            <Select
                labelId="configurator-select-shade-label"
                id="configurator-shade-material"
                style={{ color: 'white', ...getStyle(configurator.shadeColor) }}
                value={configurator.shadeId}
                onChange={onchange}
                label="Matériaux"
                fullWidth
                disabled={!configurator.materialId || readOnly}
                inputProps={{ readOnly: readOnly || false }}
            >
                {getOptions()}
            </Select>
        </FormControl>
    )
}

export default ConfigurationManuShadeSelector
