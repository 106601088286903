import { Button, Paper, Grid, Tabs, Tab } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { UserContext, useGetMe, useEditMe } from '../contexts/UserContext';
import UserFrom from './User/UserFrom';
import UserProForm from './User/UserProForm';
import { TabPanel } from '@material-ui/lab';
import OrdersControl from './Control/OrdersControl';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(5),
        boxShadow: 'rgb(17 12 46 / 15%) 0px 48px 100px 0px',
        borderRadius: '6px',
        position: 'relative',
        width: '80%',
        marginRight: 'auto',
        marginLeft: 'auto'
    }
}))

function Profil() {

    const [{ me }] = useContext(UserContext);
    const { getMe } = useGetMe();
    const { editMe } = useEditMe();


    const [userInformations, setuserInformations] = useState({
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        zipCode: '',
        houseNumber: '',
        password: '',
        pro: {}
    });

    const [formSubmitErrors, setformSubmitErrors] = useState([]);

    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const classes = useStyles();

    useEffect(() => {
        getMe();
    }, []);

    useEffect(() => {

        setuserInformations({
            ...me
        })
    }, [me])

    const handleSubmit = (event) => {
        event.preventDefault();
        editMe(me._id, userInformations);

    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>

                <Tabs value={selectedTab} onChange={handleChangeTab}>
                    <Tab label="Informations personnelles" />
                    <Tab label="Mes commandes" />
                </Tabs>

                {selectedTab === 0 &&
                    <form>
                        <UserFrom formSubmitErrors={formSubmitErrors} userInformations={userInformations} setuserInformations={setuserInformations} isEdit />
                        {me.isPro && userInformations.pro &&
                            <>

                                <UserProForm userProInformations={userInformations.pro} setUserProInformations={(pro) => setuserInformations({ ...userInformations, pro })} isEdit />
                            </>
                        }
                        <Grid container justify="flex-end">
                            <Button color='secondary' variant='contained' onClick={handleSubmit}>Enregistrer</Button>
                        </Grid>
                    </form>
                }
                {selectedTab === 1 &&
                    <OrdersControl mode="user" />

                }
            </Paper>
        </div>
    )
}

export default Profil