import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, makeStyles, Paper, TextField } from '@material-ui/core'
import React, { useEffect, useState, useCallback, useContext } from 'react'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router';
import CartValidateChoiseDelivery from './CartValidateChoiseDelivery';
import { useGet } from '../modules/request';
import OrderData from './OrderData';
import { BasketContext } from '../contexts/BasketContext';
import OrderResult from './OrderResult';
import { useHistory } from 'react-router';
import { usePostOrders } from '../contexts/OrderContext';
import { PropertiesContext } from '../contexts/PropertiesContext';
import { usePostEstimate } from '../contexts/EstimateContext';
import { slugify } from '../modules/utils';
import { useIsAuthenticated } from 'react-auth-kit';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    marginBottom: theme.spacing(4)
  },
  stepContent: {

  },
  actionButtons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
}));

function getSteps() {
  return ['', 'Récapitulatif', 'Finalisation'];
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function CartValidate() {
  const classes = useStyles()
  const get = useGet()
  const [property] = useContext(PropertiesContext);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [overSizeForTransport, setOverSizeForTransport] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [estimate, setEstimate] = useState(null);
  const [cartType, setCartType] = useState('order');
  const [clientRef, setClientRef] = useState(null);
  const isAuth = useIsAuthenticated()

  const [me, setme] = useState({});
  const [data, setdata] = useState({
    deliveryMethode: null,
    deliveryPrice: null,
    deliveryTime: null
  });
  const [paymentResult] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [baskets] = useContext(BasketContext);
  const { postOrder } = usePostOrders();
  const { postEstimate } = usePostEstimate();
  // eslint-disable-next-line no-unused-vars
  const [, setmeLoading] = useState(false);

  const steps = getSteps();

  const query = useQuery();

  useEffect(() => {
    for (const basket of baskets) {
      if (+basket.maxHeight > property.transportMaxLength && +basket.maxHeight > property.transportMaxWidth) {
        setOverSizeForTransport(true);
      } else if (+basket.maxWidth > property.transportMaxWidth && +basket.maxWidth > property.transportMaxLength) {
        setOverSizeForTransport(true)
      }
    }
  }, [baskets])


  const history = useHistory()

  const getTotalPrice = useCallback(
    () => {
      let total = 0
      baskets.forEach(item => total += item.price)
      return Number(total.toFixed(2))
    },
    [baskets],
  )
  const vatRate = Number(property.vatRate);

  const getStepContent = useCallback(
    (stepIndex) => {
      switch (stepIndex) {
        case 0:
          return <CartValidateChoiseDelivery me={me} data={data} setdata={setdata} setme={setme} loading={loading} overSizeForTransport={overSizeForTransport} />
        case 1:
          return <OrderData loading={loading} handleNext={handleNext} setConfirmationDialogOpen={setConfirmationDialogOpen} setActiveStep={setActiveStep} cartType={cartType} setCartType={setCartType} getTotalPrice={getTotalPrice} vatRate={vatRate} />;
        case 2:
          return <OrderResult cartType={cartType} estimate={estimate} />
        default:
          return 'Unknown stepIndex';
      }
    },
    [cartType, data, estimate, getTotalPrice, loading, me, overSizeForTransport, vatRate],
  )

  useEffect(() => {
    if (property?.deliveryTimes?.length && !overSizeForTransport) {
      const firstDeliveryTime = property.deliveryTimes[0]._id
      setdata({ ...data, deliveryMethode: 'delivery', deliveryTime: firstDeliveryTime })
    } else {
      setdata({ ...data, deliveryMethode: 'pickup', deliveryTime: null })
    }
  }, [overSizeForTransport, property.deliveryTimes])

  useEffect(() => {
    (async () => {
      setmeLoading(true)
      try {
        const userQuery = query.get('user');
        const res = await get('/user/me')
        let me = res.data.message;
        if (me.role === 0 && userQuery) {
          const res = await get(`/user/${userQuery}`)
          const newOrderUser = res.data.message;
          me = newOrderUser;
        }
        setme(me)
      } catch (e) {

      }
      setmeLoading(false)

    })()
  }, []);


  const handleNext = async ({ confirmed }) => {
    if (activeStep === 1 && confirmed) {
      setLoading(true);
      let clientDeliveryData = {}
      if (me.isPro) {
        clientDeliveryData = {
          deliveryHouseNumber: me.pro.deliveryHouseNumber,
          deliveryStreet: me.pro.deliveryStreet,
          deliveryCity: me.pro.deliveryCity,
          deliveryZipCode: me.pro.deliveryZipCode,
          deliveryMethode: data.deliveryMethode
        }
      } else {
        clientDeliveryData = {
          houseNumber: me.houseNumber,
          street: me.street,
          city: me.city,
          deliveryZipCode: me.deliveryZipCode,
          deliveryMethode: data.deliveryMethode

        }
      }

      try {

        let response = null;

        const datas = { clientDeliveryData: { ...clientDeliveryData, lastName: me.lastName, firstName: me.firstName } };
        if (clientRef) {
          datas.clientRef = clientRef;
        }

        if (query.get('user')) {
          datas.overwriteUser = query.get('user');
        }

        if (cartType === 'order') {
          response = await postOrder(datas)
        }

        if (cartType === 'estimate') {
          response = await postEstimate(datas)
          setEstimate(response)

        }

        if (!response) {
          throw new Error('Une erreur c\'est produite')
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (e) {
        console.log('err')
      } finally {
        setLoading(false);
      }

      return

    }
    if (activeStep === 2) {
      history.push('/')
      return
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);


  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChangeClientRef = (e) => {
    const value = e.target.value;
    if (value.length <= 40) {
      setClientRef(value);
    }
  };

  const handleBlurClientRef = () => {
    if (clientRef) {

      const slugifiedValue = slugify(clientRef);
      setClientRef(slugifiedValue);
    }
  };

  const disabledButton = useCallback(
    () => {
      if (activeStep === 0) {
        return !data.deliveryMethode || data.error
      }
    },
    [activeStep, data.deliveryMethode, data.error],
  )

  return (
    <>
      <Grid container justify="center" className={classes.root} >

        <Grid item xs={12} sm={10}>

          <div className={classes.root}>
            <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>All steps completed</Typography>
                  <Button onClick={handleReset}>Recommencer</Button>
                </div>
              ) : (
                <div>
                  <div className={classes.stepContent}>{getStepContent(activeStep)}</div>
                  {
                    activeStep !== 1 &&

                    <Paper elevation={0} className={classes.actionButtons}>
                      <Grid container justify='flex-end' >
                        {
                          activeStep < 2 ?
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              color='secondary'
                              className={classes.backButton}
                              variant='outlined'
                            >
                              Retour
                            </Button>
                            : null
                        }

                        {

                          <Button variant="contained" color="primary" onClick={handleNext} disabled={disabledButton()}>
                            {activeStep === steps.length - 1 ? 'Terminer' : 'Suivant'}
                          </Button>

                        }

                      </Grid>

                    </Paper>
                  }
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <DialogContent>
          <Typography variant="body1"> {`Êtes-vous sûr de vouloir valider votre ${cartType === 'order' ? 'commande' : 'devis'} ?`}</Typography>
          <DialogContentText>

            {
              cartType === 'order' ?
                <>
                  <Typography variant="body2">
                    Merci de vérifier votre commande avant de procéder à la validation.
                  </Typography>
                  <Typography variant="body2">
                    Pour information, un forfait minimum de 50€ HT est appliqué pour toute commande inférieure à ce montant.
                  </Typography>
                  <Typography variant="body2">
                    Après validation, toute modification de votre commande pourra entrainer des frais supplémentaires.
                  </Typography>

                </>
                :
                <>
                  <Typography variant="body2">
                    Votre devis est valable 1 mois, sous réserve de vente.
                  </Typography>
                  <Typography variant="body2">
                    Attention : Ce logiciel de chiffrage est mis à disposition gratuitement pour nos clients. Toute utilisation abusive des devis non transformés en commande sera facturé 50€ HT par devis.
                  </Typography>
                </>
            }
          </DialogContentText>
          <br />

          <Typography variant="body2">{`Ajouter une référence client à votre ${cartType === 'order' ? 'commande' : 'devis'} :`} </Typography>
          <br />
          <TextField
            value={clientRef}
            onBlur={handleBlurClientRef}
            variant="outlined"
            onChange={handleChangeClientRef}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationDialogOpen(false)} color="secondary">
            Annuler
          </Button>
          <Button
            onClick={() => {
              handleNext({ confirmed: true });
              setConfirmationDialogOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog >

    </>
  )
}

export default CartValidate
