import React, { useCallback, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextFieldValidation from './TextFieldValidation'
import { Button, Grid, Paper, Typography, LinearProgress } from '@material-ui/core';
import { MaterialsContext } from '../contexts/MaterialsContext'
import { MaterialSchema, ShadeSchema, ThicknessSchema, MaterialnameSchema, MaterialDensitySchema, ThicknessFoldingTimeSchema, MaterialPriceperKgSchema, ShadenameSchema, ThicknessValueSchema, ThicknessPierceTimeSchema, ThicknessCuttingSpeedSchema, ThicknessPricePerKiloSchema } from '../modules/validation'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        marginBlock: theme.spacing(2)
    },
    textfields: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
    }

}));


function ModalAddMaterial({ open, setdiagOpen, type, materialId, shadeId }) {

    const classes = useStyles();
    const [materials, dispatch] = useContext(MaterialsContext);
    const [materialName, setmaterialName] = useState('');
    const [materialNameError] = useState({ value: false, message: '' });
    const [materialDensity, setmaterialDensity] = useState('');
    const [materialDensityError] = useState({ value: false, message: '' });
    const [shadeName, setshadename] = useState('');
    const [thicknessValue, setthicknessValue] = useState('');
    const [thicknessPierceTime, setthicknessPierceTime] = useState('');
    const [thicknessCuttingSpeed, setthicknessCuttingSpeed] = useState('');



    const [thicknessPricePerKilo, setthicknessPricePerKilo] = useState('');
    const [thicknessFoldingTime, setthicknessFoldingTime] = useState('');
    const [loading, setloading] = useState(false)



    const getTitle = useCallback(
        () => {
            switch (type) {
                case 'M':
                    return 'Ajouter une matière'
                case 'N':
                    return 'Ajouter une RAL'
                case 'E':
                    return 'Ajouter une épaisseur'
                default:
                    break;
            }
        },
        [type],
    )


    const getField = useCallback(
        () => {
            const fieldsToDisplay = []
            switch (type) {
                case 'M':
                    fieldsToDisplay.push(
                        <>

                            <Paper elevation={0} className={classes.paper}>
                                <Typography variant="h6" align='center'>Matière</Typography>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldValidation
                                            autoFocus
                                            margin="dense"
                                            id="add-material-name"
                                            label={"Nom"}
                                            type="text"
                                            fullWidth
                                            helperText={materialNameError.message || "Nom"}
                                            value={materialName}
                                            onChange={(e) => setmaterialName(e.target.value)}
                                            yupValidation={MaterialnameSchema}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldValidation
                                            autoFocus
                                            className={classes.textfields}
                                            margin="dense"
                                            id="add-material-density"
                                            label="Densité"
                                            type="number"
                                            fullWidth
                                            helperText={materialDensityError.message || "Densité"}
                                            value={materialDensity}
                                            onChange={(e) => setmaterialDensity(e.target.value)}
                                            yupValidation={MaterialDensitySchema}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>

                        </>
                    )
                // eslint-disable-next-line no-fallthrough
                case 'N':
                    fieldsToDisplay.push(
                        <>
                            <Paper elevation={0} className={classes.paper}>
                                <Typography variant="h6" align='center'>RAL</Typography>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldValidation
                                            autoFocus
                                            margin="dense"
                                            id="add-shade-name"
                                            label="RAL"
                                            type="text"
                                            fullWidth
                                            helperText="RAL"
                                            value={shadeName}
                                            onChange={(e) => setshadename(e.target.value)}
                                            yupValidation={ShadenameSchema}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>

                        </>
                    )
                // eslint-disable-next-line no-fallthrough
                case 'E':
                    fieldsToDisplay.push(
                        <>
                            <Paper elevation={0} className={classes.paper}>
                                <Typography variant="h6" align='center'>Epaisseur</Typography>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldValidation
                                            autoFocus
                                            margin="dense"
                                            id="add-thickness-value"
                                            label="Valeur"
                                            type="number"
                                            fullWidth
                                            helperText="Valeur de l'épaisseur (mm)"
                                            value={thicknessValue}
                                            onChange={(e) => setthicknessValue(e.target.value)}
                                            yupValidation={ThicknessValueSchema}

                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextFieldValidation
                                            autoFocus
                                            margin="dense"
                                            id="add-thickness-pierceTime"
                                            label="Temps de perçage"
                                            type="number"
                                            fullWidth
                                            helperText="Temps de perçage pour cette épaisseur (s)"
                                            value={thicknessPierceTime}
                                            onChange={(e) => setthicknessPierceTime(e.target.value)}
                                            yupValidation={ThicknessPierceTimeSchema}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldValidation
                                            autoFocus
                                            margin="dense"
                                            id="add-thickness-cuttingSpeed"
                                            label="Vitesse de découpe"
                                            type="number"
                                            fullWidth
                                            helperText="Vitesse de découpe pour cette épaisseur (mm/min)"
                                            value={thicknessCuttingSpeed}
                                            onChange={(e) => setthicknessCuttingSpeed(e.target.value)}
                                            yupValidation={ThicknessCuttingSpeedSchema}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldValidation
                                            autoFocus
                                            margin="dense"
                                            id="add-thickness-pricePerKilo"
                                            label="Prix au kilo"
                                            type="number"
                                            fullWidth
                                            helperText="Prix au kilo de cette RAL (€/kg)"
                                            value={thicknessPricePerKilo}
                                            onChange={(e) => setthicknessPricePerKilo(e.target.value)}
                                            yupValidation={ThicknessPricePerKiloSchema}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldValidation
                                            autoFocus
                                            margin="dense"
                                            id="add-thickness-foldingTime"
                                            label="Temps de pliage"
                                            type="number"
                                            fullWidth
                                            helperText="Temps de pliage (s)"
                                            value={thicknessFoldingTime}
                                            onChange={(e) => setthicknessFoldingTime(e.target.value)}
                                            yupValidation={ThicknessFoldingTimeSchema}
                                        />
                                    </Grid>

                                </Grid>
                            </Paper>
                        </>
                    )
                // eslint-disable-next-line no-fallthrough
                default:
                    break;
            }
            return fieldsToDisplay
        },
        [classes.paper, classes.textfields, materialDensity, materialDensityError.message, materialName, materialNameError.message, shadeName, thicknessCuttingSpeed, thicknessFoldingTime, thicknessPierceTime, thicknessPricePerKilo, thicknessValue, type],
    )
    const handelClickAdd = useCallback(
        () => {
            (async () => {
                let data = {
                    name: materialName,
                    density: materialDensity,
                    shades: [
                        {
                            name: shadeName,
                            thicknesses: [
                                {
                                    value: thicknessValue,
                                    cuttingSpeed: thicknessCuttingSpeed,
                                    pierceTime: thicknessPierceTime,
                                    pricePerKilo: thicknessPricePerKilo,
                                    foldingTime: thicknessFoldingTime
                                }
                            ]
                        }
                    ]
                }

                switch (type) {
                    case 'M':
                        setloading(true)

                        try {
                            await MaterialSchema.validate(data)
                            const res = await axios.post(process.env.REACT_APP_BASE_API + '/material', data)
                            dispatch({
                                type: 'ADD_MATERIAL',
                                payload: res.data.message
                            })
                            setdiagOpen(false)
                        } catch (e) {
                            console.log(e)
                        }
                        setloading(false)
                        break;
                    case 'N':
                        setloading(true)
                        try {
                            const material = materials.find(item => item._id === materialId)
                            await ShadeSchema.validate(data.shades[0])
                            material.shades.push(data.shades[0])
                            data = { ...material, shades: [...material.shades] }

                            const res = await axios.put(process.env.REACT_APP_BASE_API + '/material/' + materialId, data)
                            dispatch({
                                type: 'SET_MATERIAL',
                                payload: { idMaterial: materialId, material: res.data.message }
                            })
                            setdiagOpen(false)
                        } catch (e) {

                        }
                        setloading(false)
                        break;
                    case 'E':
                        setloading(true)
                        try {
                            const material2 = materials.find(item => item._id === materialId)
                            const shade = material2.shades.find(item => item._id === shadeId)
                            await ThicknessSchema.validate(data.shades[0].thicknesses[0])
                            shade.thicknesses.push(data.shades[0].thicknesses[0])
                            data = { ...material2, shades: material2.shades.map(item => shade._id !== shadeId ? item : { ...item, thicknesses: shade.thicknesses }) }
                            const res = await axios.put(process.env.REACT_APP_BASE_API + '/material/' + materialId, data)
                            dispatch({
                                type: 'SET_MATERIAL',
                                payload: { idMaterial: materialId, material: res.data.message }
                            })
                            setdiagOpen(false)
                        } catch (e) {

                        }
                        setloading(false)
                        break;
                    default:
                        break;
                }

            })()
        },
        [dispatch, materialDensity, materialId, materialName, materials, setdiagOpen, shadeId, shadeName, thicknessCuttingSpeed, thicknessFoldingTime, thicknessPierceTime, thicknessPricePerKilo, thicknessValue, type],
    )

    return (
        <Dialog open={open} onClose={() => setdiagOpen(false)} aria-labelledby="form-dialog-title">
            {loading ? <LinearProgress /> : null}
            <DialogTitle id="form-dialog-title" align="center">{getTitle()}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Veuliez remplir les informations suivantes.
                </DialogContentText>
                {getField()}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setdiagOpen(false)}>
                    Annuler
                </Button>
                <Button variant="outlined" onClick={handelClickAdd} color="primary">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalAddMaterial
