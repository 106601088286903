import React, { useState, useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import GreenButton from '../Button/GreenButton'
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, CircularProgress, FormControlLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { deleteLayersfromDxf, deleteLayersInHeader, dxftoSVG } from '../../modules/dxf';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    dialog: {
        padding: 0,
        height: '75vh',
    },
    svgDisplayBox: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(2)
    },
    svg: {
        height: '250px',
        display: 'flex',
        marginTop: theme.spacing(4),
        justifyContent: 'center',
    },
    textLayerToDelete: {
        marginBottom: theme.spacing(2)
    }
}));

export default function DialogShowDXFLayers({ layers, dxf, open, handleClose, selectedLayers, setselectedLayers, gravedLayers, setgravedLayers, handleClickValidateDxf, loading, allowGraved = true }) {


    const classes = useStyles();
    const [editedDxf, seteditedDxf] = useState(dxf);
    const [svg, setsvg] = useState('');

    useEffect(() => {
        const svg = dxftoSVG(editedDxf)
        setsvg(svg)
    }, [editedDxf]);

    useEffect(() => {
        if (dxf && selectedLayers && selectedLayers.length) {
            const u = deleteLayersInHeader(deleteLayersfromDxf(dxf, selectedLayers), selectedLayers)
            seteditedDxf(u)
        }
    }, [dxf, selectedLayers])

    const handleClickRadio = useCallback((e, layer) => {
        if (e.target.checked) {
            setselectedLayers([...selectedLayers, layer])
            setgravedLayers(gravedLayers.filter(item => item !== layer))
        } else {
            setselectedLayers(selectedLayers.filter(item => item !== layer))
        }

    }, [gravedLayers, selectedLayers, setgravedLayers, setselectedLayers])

    const handleClickGraved = useCallback((e, layer) => {
        if (e.target.checked) {
            setgravedLayers([...gravedLayers, layer])
        } else {
            setgravedLayers(gravedLayers.filter(item => item !== layer))
        }

    }, [gravedLayers, setgravedLayers])


    const isButtonDisabled = () => selectedLayers.length >= layers.length

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableBackdropClick={true}
            fullWidth
            maxWidth={'md'}
        >

            <DialogContent className={classes.dialog}>
                <Card raised={false} className={classes.root} elevation={0}>
                    <Typography variant="h6" align='center' color='primary' gutterBottom>
                        Gestion des layers
                    </Typography>
                    <CardContent>
                        <Grid container justify='center'>
                            <Grid item xs={12} sm={8} className={classes.svgDisplayBox}>
                                <div className={classes.svg} dangerouslySetInnerHTML={{ __html: svg }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify='space-around'>
                                    <Grid item xs={12}>

                                        <TableContainer component={Paper} elevation={0}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nom du Layer</TableCell>
                                                        {allowGraved && <TableCell align="right">Gravure</TableCell>}
                                                        <TableCell align="right">Désactiver</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {layers.map((layer) => (
                                                        <TableRow key={layer}>
                                                            <TableCell style={{ padding: '0px, 1rem' }} component="th" scope="row">
                                                                {layer}
                                                            </TableCell>
                                                            {allowGraved && <TableCell style={{ padding: 0 }} align="right"><FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        disabled={selectedLayers.includes(layer)}
                                                                        checked={gravedLayers.includes(layer)}
                                                                        onChange={(e) => handleClickGraved(e, layer)}
                                                                        name="gravure"
                                                                        color="primary"
                                                                    />
                                                                }
                                                            /></TableCell>}
                                                            <TableCell style={{ padding: 0 }} align="right"><FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedLayers.includes(layer)}
                                                                        onChange={(e) => handleClickRadio(e, layer)}
                                                                        name="disable"
                                                                        color="primary"
                                                                    />
                                                                }
                                                            /></TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>

                    <CardActions className={classes.action}>
                        <Grid container justify='space-between'>
                            <GreenButton startIcon={loading && <CircularProgress size={15} />} onClick={() => handleClickValidateDxf(editedDxf)} disabled={isButtonDisabled()}>Valider</GreenButton>
                        </Grid>

                    </CardActions>
                </Card>
            </DialogContent>
        </Dialog>
    );
}
