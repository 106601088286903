import { Button, ButtonGroup, Chip, CircularProgress, Divider, Fab, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDeleteItemBasket, useEditBasketItem, useGetBasket } from '../contexts/BasketContext'
import { Alert, AlertTitle } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({

    root: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1)

    },
    svg: {
        height: '150px'
    },
    image: {
        height: '150px',
        width: '200px',
        objectFit: 'contain'
    },
    price: {
        marginLeft: theme.spacing(0.5),
        fontWeight: 'bold'
    },
    priceChip: {
        width: '100%',
    },
    priceChipVat: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    previewContainer: () => theme.breakpoints.down('xs') ? { textAlign: 'center' } : null
}))


function CartLinePO({ item, control }) {
    const classes = useStyles()

    const [imgPreview, setimgPreview] = useState(null);
    const [loadingDXF, setloadingDXF] = useState(true);
    const [loadingQuantity, setloadingQuantity] = useState(false)
    const [loadingError, setloadingError] = useState(false);
    const [loadingDelete, setloadingDelete] = useState(false);

    const { getBasket } = useGetBasket()
    const { editItemBasket } = useEditBasketItem()
    const { deleteItemBasket } = useDeleteItemBasket()

    useEffect(() => {
        (async () => {
            setloadingDXF(true)
            try {

                setimgPreview(<img className={classes.image} src={`${process.env.REACT_APP_BASE_API}/download/image/${item.image}`} alt="" />)

            } catch (e) {
                setloadingError(true)
            }
            setloadingDXF(false)
        })()

    }, []);

    const isCustomProduct = [1, 2, 3].includes(+item.idExt);

    const formatLibele = () => {
        // Cherche l'index de la première parenthèse dans la chaîne extLibele.
        const index = item.extLibele.indexOf("(");
        // Si la parenthèse n'est pas trouvée, renvoie la chaîne entière. Sinon, renvoie la partie avant la parenthèse.
        return index === -1 ? item.extLibele : item.extLibele.slice(0, index).trim();
    }

    const formatMaterial = () => {
        // Cherche l'index de "(Matière :" dans la chaîne extLibele.
        const indexStart = item.extLibele.indexOf("(Matière :");
        // Si "(Matière :" n'est pas trouvé, renvoie une chaîne vide. 
        // Sinon, cherche l'index de la prochaine parenthèse après "(Matière :" et renvoie la partie entre eux.
        if (indexStart === -1) {
            return "";
        } else {
            const indexEnd = item.extLibele.indexOf(" - ", indexStart);
            return item.extLibele.slice(indexStart + 10, indexEnd).trim();
        }
    }

    const formatColor = () => {
        // Cherche l'index de "(Couleur :" dans la chaîne extLibele.
        const indexStart = item.extLibele.indexOf("(Couleur :");
        // Si "(Couleur :" n'est pas trouvé, renvoie une chaîne vide. 
        // Sinon, cherche l'index de la prochaine parenthèse après "(Couleur :" et renvoie la partie entre eux.
        if (indexStart === -1) {
            return "";
        } else {
            const indexEnd = item.extLibele.indexOf(")", indexStart);
            return item.extLibele.slice(indexStart + 10, indexEnd).trim();
        }
    }

    const handelClickAdd = useCallback(
        () => {
            (async () => {
                setloadingQuantity(true)
                const newBasketItem = {
                    ...item,
                    quantity: item.quantity + 1
                }
                await editItemBasket(newBasketItem)
                await getBasket();
                setloadingQuantity(false)
            })()
        },
        [editItemBasket, getBasket, item],
    )

    const handelClickSub = useCallback(
        () => {
            (async () => {
                setloadingQuantity(true)
                const newBasketItem = {
                    ...item,
                    quantity: item.quantity - 1
                }
                await editItemBasket(newBasketItem)
                await getBasket();
                setloadingQuantity(false)
            })()
        },
        [editItemBasket, getBasket, item],
    )

    const handelClickDelete = useCallback(
        () => {
            (async () => {
                setloadingDelete(true);
                await deleteItemBasket(item._id);
                await getBasket();
                setloadingDelete(false);
            })()
        },
        [deleteItemBasket, getBasket, item._id],
    )

    return (
        <Paper className={classes.root} elevation={0}>
            <Grid container spacing={4}>
                <Grid item style={{ background: 'aliceblue' }} xs={12} sm={3} container justify='center'>
                    <List dense>
                        <ListItem>
                            <ListItemText
                                primary="Nom"
                                secondary={isCustomProduct ? item?.extLibele : formatLibele()}
                            />
                        </ListItem>
                        {
                            item?.thickness &&
                            <ListItem>
                                <ListItemText
                                    primary="Epaisseur"
                                    secondary={item.thickness}
                                />
                            </ListItem>
                        }

                        {item?.color?.ral &&
                            <ListItem>
                                <ListItemText
                                    primary="RAL"
                                    secondary={item?.color?.ral}
                                />
                            </ListItem>
                        }
                        {(item?.color?.libele || (!isCustomProduct && formatColor())) &&
                            <ListItem>
                                <ListItemText
                                    primary="Couleur"
                                    secondary={isCustomProduct ? item?.color?.libele : formatColor()}
                                />
                            </ListItem>
                        }

                    </List>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid container direction='column' spacing={2}>
                        <Grid item xs={12}>
                            {
                                loadingError ?
                                    <Alert severity="error">
                                        <AlertTitle>Erreur du chargement de l'apperçus</AlertTitle>

                                    </Alert>
                                    : <div className={classes.previewContainer}>{imgPreview}</div>
                            }


                        </Grid>
                        <Grid item xs={12} container >
                            {item.maxWidth && item.maxHeight &&

                                <Grid xs={12}>
                                    <Typography variant="body2" gutterBottom display='inline'>
                                        Dimensions dépliées :
                                    </Typography>
                                    <br />
                                    <Typography className={classes.price} variant="body2" gutterBottom display='inline'>
                                        {loadingQuantity ? <CircularProgress size={20} /> : `${item.maxWidth} x ${item.maxHeight} mm`}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                </Grid>
                {control !== false ?
                    <Grid item xs={12} sm={3}>
                        <Grid container direction='column' justify='space-around' alignItems='center' alignContent='center' spacing={2}>
                            <Grid item xs={12}>
                                <Grid container direction='column' justify='center' alignItems='center' alignContent='center'>
                                    <Grid item xs={12}>
                                        <Typography variant="button" gutterBottom display='inline'>
                                            Qte : {loadingQuantity ? <CircularProgress size={20} /> : item.quantity}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ButtonGroup>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={handelClickSub}
                                                disabled={item.quantity <= 1}
                                            >
                                                <RemoveIcon fontSize="small" />
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={handelClickAdd}
                                            >
                                                <AddIcon fontSize="small" />
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12}>
                                <Fab size="small" color="secondary" aria-label="delete" onClick={handelClickDelete}>
                                    {loadingDelete ? <CircularProgress size={20} color='secondary' /> : <DeleteIcon />}
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Typography variant="button" gutterBottom display='inline'>
                        Qte : {loadingQuantity ? <CircularProgress size={20} /> : item.quantity}
                    </Typography>
                }

            </Grid>

        </Paper>
    )
}

export default CartLinePO
