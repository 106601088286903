import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import GreenButton from '../Button/GreenButton'
import { CircularProgress, Grid } from '@material-ui/core';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import MuiAlert from '@material-ui/lab/Alert';
import { displayFormatedPrice, calculPriceWithVat } from '../../modules/utils';
import { PropertiesContext } from '../../contexts/PropertiesContext';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    dialog: {
        padding: 0,
    },
    svg: {
        display: 'flex',
        marginTop: theme.spacing(4),
        justifyContent: 'center',
        maxHeight: '400px',
        overflow: 'auto',
        '& svg': {
            maxHeight: '400px',
            width: '400px'
        }
    },
    action: {
        justifyContent: 'space-between'
    }
}));

export default function DialogShowDXFDropResult({ price, svg, open, handleClose, handelAddToBasket, postBasketLoading, qte, collision, handelClickNext, lastFile }) {


    const classes = useStyles();
    const [property] = useContext(PropertiesContext);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableBackdropClick={true}
        >

            <DialogContent className={classes.dialog}>
                <Card raised={false} className={classes.root}>
                    <Typography variant="h5" align='center' color='primary' gutterBottom>
                        Aperçu de votre découpe
                    </Typography>
                    <div className={classes.svg} dangerouslySetInnerHTML={{ __html: svg }} />
                    {
                        collision ? <MuiAlert elevation={0} variant="filled" severity="error">Fichier DXF non valide, merci de vérifier votre fichier</MuiAlert> : null
                    }
                    {
                        !collision ?
                            <CardContent>
                                <Grid container justify='space-between'>

                                    <Grid item xs={12} sm={3}>
                                        <Grid container justify='space-between'>
                                            <Grid item xs={12}>
                                                <Grid container justify='space-between' alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="button" display="inline" gutterBottom>
                                                            quantité :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography gutterBottom display="inline" variant="console" component="p">
                                                            {qte}
                                                        </Typography>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify='space-between'>
                                            <Grid item xs={12}>
                                                <Grid container justify='space-between'>
                                                    <Grid item>
                                                        <Typography variant="button" display="inline" gutterBottom>
                                                            prix HT :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>

                                                        <Typography gutterBottom display="inline" variant="h5" component="p">
                                                            {displayFormatedPrice(price)} €
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container justify='space-between'>
                                                    <Grid item>
                                                        <Typography variant="button" display="inline" gutterBottom>
                                                            prix TTC :
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item>

                                                        <Typography gutterBottom display="inline" variant="h5" component="p">
                                                            {displayFormatedPrice(calculPriceWithVat(price, property.vatRate))} €
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Grid>

                            </CardContent>
                            : null
                    }

                    <CardActions className={classes.action}>
                        <Grid container justify='center' spacing={2} direction='row-reverse'>
                            {
                                !collision &&
                                <Grid item xs={10} sm={5}>
                                    <GreenButton startIcon={!postBasketLoading ? <ShoppingBasketIcon /> : <CircularProgress />} onClick={handelAddToBasket}>
                                        Ajouter au panier
                                    </GreenButton>
                                </Grid>
                            }
                            <Grid item xs={5} sm={3}>
                                <Button fullWidth onClick={handleClose} variant="contained" color="secondary">
                                    Annuler
                                </Button>
                            </Grid>
                            <Grid item xs={5} sm={3}>
                                <Button fullWidth onClick={handelClickNext} color='primary' variant="contained">Ignorer</Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </DialogContent>
        </Dialog>
    );
}
