import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ConfiguratorMenu from '../EndUser/Configurator/ConfiguratorMenu';

function DialogConfigurationMenu({ open, setopenDialog }) {
    const history = useHistory();
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='sm'
        >
            <DialogContent>
                <Typography color='primary' align='center' style={{ marginTop: '1rem' }} variant="h5" gutterBottom>Choisissez votre matière</Typography>
                <ConfiguratorMenu withoutNbPiece />
            </DialogContent>
            <DialogActions>
                {!process.env.REACT_APP_IFRAME_MODE && <Button onClick={() => { history.push('/') }} color="primary">
                    Sortir
                </Button>}

            </DialogActions>
        </Dialog>
    )
}

export default DialogConfigurationMenu
