import React, { useContext, useCallback, useState } from 'react';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import { PropertiesContext } from '../contexts/PropertiesContext'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableCellEditable from './TableCellEditable';
import EditIcon from '@material-ui/icons/Edit';
import { rateSchema, foldingMaxWidthSchemas, foldingMaxLengthSchemas, machineMaxLengthSchemas, machineMaxWidthSchemas, vatRateSchema, cutSizeSchemas, invoiceNextReminderDateSchemas } from '../modules/validation';
import { Button, Switch, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ModalAddIncreasePlan from './ModalAddIncreasePlan';
import RemoveIcon from '@material-ui/icons/Remove';
import ModalAddTransportDelay from './ModalAddTransportDelay';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(5)
    },
    tableCellCategory: {
        background: 'aliceblue'

    },
    tableCellProperty: {
        background: 'antiquewhite'
    },
    tableCellValue: {
        background: 'cornsilk'
    }
}));

function PropertyHandler() {
    const [properties, dispatch] = useContext(PropertiesContext);
    const [rate, setrate] = useState('')
    const [costPerKm] = useState('')

    const [minPlanCutting, setminPlanCutting] = useState('');
    const [machineMaxWidth, setmachineMaxWidth] = useState('');
    const [machineMaxLength, setmachineMaxLength] = useState('');
    const [foldingMinWidth, setfoldingMinWidth] = useState('');
    const [foldingMinLength, setfoldingMinLength] = useState('');
    const [foldingRate, setfoldingRate] = useState('');
    const [minPlanFolding, setminPlanFolding] = useState('');
    const [maxWeightPerOperator, setmaxWeightPerOperator] = useState('');
    const [maxLengthPerOperator, setmaxLengthPerOperator] = useState('');
    const [cutSize, setcutSize] = useState('');
    const [foldingMaxWidth, setfoldingMaxWidth] = useState('');
    const [foldingMaxLength, setfoldingMaxLength] = useState('');
    const [transportMaxWidth, settransportMaxWidth] = useState('');
    const [transportMaxLength, settransportMaxLength] = useState('');
    const [isNewIncreasePlan, setisNewIncreasePlan] = useState(false);
    const [isNewTransportDelay, setisNewTransportDelay] = useState(false);
    const [newIncreasePlan, setnewIncreasePlan] = useState({ from: '', price: '' });
    const [invoiceNextReminderDate, setInvoiceNextReminderDate] = useState(null);
    const [customLengthIncrease, setCustomLengthIncrease] = useState(null);

    const [startingPlanFolding, setstartingPlanFolding] = useState('');
    const [vatRate, setvatRate] = useState('');

    const classes = useStyles();

    const handelEditProperty = useCallback(
        (key, value) => {
            return (async () => {
                try {
                    if (!properties || !properties._id) {
                        const data = {
                            rate,
                            costPerKm,
                            minPlanCutting,
                            machineMaxWidth,
                            machineMaxLength,
                            foldingRate,
                            minPlanFolding,
                            maxWeightPerOperator,
                            maxLengthPerOperator,
                            foldingMaxWidth,
                            foldingMaxLength,
                            transportMaxWidth,
                            transportMaxLength,
                            vatRate,
                            startingPlanFolding
                        }
                        const res = await axios.post(process.env.REACT_APP_BASE_API + '/propertie', data)
                        dispatch({
                            type: 'SET_PROPERTY',
                            payload: res.data.message
                        })

                    } else {
                        const newData = { ...properties, [key]: value }
                        const res = await axios.put(process.env.REACT_APP_BASE_API + '/propertie/' + properties._id, newData)
                        dispatch({
                            type: 'SET_PROPERTY',
                            payload: res.data.message
                        })
                    }

                } catch (e) {

                }

            })()
        },
        [costPerKm, dispatch, foldingMaxLength, foldingMaxWidth, foldingRate, machineMaxLength, machineMaxWidth, maxLengthPerOperator, maxWeightPerOperator, minPlanCutting, minPlanFolding, properties, rate, transportMaxLength, transportMaxWidth, vatRate],
    )

    const handelEditIncreasePlan = useCallback(
        (id, key) => {
            (async () => {
                const newData = { ...properties, increasePlans: properties.increasePlans.map(item => item._id !== id ? item : { ...item, [key]: newIncreasePlan[key] }) }
                const res = await axios.put(process.env.REACT_APP_BASE_API + '/propertie/' + properties._id, newData)
                dispatch({
                    type: 'SET_PROPERTY',
                    payload: res.data.message
                })
            })()

        },
        [dispatch, newIncreasePlan, properties],
    )

    const handelclickAddIncreasePlan = useCallback(
        () => {
            setisNewIncreasePlan(true)
        },
        [],
    )


    const handelclickDeleteIncreasePlan = useCallback(
        (id) => {
            (async () => {
                const newproperties = { ...properties, increasePlans: properties.increasePlans.filter(increasePlan => increasePlan._id !== id) }
                try {
                    await axios.put(process.env.REACT_APP_BASE_API + '/propertie/' + properties._id, newproperties)
                    dispatch({
                        type: 'SET_PROPERTY',
                        payload: newproperties
                    })
                } catch (e) {

                }

            })()
        },
        [dispatch, properties],
    )


    const getIncreasePlansTableBody = useCallback(
        () => {
            return properties?.increasePlans?.map((increasePlan, index) => <TableRow>


                <TableCell>{`Forfait ${index + 1} jusqu'à`}</TableCell>
                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={newIncreasePlan.to} yupValidation={rateSchema} onCickEdited={() => handelEditIncreasePlan(increasePlan._id, 'to')} onChange={(e) => setnewIncreasePlan({ ...newIncreasePlan, to: e.target.value })} icon={<EditIcon style={{ fontSize: 20 }} />} >{increasePlan.to}</TableCellEditable>
                <TableCell>{`Prix`}</TableCell>
                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={newIncreasePlan.price} yupValidation={rateSchema} onCickEdited={() => handelEditIncreasePlan(increasePlan._id, 'price')} onChange={(e) => setnewIncreasePlan({ ...newIncreasePlan, price: e.target.value })} icon={<EditIcon style={{ fontSize: 20 }} />}>{increasePlan.price}</TableCellEditable>

                <TableCell style={{ padding: 0 }}><Button style={{ width: '100%' }} onClick={() => handelclickDeleteIncreasePlan(increasePlan._id)}><RemoveIcon color="secondary" /></Button></TableCell>
            </TableRow>)
        },
        [classes.tableCellValue, handelEditIncreasePlan, handelclickDeleteIncreasePlan, newIncreasePlan, properties?.increasePlans],
    )


    return (

        <TableContainer component={Paper} className={classes.root} elevation={0}>
            <Typography variant="h5" align='center' color='primary' style={{ marginBottom: '1rem' }}>Propriétés générales</Typography>
            <Table aria-label="tableau des propriétés">

                <TableBody>
                    <TableRow>
                        <TableCell align="left" className={classes.tableCellCategory}>Général</TableCell>
                        <Table>
                            <TableBody>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Taux de TVA <small>(%)</small></TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={vatRate} yupValidation={vatRateSchema} onCickEdited={() => handelEditProperty('vatRate', vatRate)} onChange={(e) => setvatRate(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} />}>{properties?.vatRate || ''}</TableCellEditable>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Nombre de jour(s) 1er relance</TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={invoiceNextReminderDate} yupValidation={invoiceNextReminderDateSchemas} onCickEdited={() => handelEditProperty('invoiceNextReminderDate', invoiceNextReminderDate)} onChange={(e) => setInvoiceNextReminderDate(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} />}>{properties?.invoiceNextReminderDate || ''}</TableCellEditable>
                                </TableRow>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Augmentation pour longueur personnalisée (%)</TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={customLengthIncrease} yupValidation={invoiceNextReminderDateSchemas} onCickEdited={() => handelEditProperty('customLengthIncrease', customLengthIncrease)} onChange={(e) => setCustomLengthIncrease(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} />}>{properties?.customLengthIncrease || ''}</TableCellEditable>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" className={classes.tableCellCategory}>Découpage</TableCell>
                        <Table>
                            <TableBody>
                                <TableRow >
                                    <TableCell align="left" className={classes.tableCellProperty}>Largeur coupe</TableCell>
                                    <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={cutSize} yupValidation={cutSizeSchemas} onCickEdited={() => handelEditProperty('cutSize', cutSize)} onChange={(e) => setcutSize(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} />}>{properties?.cutSize ?? ''}</TableCellEditable>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableRow>
                    <TableRow >
                        <TableCell align="left" className={classes.tableCellCategory}>
                            Pliage
                            <Switch
                                style={{ position: 'absolute' }}
                                checked={properties.foldingEnabled ? true : false}
                                onChange={(e) => handelEditProperty('foldingEnabled', e.target.checked)}
                                color="primary"
                                name="transportEnabled"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </TableCell>
                        <Table>
                            <TableBody style={{ opacity: !properties.foldingEnabled ? '35%' : null }}>

                                <TableRow>
                                    <TableCell className={classes.tableCellCategory}>Capacités machine</TableCell>
                                    <Table>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell align="left" className={classes.tableCellProperty}>Largeur maximale <small>(mm)</small></TableCell>
                                                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={foldingMaxWidth} yupValidation={foldingMaxWidthSchemas} onCickEdited={() => handelEditProperty('foldingMaxWidth', foldingMaxWidth)} onChange={(e) => setfoldingMaxWidth(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} >{properties?.foldingMaxWidth || ''}</TableCellEditable>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className={classes.tableCellProperty}>Longueur maximale <small>(mm)</small></TableCell>
                                                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={foldingMaxLength} yupValidation={foldingMaxLengthSchemas} onCickEdited={() => handelEditProperty('foldingMaxLength', foldingMaxLength)} onChange={(e) => setfoldingMaxLength(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />} >{properties?.foldingMaxLength || ''}</TableCellEditable>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className={classes.tableCellProperty}>Largeur minimale <small>(mm)</small></TableCell>
                                                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={foldingMinWidth} yupValidation={machineMaxWidthSchemas} onCickEdited={() => handelEditProperty('foldingMinWidth', foldingMinWidth)} onChange={(e) => setfoldingMinWidth(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{properties?.foldingMinWidth || ''}</TableCellEditable>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="left" className={classes.tableCellProperty}>Longueur minimale <small>(mm)</small></TableCell>
                                                <TableCellEditable className={classes.tableCellValue} justify="start" align="left" value={foldingMinLength} yupValidation={machineMaxLengthSchemas} onCickEdited={() => handelEditProperty('foldingMinLength', foldingMinLength)} onChange={(e) => setfoldingMinLength(e.target.value)} icon={<EditIcon style={{ fontSize: 15 }} fontSize='small' />}>{properties?.foldingMinLength || ''}</TableCellEditable>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableRow>
                    <TableRow >
                        <TableCell align="left" className={classes.tableCellCategory}>Réduction/Longueur <small>(%)</small> </TableCell>
                        <Table>
                            <TableBody>
                                <TableRow >
                                    <TableCell><Button startIcon={<AddIcon onClick={handelclickAddIncreasePlan} color='secondary' />}></Button></TableCell>
                                    <Table>
                                        <TableBody>
                                            {getIncreasePlansTableBody()}

                                        </TableBody>
                                    </Table>
                                </TableRow>
                            </TableBody>
                        </Table>

                    </TableRow>
                    <TableRow >
                        <TableCell align="left" className={classes.tableCellCategory}>Transport <Switch
                            style={{ position: 'absolute' }}
                            checked={properties.transportEnabled ? true : false}
                            onChange={(e) => handelEditProperty('transportEnabled', e.target.checked)}
                            color="primary"
                            name="transportEnabled"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        /></TableCell>
                        <Table>
                            <TableBody style={{ opacity: !properties.transportEnabled ? '35%' : null }}>
                            </TableBody>
                        </Table>

                    </TableRow>
                    <TableRow >
                        <TableCell align="left" className={classes.tableCellCategory}>Retrait sur place autorisé <Switch
                            style={{ position: 'absolute' }}
                            checked={properties.pickUpAllowed ? true : false}
                            onChange={(e) => handelEditProperty('pickUpAllowed', e.target.checked)}
                            color="primary"
                            name="transportEnabled"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        /></TableCell>

                    </TableRow>
                </TableBody>
            </Table>
            <ModalAddIncreasePlan open={isNewIncreasePlan} setdiagOpen={setisNewIncreasePlan} />
        </TableContainer>
    )
}

export default PropertyHandler
