
import React, { useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useContext } from 'react';
import { CatalogContext } from '../../../contexts/CatalogContext';
import { Button, CardActionArea, CardActions } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        cursor: 'pointer',
        marginBottom: '1rem',
        '&:hover': {
            boxShadow: theme.shadows[8]
        }
    },
    details: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        height: 'max-content',
        alignSelf: 'center'
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    previewImage: {
        '& img': {
            height: '100px'
        },
        '& svg': {
            height: '100px'
        },
        width: '50%'
    },
    selected: {
        borderLeft: `5px solid ${theme.palette.primary.main}`
    },
    media: {

        objectFit: 'contain'

    }
}));
function CatalogCard({ data }) {

    const [, dispatch] = useContext(CatalogContext)

    const handelClick = useCallback(
        () => {
            dispatch({
                type: 'SET_SELECTED_CATALOG',
                payload: data._id
            })
            dispatch({
                type: 'SET_STEP',
                payload: 1
            })
        },
        [data._id, dispatch],
    )

    const classes = useStyles();

    return (
        <Card className={classes.root} onClick={handelClick}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt={data.name}
                    height="140"
                    image={`${process.env.REACT_APP_BASE_API}/download/image/${data.file}`}
                    title={data.name}
                    className={classes.media}
                />
                <CardContent>
                    <Typography gutterBottom variant="button" component="h2">
                        {data.name}
                    </Typography>

                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" variant="outlined" color="primary">
                    Choisir
                </Button>

            </CardActions>
        </Card>
    )

    // return (
    //     <Card className={isSelected() ? [classes.root, classes.selected] : [classes.root]} onClick={handelClick} elevation={isSelected() ? 1 : 2}>
    //         <div className={classes.details}>
    //             <CardContent className={classes.content}>
    //                 <Typography variant="subtitle1">
    //                     {data.name}
    //                 </Typography>

    //             </CardContent>
    //         </div>
    //         <div className={classes.previewImage}>
    //             <CatalogImagePreview image={data.file} />
    //         </div>
    //     </Card>
    // )
}

export default CatalogCard
