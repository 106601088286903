import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Input, InputAdornment, Typography } from '@material-ui/core';
import { useMaterialDetails } from '../../hooks/useMaterialDetails';
import { useState } from 'react';
import { useUpdateEstimate } from '../../contexts/EstimateContext';
import { useUpdateOrder } from '../../contexts/OrderContext';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const DialogShowBasketsDetails = ({ type, open, handleClose, estimate: { id: estimateId = null, client: user = {}, content: baskets = [], ...estimate } } = {}) => {
  const classes = useStyles();
  const [isChanged, setIsChanged] = useState(false);
  const [modifiedBaskets, setModifiedBaskets] = useState(null);

  const { updateEstimate } = useUpdateEstimate();
  const { updateOrder } = useUpdateOrder();
  const { getDetails } = useMaterialDetails();

  useEffect(() => {
    if (!modifiedBaskets) {
      //setModifiedBaskets(baskets);
      setModifiedBaskets({ forms: baskets.filter(basket => basket.type !== 'accessory'), accessories: baskets.filter(b => b.type === 'accessory') })
    }

  }, [baskets])



  const handleChange = (event, field, type, index) => {
    const updatedBaskets = {
      ...modifiedBaskets, [type]: modifiedBaskets[type].map((basket, i) => {
        if (i === index) {
          return { ...basket, [field]: event.target.value };
        }
        return basket;
      })
    }
    setModifiedBaskets(updatedBaskets);
    setIsChanged(true);

  };


  const handleSave = async () => {
    if (isChanged) {
      const baskets = [...modifiedBaskets['forms'].map(basket => ({ ...basket, price: +basket.price, discount: +basket.discount })), ...modifiedBaskets['accessories'].map(basket => ({ ...basket, price: +basket.price, discount: +basket.discount }))]

      const arePricesValid = baskets.every((basket) => !isNaN(basket.price));

      if (arePricesValid) {
        if (type === 'order') {
          await updateOrder(estimateId, { baskets: baskets });
        } else {
          await updateEstimate(estimateId, { baskets: baskets });
        }
        setIsChanged(false);
        handleClose();
      } else {
        // Vous pouvez afficher une erreur ou un message d'alerte pour informer l'utilisateur que les prix ne sont pas valides
        console.error('Les prix entrés ne sont pas valides.');
      }
    }
  };

  const getRows = () => {
    if (!modifiedBaskets) {
      return null
    }
    return modifiedBaskets?.forms?.map((basket, index) => {

      const puht = (basket.price / basket.quantity).toFixed(2).padStart(5, '0');
      return (<TableRow key={index}>
        <TableCell>{basket.clientRef || '--'}</TableCell>
        <TableCell>{basket.materialName}</TableCell>
        <TableCell>{basket.shadeName}</TableCell>
        <TableCell>{basket.thickness}</TableCell>
        <TableCell>{basket.quantity}</TableCell>
        <TableCell>
          <Input
            type="number"
            value={puht}
            disabled
            //onChange={(event) => handleChange(event, 'price', index)}
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
          />
        </TableCell>
        <TableCell>
          <Input
            type="number"
            value={basket.price}
            disabled
            //onChange={(event) => handleChange(event, 'price', index)}
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
          />
        </TableCell>
        <TableCell>
          <Input
            type="number"
            value={basket.discount || user.discount}
            onChange={(event) => handleChange(event, 'discount', 'forms', index)}
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
          />
        </TableCell>
        <TableCell>
          {basket.maxWidth} x {basket.maxHeight}
        </TableCell>
        <TableCell>
          {basket.nbFolding}
        </TableCell>
      </TableRow>)
    }
    )
  }


  const getRowsAccessories = () => {
    if (!modifiedBaskets) {
      return null
    }
    return modifiedBaskets?.accessories?.map((basket, index) => {
      return (<TableRow key={index}>
        <TableCell>{basket.refExt}</TableCell>
        <TableCell>{basket.extLibele}</TableCell>
        <TableCell>{basket.quantity}</TableCell>
        <TableCell>
          <Input
            type="number"
            value={(basket.price / basket.quantity).toFixed(2)}
            disabled
            //onChange={(event) => handleChange(event, 'price', index)}
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
          />
        </TableCell>
        <TableCell>
          <Input
            type="number"
            value={basket.discount || user.discount}
            onChange={(event) => handleChange(event, 'discount', 'accessories', index)}
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
          />
        </TableCell>


      </TableRow>)
    }
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'xl'}
      scroll='body'
    >
      <DialogContent >
        <DialogContentText >
          <TableContainer>
            {!!modifiedBaskets?.forms?.length &&
              <Table className={classes.table} >
                <TableHead>
                  <TableRow>
                    <TableCell>Ref client</TableCell>
                    <TableCell>Matière</TableCell>
                    <TableCell>RAL</TableCell>
                    <TableCell>Épaisseur (mm)</TableCell>
                    <TableCell>Quantité</TableCell>
                    <TableCell>PU</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Remise (%)</TableCell>
                    <TableCell>Dimension (mm)</TableCell>
                    <TableCell>plis</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getRows()}
                </TableBody>
              </Table>}
            {modifiedBaskets?.accessories?.length ?
              <>
                <Typography style={{ marginTop: '2rem' }} display="block" variant="button">Accessoires</Typography>
                <Table className={classes.table} >
                  <TableHead>
                    <TableRow>
                      <TableCell>Ref</TableCell>
                      <TableCell>Nom</TableCell>
                      <TableCell>Quantité</TableCell>
                      <TableCell>PU</TableCell>
                      <TableCell>Remise (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getRowsAccessories()}
                  </TableBody>
                </Table> </> : null
            }

          </TableContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={handleClose} variant='contained' color="secondary">
            Fermer
          </Button>
          {isChanged && (
            <Button onClick={handleSave} variant="contained" color="primary">
              Enregistrer
            </Button>
          )}
        </>

      </DialogActions>

    </Dialog>


  );
}

export default DialogShowBasketsDetails;