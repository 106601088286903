import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useState } from 'react';
import { usePost } from '../../modules/request';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { grey } from '@material-ui/core/colors'

export default function ModalGenInvoice({ open, setOpen, invoicesCreated }) {

  const [isGenerate, setIsGenerate] = useState(false);

  const post = usePost()
  const [, dispatchSnackbar] = useContext(SnackbarContext)

  const handleClose = () => {
    setOpen(false);
  };

  const handleGenInvoice = async () => {
    try {
      setIsGenerate(true);

      for (const invoice of invoicesCreated) {

        const response = await post('/calculate/invoice', { invoiceId: invoice._id }, {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `facture-${invoice.ref}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      }


      dispatchSnackbar({
        type: 'SET_SNACKBAR',
        payload: {
          open: true,
          content: 'Edition effectué',
          type: 'success',
          duration: 4000
        }
      });
    } catch (e) {
      console.log(e);
      dispatchSnackbar({
        type: 'SET_SNACKBAR',
        payload: {
          open: true,
          content: 'Erreur lors de l\'édition',
          type: 'error',
          duration: 4000
        }
      });
    } finally {
      setIsGenerate(false);
      handleClose()
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle><Typography variant="h6" align="center" color="primary">Générer les factures</Typography></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body" align="center" display="block" color="primary">Les commandes selectionnées ont étaient transférées en factures</Typography>
            <Typography variant="body" align="center" display="block" color="primary">Souhaitez-vous les éditer en PDF maintenant ?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
            Fermer
          </Button>
          <Button onClick={handleGenInvoice} variant="contained" color="primary" autoFocus>
            {isGenerate ? <CircularProgress size={20} color={grey[50]} /> : 'Oui'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
