import { Grid, Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react'
import { AccessoriesContext, useGetAccessories } from '../../../contexts/AccessoriesContext';
import AccessoryCard from './AccessoryCard';

function DisplayAccessories({ handleValidateDelete, delectable, selectable, onSelect, handleEditAccessory }) {

    const [{ accessories }] = useContext(AccessoriesContext);
    const { getAccessories } = useGetAccessories()
    useEffect(() => {
        if (!accessories || !accessories.length) {
            getAccessories()
        }
    }, []);

    const filtredAccessories = () => {
        let filtredCatalogSvg = accessories;
        // do some filters
        return filtredCatalogSvg;
    }

    const getCatalogSvgToDisplay = () => {
        const accessories = filtredAccessories();
        if (accessories && accessories.length) {
            return accessories.map(accessory => <Grid key={accessory._id} item xs={12} sm={2}><AccessoryCard data={accessory} delectable={delectable} selectable={selectable} onSelect={onSelect} handleValidateDelete={handleValidateDelete} handleEditAccessory={handleEditAccessory} /></Grid>)
        } else {
            return <Grid container justify='center'>
                <Typography align="center" display='block' variant="button" gutterBottom>
                    Aucun de accessoire à afficher
                </Typography>
            </Grid>
        }
    }

    return (
        <Grid container spacing={2}>
            {getCatalogSvgToDisplay()}
        </Grid>
    )
}

export default DisplayAccessories