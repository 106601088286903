import React, { useEffect, useContext } from 'react'
import axios from 'axios'
import MaterialHandler from './MaterialHandler';
import { MaterialsContext } from '../contexts/MaterialsContext'
import { Box, Grid } from '@material-ui/core';
import PropertyHandler from './PropertyHandler';

function ParamHandler() {

    const [materials, dispach] = useContext(MaterialsContext);

    useEffect(() => {
        (async () => {
            const materials = await axios.get(process.env.REACT_APP_BASE_API + '/material')
            dispach({
                type: 'SET_MATERIALS',
                payload: materials.data.message
            })
        })()

    }, [dispach]);

    return (
        <>
            <Grid container justify="space-around" spacing={3} style={{ height: '100%', backgroundColor: '#f1f1f1', padding: '3rem 0' }}>
                <Grid item xs={12} sm={5}>
                    <Box mx={0}>
                        <MaterialHandler />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box mx={0}>
                        <PropertyHandler />
                    </Box>
                </Grid>
            </Grid>
        </>

    )
}

export default ParamHandler
