import React from 'react';
import TextField from '@material-ui/core/TextField';

function AutoSelectTextField(props) {

  const inputRef = React.useRef(null);

  const handleKeyDown = (e) => {
    if (!inputRef.current) return;

    const element = inputRef.current;
    const currentRow = element.closest('tr');

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        const previousRow = currentRow.previousElementSibling;
        if (previousRow) {
          const target = previousRow.querySelector(`td:nth-child(${element.closest('td').cellIndex + 1}) input[type="text"], td:nth-child(${element.closest('td').cellIndex + 1}) input[type="tel"]`);
          target && target.focus();
        }
        break;

      case 'ArrowDown':
        e.preventDefault();
        const nextRow = currentRow.nextElementSibling;
        if (nextRow) {
          const target = nextRow.querySelector(`td:nth-child(${element.closest('td').cellIndex + 1}) input[type="text"], td:nth-child(${element.closest('td').cellIndex + 1}) input[type="tel"]`); target && target.focus();
        }
        break;

      case 'ArrowLeft':
        e.preventDefault();
        const previousInput = currentRow.querySelector(`td:nth-child(${element.closest('td').cellIndex}) input[type="text"], td:nth-child(${element.closest('td').cellIndex}) input[type="tel"]`); previousInput && previousInput.focus();
        break;

      case 'ArrowRight':
        e.preventDefault();
        const nextInput = currentRow.querySelector(`td:nth-child(${element.closest('td').cellIndex + 2}) input[type="text"], td:nth-child(${element.closest('td').cellIndex + 2}) input[type="tel"]`);
        nextInput && nextInput.focus();
        break;

      default:
        break;
    }
  };


  return (
    <TextField
      {...props}
      ref={inputRef}
      type="tel"
      onKeyDown={handleKeyDown}
      onFocus={(e) => {
        if (props.onFocus) {
          props.onFocus(e);
        }
        e.target.select();
      }}
      onMouseUp={(e) => {
        e.preventDefault();
      }}
    />
  );
}

export default AutoSelectTextField;