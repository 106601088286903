import React, { useContext, useReducer } from 'react';
import { useGet, usePost, usePut } from '../modules/request';
import { BasketContext } from './BasketContext';


const InvoiceContext = React.createContext([[], () => { }]);

const initialState = []

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INVOICES":
      return action.payload;
    case "SET_INVOICE":
      return state.map(invoice => invoice._id !== action.payload.id ? invoice : action.payload.invoice);
    case "UPDATE_INVOICE":
      return state.map(invoice => invoice._id !== action.payload.id ? invoice : { ...invoice, [action.payload.key]: [action.payload.value] });
    case "REMOVE_INVOICE":
      return state.filter(invoice => invoice._id !== action.payload.id);
    default:
      throw new Error();
  }
};


const useGetInvoices = () => {
  const [state, dispatch] = useContext(InvoiceContext);
  const get = useGet();
  const put = usePut();

  return {
    getInvoices: async (options) => {
      const filters = options.filterModel;
      const filtersJSON = JSON.stringify(filters);

      let query = `?filters=${encodeURIComponent(filtersJSON)}`;

      const res = await get(`/invoice${query}`)
      dispatch({
        type: 'SET_INVOICES',
        payload: res.data.message
      })
    },
  };
}

const useGetMyInvoices = () => {
  const [state, dispatch] = useContext(InvoiceContext);
  const get = useGet()
  return {
    getMyInvoice: async ({ page, field, value }) => {
      const res = await get('/invoice/my')
      dispatch({
        type: 'SET_INVOICES',
        payload: res.data.message
      })
    },
  };
}

const usePostInvoice = () => {
  const [state, dispatch] = useContext(BasketContext);
  const post = usePost()
  return {
    postInvoice: async (data) => {
      try {
        const res = await post('/invoice', data)
        dispatch({
          type: 'SET_BASKET',
          payload: []
        })
        return res.data.message
      } catch (e) {
        return false
      }
    },
  };
}

const useUpdateInvoice = () => {
  const [, dispatch] = useContext(InvoiceContext);
  const put = usePut();

  return {
    updateInvoice: async (id, datas) => {
      try {
        const response = await put(`/invoice/${id}`, datas);
        dispatch({
          type: 'SET_INVOICE',
          payload: { id, invoice: response.data.message },
        });
        return response.data.message;
      } catch (e) {
        return false;
      }
    },
  };
};


function InvoiceProvider(props) {
  const [invoices, dispatch] = useReducer(reducer, initialState);

  return (
    <InvoiceContext.Provider value={[invoices, dispatch]}>
      {props.children}
    </InvoiceContext.Provider>
  );
};

export { InvoiceContext, InvoiceProvider, useGetInvoices, useGetMyInvoices, usePostInvoice, useUpdateInvoice };