import { Button, ButtonGroup, Chip, CircularProgress, Divider, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MaterialsContext } from '../contexts/MaterialsContext'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import { BasketContext, useDeleteItemBasket, useEditBasketItem, useGetBasket } from '../contexts/BasketContext'
import { Alert, AlertTitle } from '@material-ui/lab';
import { TextRotationNone } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import AutoSelectTextField from './Input/AutoSelectTextField';

const useStyles = makeStyles((theme) => ({

    root: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1)

    },
    svg: {
        height: '150px'
    },
    image: {
        height: '150px',
        width: '200px',
        objectFit: 'cover'
    },
    price: {
        marginLeft: theme.spacing(0.5),
        fontWeight: 'bold'
    },
    priceChip: {
        width: '100%',
    },
    priceChipVat: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    imageAccessory: {
        height: '50px',
        width: '50px',
        objectFit: 'contain'
    },
    table: {
        marginTop: theme.spacing(2)
    },
    tableRow: {
        "& td": {
            padding: "0"
        }
    },
    tableHead: {
        "& th": {
            fontWeight: 'bold'
        }
    },
    previewContainer: () => theme.breakpoints.down('xs') ? { textAlign: 'center' } : null
}))


function CartLine({ item, control, baskets }) {
    const classes = useStyles()
    const [materials] = useContext(MaterialsContext)
    const [imgPreview, setimgPreview] = useState(null);
    const [, dispatchBasketContext] = useContext(BasketContext);
    const [loadingQuantity, setloadingQuantity] = useState(false)
    const [loadingDelete, setloadingDelete] = useState(false);
    const [loadingError, setloadingError] = useState(false);

    const { editItemBasket } = useEditBasketItem();
    const { deleteItemBasket } = useDeleteItemBasket();

    const { getBasket } = useGetBasket();

    useEffect(() => {
        (async () => {
            try {

                const image = item.image
                setimgPreview(<img className={classes.image} src={`${process.env.REACT_APP_BASE_API}/download/image/${image}`} alt="" />)

            } catch (e) {
                setloadingError(true)
            }

        })()

    }, []);

    const getMaterial = useCallback(
        () => {
            return materials.find(material => material._id === item.materialId)
        },
        [item.materialId, materials],
    )

    const getShade = useCallback(
        () => {
            return materials.find(material => material._id === item.materialId)?.shades.find(shade => shade._id === item.shadeId)
        },
        [item.materialId, item.shadeId, materials],
    )

    const getThickness = useCallback(
        () => {
            return materials.find(material => material._id === item.materialId)?.shades.find(shade => shade._id === item.shadeId)?.thicknesses.find(thickness => thickness._id === item.thicknessId)
        },
        [item.materialId, item.shadeId, item.thicknessId, materials],
    )

    const handleChangeAccessoryQuantity = async (basketId, accessoryId, quantity) => {

        dispatchBasketContext({
            type: 'UPDATE_ACCESSORY_QUANTITY',
            payload: {
                accessoryId: accessoryId,
                basketId: basketId,
                quantity: +quantity
            }
        })

    }

    const handleBlurAccessoryQuantity = async (basketId, accessoryId, quantity) => {

        const accessoryToUpdate = baskets.find(b => b._id === accessoryId);
        const newBasketItem = {
            ...accessoryToUpdate,
            quantity: +quantity,
        }

        await editItemBasket(newBasketItem)
        await getBasket();

    }

    const handleChangeFormQuantity = async (basketId, quantity) => {
        dispatchBasketContext({
            type: 'UPDATE_QUANTITY',
            payload: {
                basketId: basketId,
                quantity: +quantity
            }
        })
    }

    const handleBlurFormQuantity = async (basketId, quantity) => {
        setloadingQuantity(true)
        const basketToUpdate = baskets.find(b => b._id === basketId);
        const newBasketItem = {
            ...basketToUpdate,
            quantity: +quantity,
        }
        await editItemBasket(newBasketItem)
        await getBasket();
        setloadingQuantity(false)
    }

    const handelClickDelete = async (basketId) => {
        setloadingDelete(true);
        await deleteItemBasket(basketId);
        await getBasket();
        setloadingDelete(false);
    }

    return (
        <Paper className={classes.root} elevation={0}>
            <Grid container spacing={4} xs={12}>
                <Grid item style={{ background: 'aliceblue' }} xs={12} sm={3} container justify='center'>
                    <List dense>
                        {
                            item.isCatalogItem ?
                                <ListItem>
                                    <ListItemIcon>
                                        <Chip label="Catalogue" />
                                    </ListItemIcon>
                                </ListItem>
                                : null
                        }

                        <ListItem>
                            <ListItemText
                                primary="Matière"
                                secondary={getMaterial()?.name}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="RAL"
                                secondary={getShade()?.name}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Epaisseur"
                                secondary={getThickness()?.value}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} sm={9} container >
                    <Grid item container xs={12}>

                        <Grid item xs={12} sm={8}>

                            <Grid container direction='column' spacing={2}>
                                <Grid item xs={12}>

                                    {
                                        loadingError ?
                                            <Alert severity="error">
                                                <AlertTitle>Erreur du chargement de l'apperçus</AlertTitle>

                                            </Alert>
                                            : <div className={classes.previewContainer}>{imgPreview}</div>
                                    }

                                </Grid>
                                <Grid item xs={12} container >
                                    <Grid xs={12}>
                                        <Typography variant="body2" gutterBottom display='inline'>
                                            Dimensions dépliées :
                                        </Typography>
                                        <br />
                                        <Typography className={classes.price} variant="body2" gutterBottom display='inline'>
                                            {loadingQuantity ? <CircularProgress size={20} /> : `${item.maxWidth} x ${item.maxHeight} mm`}
                                        </Typography>
                                    </Grid>
                                    {!!item.clientRef && <Grid xs={12}>
                                        <Typography variant="body2" gutterBottom display='inline'>
                                            Référence :
                                        </Typography>
                                        <Typography className={classes.price} variant="body2" gutterBottom display='inline'>
                                            {`${item.clientRef}`}
                                        </Typography>
                                    </Grid>}
                                </Grid>
                            </Grid>


                        </Grid>

                        {
                            control !== false ?
                                <Grid item xs={12} sm={4}>
                                    <Grid container direction='column' justify='space-around' alignItems='center' alignContent='center' spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container direction='column' justify='center' alignItems='center' alignContent='center'>
                                                <Grid item xs={12}>
                                                    <Typography variant="button" gutterBottom display='inline'>
                                                        Qte
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AutoSelectTextField inputProps={{ style: { textAlign: 'center' } }} type="tel" style={{ width: '50px' }} onBlur={(e) => handleBlurFormQuantity(item._id, e.target.value)} onChange={(e) => handleChangeFormQuantity(item._id, e.target.value)} value={item.quantity} />

                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <IconButton style={{ color: '#e91e63' }} size="small" onClick={() => handelClickDelete(item._id)}>
                                                {loadingDelete ? <CircularProgress size={20} color='secondary' /> : <ClearIcon />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                <Typography variant="button" gutterBottom display='inline'>
                                    Qte : {loadingQuantity ? <CircularProgress size={20} /> : item.quantity}
                                </Typography>
                        }
                    </Grid>
                    {
                        !!item.accessories.length && <Grid item xs={12}>
                            <TableContainer className={classes.table} elevation={0} component={Paper}>
                                <Table >
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            <TableCell align="center">Label</TableCell>
                                            <TableCell align="center">Quantitée</TableCell>
                                            <TableCell align="center">Ref</TableCell>
                                            <TableCell align="center">Image</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            item.accessories.map(accessory => <TableRow className={classes.tableRow}>
                                                <TableCell align="center">{accessory.extLibele}</TableCell>
                                                <TableCell align="center"><AutoSelectTextField inputProps={{ style: { textAlign: 'center' } }} style={{ width: '50px' }} onBlur={(e) => handleBlurAccessoryQuantity(item._id, accessory._id, e.target.value)} onChange={(e) => handleChangeAccessoryQuantity(item._id, accessory._id, e.target.value)} value={accessory.quantity} /></TableCell>
                                                <TableCell align="center">{accessory.refExt}</TableCell>
                                                <TableCell align="center"><img className={classes.imageAccessory} src={`${process.env.REACT_APP_BASE_API}/download/image/${accessory.image}`} alt="" /></TableCell>
                                                <TableCell align="center"><IconButton style={{ color: '#e91e63' }} size="small" onClick={() => handelClickDelete(accessory._id)}><ClearIcon /></IconButton></TableCell>

                                            </TableRow>)
                                        }


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }

                </Grid>

            </Grid>

        </Paper >
    )
}

export default CartLine
