import React, { useReducer } from 'react'

const ConfiguratorContext = React.createContext([[], () => { }])

const initialState = {
  materialId: null,
  shadeId: null,
  thicknessId: null,
  materialImageName: null,
  shadeImageName: null,
  size: null,
  form: 0,
  formToAdd: null,
  forms: [],
  ratio: 0,
  isDraging: false,
  mainFormX: 0,
  mainFormY: 0,
  mainForm: {
    configured: false,
    width: null,
    height: null,
    radius: null
  },
  price: 0,
  unitPrice: 0,
  nbPiece: 1,
  nbFolding: 0,
  priceLoading: false,
  length: 0,
  nbChains: 0,
  model: {},
  step: 0,
  selectedForm: '',
  formToAddDataTemp: {},
  realWidth: 0,
  realHeight: 0,
  weight: 0,
  isFromCatalog: false,
  shadeColor: null,
  realSurface: 0,
  accessories: {},
  formRef: ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_VALUE':
      return { ...state, [action.payload.key]: action.payload.data }
    case 'SET_VALUES':
      return { ...state, ...action.payload.data }
    case 'SET_PROPERTY_SELECT_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== state.selectedForm ? form : {
          ...form,
          [action.payload.property]: action.payload.data
        }
        )
      }
    case 'SET_POSITION_FORM':
      const selectedForm = state.forms.find(form => form.id === action.payload.form)
      if (selectedForm) {

        let x = Number(action.payload.data.x);
        let y = Number(action.payload.data.y);
        return {
          ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
            ...form,
            realx: (x || x === 0) && !isNaN(x) ? Math.ceil(x) : form.realx,
            realy: (y || y === 0) && !isNaN(y) ? Math.ceil(y) : form.realy
          }
          )
        }
      }
      break;
    case 'SET_ABS_POSITION_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          absx: action.payload.data.x ? Math.ceil(action.payload.data.x) : form.absx,
          absy: action.payload.data.y ? Math.ceil(action.payload.data.y) : form.absy
        }
        )
      }
    case 'SET_WIDTH_HEIGHT_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          width: action.payload.data.width ? Math.ceil(action.payload.data.width) : form.width,
          height: action.payload.data.height ? Math.ceil(action.payload.data.height) : form.height
        }
        )
      }
    case 'SET_TEXT_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          text: action.payload.data.text || action.payload.data.text === '' ? action.payload.data.text : form.text,
        }
        )
      }
    case 'SET_RADIUS_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          radius: Math.ceil(Number(action.payload.data.radius))
        }
        )
      }

    case 'SET_ANGLE_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          d: Math.ceil(Number(action.payload.data.d))
        }
        )
      }
    case 'SET_VALUE_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          ...action.payload.data
        }
        )
      }
    case 'SET_ROTATION_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          rotation: Math.ceil(Number(action.payload.data.rotation))
        }
        )
      }
    case 'SET_MODEL_FORM':
      return {
        ...state, forms: state.forms.map(form => form.id !== action.payload.form ? form : {
          ...form,
          model: action.payload.data.model
        }
        )
      }

    case 'DELETE_FORM':
      return { ...state, forms: state.forms.filter(form => form.id !== action.payload.id) }
    case 'RESET_SOFT':
      return {
        ...state,
        formToAdd: null,
        forms: [],
        ratio: 0,
        isDraging: false,
        mainFormX: '',
        mainFormY: '',
        mainForm: { configured: false, width: null, height: null, radius: null },
        price: 0,
        unitPrice: 0,
        nbFolding: 0,
        priceLoading: false,
        length: 0,
        nbChains: 0,
        model: {},
        formToAddDataTemp: {},
        realSurface: 0,
        profil: null,
        profilH: null,
        accessories: []
      }
    case 'RESET':
      return {
        ...state,
        materialId: null,
        shadeId: null,
        thicknessId: null,
        materialImageName: null,
        shadeImageName: null,
        size: null,
        form: 0,
        formToAdd: null,
        forms: [],
        ratio: 0,
        isDraging: false,
        mainFormX: 0,
        mainFormY: 0,
        mainForm: { configured: false, width: null, height: null, },
        price: null,
        unitPrice: 0,
        nbPiece: 1,
        nbFolding: 0,
        priceLoading: false,
        length: 0,
        nbChains: 0,
        model: {},
        formToAddDataTemp: {},
        realWidth: 0,
        realHeight: 0,
        weight: 0,
        isFromCatalog: false,
        shadeColor: null,
        realSurface: 0,
        profil: null,
        profilH: null,
        accessories: []
      }
    default:
      throw new Error();
  }
};


function ConfiguratorProvider(props) {
  const [configurator, dispatch] = useReducer(reducer, initialState);

  return (
    <ConfiguratorContext.Provider value={[configurator, dispatch]}>
      {props.children}
    </ConfiguratorContext.Provider>
  );
}

export { ConfiguratorContext, ConfiguratorProvider }

