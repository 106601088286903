import React, { useContext, useReducer } from 'react';
import { useGet, usePost, useDelete, usePut } from '../modules/request';

const AccessoriesContext = React.createContext([[], () => { }]);

const initialState = {
    selectedAccessory: null,
    accessories: [],
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_ACCESSORIES':
            return { ...state, accessories: action.payload }
        case 'ADD_ACCESSORY':
            return { ...state, accessories: [...state.accessories, { ...action.payload }] }
        case 'EDIT_ACCESSORY':
            return { ...state, accessories: [...state.accessories.map(accessory => accessory._id === action.payload._id ? action.payload : accessory)] }
        case 'DELETE_ACCESSORY':
            return { ...state, accessories: state.accessories.filter(accessory => accessory._id !== action.payload) }
        case 'RESET':
            return initialState;
        default:
            throw new Error();
    }
};


const useGetAccessories = () => {
    const [, dispatch] = useContext(AccessoriesContext);
    const get = useGet()
    return {
        getAccessories: async () => {

            const res = await get('/accessory')
            dispatch({
                type: 'SET_ACCESSORIES',
                payload: res.data.message
            })
        },
    };
}

const usePostAccessory = () => {
    const [, dispatch] = useContext(AccessoriesContext);
    const post = usePost()
    return {
        postAccessory: async (data, file = null, fileConfig = null) => {
            try {

                let formData = new FormData()
                formData.append('file', file)
                let uploadResponse = await post('/upload/image', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                if (uploadResponse.status !== 201 || !uploadResponse.data.success) throw new Error('upload fail')
                let filename = uploadResponse.data.message
                data = { ...data, file: filename }

                const res = await post('/accessory', data)

                dispatch({
                    type: 'ADD_ACCESSORY',
                    payload: res.data.message
                })
                if (res && res.status === 201) {
                    return res.data.message
                } else {
                    return false
                }
            } catch (e) {
                return false
            }
        },
    };
}

const usePutAccessory = () => {
    const [, dispatch] = useContext(AccessoriesContext);
    const put = usePut()
    const post = usePost()
    return {
        putAccessory: async (data, file = null) => {
            try {
                if (file) {

                    let formData = new FormData()
                    formData.append('file', file)
                    let uploadResponse = await post('/upload/image', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    })
                    if (uploadResponse.status !== 201 || !uploadResponse.data.success) throw new Error('upload fail')
                    let filename = uploadResponse.data.message
                    data = { ...data, file: filename }
                }

                const res = await put(`/accessory/${data._id}`, data)

                dispatch({
                    type: 'EDIT_ACCESSORY',
                    payload: res.data.message
                })
                if (res && res.status === 201) {
                    return res.data.message
                } else {
                    return false
                }
            } catch (e) {
                return false
            }
        },
    };
}


const useDeleteAccessory = () => {
    const [, dispatch] = useContext(AccessoriesContext);
    const del = useDelete()
    return {
        deleteAccessory: async (id) => {
            try {
                const res = await del(`/accessory/${id}`)
                if (res.status === 200) {
                    dispatch({
                        type: 'DELETE_ACCESSORY',
                        payload: id,
                    })
                    return true
                } else {
                    return false
                }

            } catch (e) {

            }
        },
    };
}


const AccessoriesProvider = (props) => {
    const [accessories, dispatch] = useReducer(reducer, initialState);



    return (
        <AccessoriesContext.Provider value={[accessories, dispatch]}>
            {props.children}
        </AccessoriesContext.Provider>
    );
}

export { AccessoriesContext, AccessoriesProvider, useGetAccessories, usePostAccessory, useDeleteAccessory, usePutAccessory };