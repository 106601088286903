import { Box, Button, CircularProgress, Grid, makeStyles, MenuItem, Paper, Select, TextField, Typography, useTheme } from '@material-ui/core'
import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react'
import { MaterialsContext } from '../contexts/MaterialsContext'
import { useHistory } from 'react-router'
import { BasketContext } from '../contexts/BasketContext'
import { useIsAuthenticated } from 'react-auth-kit';
import CartLine from './CartLine'
import emptyBox from '../assets/images/empty-box.jpg'
import { useGetBasket } from '../contexts/BasketContext';
import { grey } from '@material-ui/core/colors'
import CartLinePO from './CartLinePO'
import { useGetMe, useGetUsers, UserContext } from '../contexts/UserContext'
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4)

    },
    box: {
        marginTop: theme.spacing(2)
    },
    emptybasket: {
        padding: theme.spacing(4)
    },
    emptyBasketImg: {
        height: '100px',
        margin: '0 auto',
        display: 'block'
    },
    paperTotal: {
        padding: theme.spacing(2),
        marginLeft: 'auto',
        marginTop: theme.spacing(3)
    },
    price: {
        fontWeight: 'bold',
        marginLeft: theme.spacing(2),
        fontSize: '1rem',
        textAlign: 'left'
    },
    divider: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    actionBar: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        marginTop: '6px'
    }
}))


function Cart() {

    const classes = useStyles()
    const [baskets] = useContext(BasketContext)
    const history = useHistory()
    const [loading, setloading] = useState(false);
    const [userOrder, setUserOrder] = useState(null);
    const [materials] = useContext(MaterialsContext)
    const [{ users, me }] = useContext(UserContext);
    const theme = useTheme()

    const isAuth = useIsAuthenticated()
    const { getUsers } = useGetUsers();
    const { getMe } = useGetMe();


    const filtredBaskets = useMemo(() => {
        return baskets.filter(b => b.type !== 'accessory')
    }, [baskets])
    const { getBasket } = useGetBasket();

    const getItems = useCallback(
        () => {
            if (filtredBaskets && filtredBaskets.length > 0 && materials.length > 0) {
                return filtredBaskets.map(item => <Grid xs={12} item>{item.type === 'accessory' ? <CartLinePO key={item._id} item={item} /> : <CartLine key={item._id} item={item} baskets={baskets} />}</Grid>)
            }
        },
        [filtredBaskets, materials.length],
    )

    const handelClickValidateBasket = () => {

        let user = '/cart/validate';
        if (isAuth() && userOrder) {
            user += `?user=${userOrder}`
        }
        history.push(user)
    }

    useEffect(() => {
        getBasket();
        if (isAuth()) {
            getUsers();
            getMe();
        }
    }, []);

    const getUserOptions = () => {
        return users.filter(user => user._id !== me._id).map(user => ({ lastName: user.lastName, firstName: user.firstName, _id: user._id }));
    }

    const handleChangeUserSelect = (e, v) => {
        setUserOrder(v._id)
    }

    return (
        <Grid className={classes.root} container direction='column' justify='center' alignItems='center'>
            <Grid item md={6} xs={11}>
                <Grid container className={classes.paper} direction='column'>

                    <Grid container direction='column' spacing={2} alignItems="center" >
                        <Grid item xs={12} container justify="center">

                            {filtredBaskets.length > 0
                                ?
                                getItems()
                                :
                                <Paper elevation={0} style={{ marginTop: theme.spacing(8) }}>
                                    <Grid className={classes.emptybasket} container direction='column' justify="center">
                                        <Grid item>
                                            <Typography align="center" variant="h6" gutterBottom>
                                                Votre panier est vide
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <img className={classes.emptyBasketImg} src={emptyBox} alt='Panier vide' />
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => history.push('/')} color="primary" variant='contained'>Configurer une piece</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            }
                        </Grid>
                    </Grid>

                    {
                        baskets.length > 0 ?
                            <Paper elevation={0} className={classes.actionBar}>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item xs={6}>
                                        {isAuth() && me.role === 0 && <Autocomplete

                                            options={getUserOptions()}
                                            getOptionLabel={(option) => `${option.lastName} ${option.firstName}`}
                                            style={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Affecter à un utilisateur" variant="outlined" />}
                                            onChange={handleChangeUserSelect}
                                        />}
                                    </Grid>
                                    <Grid item>
                                        <Button color="primary" variant="contained" onClick={handelClickValidateBasket}>Etape suivante {loading ? <CircularProgress size={20} color={grey[50]} /> : null}</Button>
                                    </Grid>


                                </Grid>

                            </Paper>
                            : null
                    }

                </Grid>
            </Grid>
        </Grid>

    )
}

export default Cart
