import React, { useReducer } from 'react';
const MaterialsContext = React.createContext([[], () => {}]);

const initialState = []

const reducer =  (state, action) => {
  switch (action.type) {
    case "SET_MATERIALS":
      return action.payload
    case "ADD_MATERIAL":
      
      return [...state, {...action.payload}]
    case "SET_MATERIAL":
      return state.map(material => material._id === action.payload.idMaterial ? action.payload.material : material)
    case "EDIT_MATERIAL_VALUE":
      return state.map(material => material._id === action.payload.id ? {...material, [action.payload.key]: action.payload.value} : material)
    case "DELETE_MATERIAL":
      return state.filter(material => material._id !== action.payload.id)
    default:
      throw new Error();
  }
};


const MaterialsProvider = (props) => {
  const [materials, dispatch] = useReducer(reducer, initialState);



  return (
    <MaterialsContext.Provider value={[materials, dispatch]}>
      {props.children}
    </MaterialsContext.Provider>
  );
}

export { MaterialsContext, MaterialsProvider };