import React, { useContext, useReducer } from 'react';
import { useGet, usePost, usePut, useDelete } from '../modules/request';

const CatalogContext = React.createContext([[], () => { }]);

const initialState = {
    selectedCatalog: null,
    catalogConfiguration: [],
    catalogs: [],
    price: 0,
    step: 0,
    hasError: false
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_CATALOGS":
            return { ...state, catalogs: action.payload }
        case "ADD_CATALOG":
            return { ...state, catalogs: [...state.catalogs, { ...action.payload }] }
        case "EDIT_CATALOG":
            return { ...state, catalogs: [...state.catalogs.map(catalog => catalog._id === action.payload.id ? action.payload.data : catalog)] }
        case "DELETE_CATALOG":
            return { ...state, catalogs: state.catalogs.filter(catalog => catalog._id !== action.payload) }
        case "SET_SELECTED_CATALOG":
            return { ...state, selectedCatalog: action.payload }
        case "SET_CATALOG_CONFIGURATIONS":
            return { ...state, catalogConfiguration: action.payload }
        case "SET_CATALOG_PRICE":
            return { ...state, price: action.payload }
        case "SET_STEP":
            return { ...state, step: action.payload }
        case "SET_ERROR":
            return { ...state, hasError: action.payload }
        case 'RESET_SOFT':
            return { ...state, selectedCatalog: null, catalogConfiguration: [], price: 0 }
        case 'RESET':
            return { catalogs: [], selectedCatalog: null, catalogConfiguration: [], price: 0, step: 0 }
        default:
            throw new Error();
    }
};


const useGetCatalog = () => {
    const [, dispatch] = useContext(CatalogContext);
    const get = useGet()
    return {
        getCatalog: async () => {

            const res = await get('/catalog')
            dispatch({
                type: 'SET_CATALOGS',
                payload: res.data.message
            })
        },
    };
}

const usePostCatalog = () => {
    const [, dispatch] = useContext(CatalogContext);
    const post = usePost()
    return {
        postCatalog: async (data, file = null, fileConfig = null, excel = null) => {
            try {

                let formData = new FormData()
                formData.append('file', file)
                let uploadResponse = await post('/upload/image', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                if (uploadResponse.status !== 201 || !uploadResponse.data.success) throw new Error('upload fail')
                let filename = uploadResponse.data.message
                data = { ...data, file: filename }

                formData = new FormData()
                formData.append('file', fileConfig)
                uploadResponse = await post('/upload/image', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                if (uploadResponse.status !== 201 || !uploadResponse.data.success) throw new Error('upload fail')
                filename = uploadResponse.data.message
                data = { ...data, fileConfig: filename }

                if (excel) {
                    formData = new FormData()
                    formData.append('file', excel)
                    uploadResponse = await post('/upload/sheetProd', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    })
                    if (uploadResponse.status !== 201 || !uploadResponse.data.success) throw new Error('upload fail')
                    filename = uploadResponse.data.message
                    data = { ...data, excel: filename }
                }




                const res = await post('/catalog', data)
                dispatch({
                    type: 'ADD_CATALOG',
                    payload: res.data.message
                })
                if (res && res.status === 201) {
                    return res.data.message
                } else {
                    return false
                }
            } catch (e) {
                return false
            }
        },
    };
}


const useEditCatalog = () => {
    const [, dispatch] = useContext(CatalogContext);
    const put = usePut()
    const post = usePost()
    return {
        editCatalog: async (data, file, fileConfig, excel) => {
            if (file) {
                const formData = new FormData()
                formData.append('file', file)
                const uploadResponse = await post('/upload/image', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                if (uploadResponse.status !== 201 || !uploadResponse.data.success) throw new Error('upload fail')
                const filename = uploadResponse.data.message
                data = { ...data, file: filename }
            }
            if (fileConfig) {
                const formData = new FormData()
                formData.append('file', fileConfig)
                const uploadResponse = await post('/upload/image', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                if (uploadResponse.status !== 201 || !uploadResponse.data.success) throw new Error('upload fail')
                const filename = uploadResponse.data.message
                data = { ...data, fileConfig: filename }
            }
            if (excel) {
                const formData = new FormData()
                formData.append('file', excel)
                const uploadResponse = await post('/upload/sheetProd', formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                if (uploadResponse.status !== 201 || !uploadResponse.data.success) throw new Error('upload fail')
                const filename = uploadResponse.data.message
                data = { ...data, excel: filename }
            }
            const res = await put(`/catalog/${data._id}`, data)
            if (res.status === 201) {
                dispatch({
                    type: 'EDIT_CATALOG',
                    payload: {
                        id: res.data.message._id,
                        data: res.data.message
                    },
                })
                return true
            } else {
                return false
            }
        },
    };
}

const useDeleteCatalog = () => {
    const [, dispatch] = useContext(CatalogContext);
    const del = useDelete()
    return {
        deleteCatalog: async (id) => {
            try {
                const res = await del(`/catalog/${id}`)
                if (res.status === 200) {
                    dispatch({
                        type: 'DELETE_CATALOG',
                        payload: id,
                    })
                    return true
                } else {
                    return false
                }

            } catch (e) {

            }
        },
    };
}


const CatalogProvider = (props) => {
    const [catalogs, dispatch] = useReducer(reducer, initialState);



    return (
        <CatalogContext.Provider value={[catalogs, dispatch]}>
            {props.children}
        </CatalogContext.Provider>
    );
}

export { CatalogContext, CatalogProvider, useGetCatalog, usePostCatalog, useEditCatalog, useDeleteCatalog };