import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Button, ButtonGroup, FormControl, FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CatalogContext } from '../../../contexts/CatalogContext';
import { PropertiesContext } from '../../../contexts/PropertiesContext';
import CatalogdrawingPannelEntitiesListValue from './CatalogdrawingPannelEntitiesListValue';
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import AutoSelectTextField from '../../Input/AutoSelectTextField';

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        '& td': {
            padding: theme.spacing(1)
        },
        '& th': {
            padding: theme.spacing(1)
        }
    },
    fab: {
        marginRight: theme.spacing(2),
        textTransform: 'none',
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
        '& td': {
            border: `1px solid ${theme.palette.divider}`
        },
        '& input': {
            textAlign: 'center'
        }

    },
    tableHead: {
        '& th': {
            textAlign: 'center',
            border: `1px solid ${theme.palette.divider}`,
            fontWeight: 'bold'
        },
        '& td': {
        },
    },
    tableHeadRowEntities: {
        '& th': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
    }
}));

function PaintDirectionRadio({ value, onChange }) {
    return (
        <FormControl component="fieldset">
            <RadioGroup
                row
                value={value}
                onChange={(e) => onChange(e.target.value)}
            >
                <FormControlLabel value="inside" control={<Radio />} label="Intérieur" />
                <FormControlLabel value="outside" control={<Radio />} label="Extérieur" />
            </RadioGroup>
        </FormControl>
    );
}

function TableHeader({ catalog }) {

    const classes = useStyles();


    if (!catalog) return null;

    const nbOfLines = catalog.entities.reduce((a, c) => {
        if (c.type === 'line' || c.type === 'longer') {
            return a + 1
        }
        return a
    }, 0)

    const nbOfAngle = catalog.entities.reduce((a, c) => {
        if (c.type === 'angle') {
            return a + 1
        }
        return a
    }, 0)
    const cells = [
        <TableCell>Action</TableCell>,
        ...catalog.entities.map(entity => <TableCell key={entity._id}>{entity.name}</TableCell>),
        <TableCell>REF client</TableCell>,
        <TableCell>Quantité</TableCell>,
        <TableCell>Sens peinture</TableCell>,
    ];

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                <TableCell colSpan={1}></TableCell>
                <TableCell colSpan={nbOfLines}>Cotes (mm)</TableCell>
                <TableCell colSpan={nbOfAngle}>Angle (°)</TableCell>
                <TableCell colSpan={3}></TableCell>
            </TableRow>
            <TableRow className={classes.tableHeadRowEntities}>{cells}</TableRow>
        </TableHead>
    );
}

function TableRowContent({ config, index, catalog, handleDeleteRow, handleAddRow, handelSetData, handleChangeQuantity, handlePaintDirectionChange, handleRefClientChange }) {

    const [{ catalogConfiguration }] = useContext(CatalogContext);

    const classes = useStyles();

    const getData = (entity) => {
        return config.configuration.find(data => data.entityId === entity._id);
    };

    if (!catalog) return null;

    const cells = [
        <TableCell key="action-cell">
            <ButtonGroup>
                {catalogConfiguration.length === index + 1 && <Button onClick={handleAddRow}>+</Button>}
                {catalogConfiguration.length > 1 && <Button onClick={() => handleDeleteRow(config.id)}>-</Button>}
            </ButtonGroup>
        </TableCell>,
        ...catalog.entities.map(entity => (
            <CatalogdrawingPannelEntitiesListValue
                key={entity._id}
                setData={(newData) => handelSetData(config, newData)}
                data={getData(entity)}
                entity={entity}
            />
        )),
        <TableCell key="ref-client-cell">
            <AutoSelectTextField
                value={config.refClient || ''}
                onChange={(e) => handleRefClientChange(config, e.target.value)}
            />
        </TableCell>,
        <TableCell key="quantity-cell">
            <AutoSelectTextField value={config.quantity} onChange={(e) => handleChangeQuantity(config, e.target.value)} />
        </TableCell>,
        <TableCell key="paint-direction-cell">
            <PaintDirectionRadio
                value={config.paintDirection || 'intérieur'}
                onChange={(direction) => handlePaintDirectionChange(config, direction)}
            />
        </TableCell>,
    ];

    return <TableRow className={classes.row}>{cells}</TableRow>;
}

function CatalogdrawingPannelEntitiesList({ catalog, defaultRowData }) {
    const classes = useStyles();
    const [{ catalogConfiguration }, dispatchCatalog] = useContext(CatalogContext);


    const handleAddRow = () => {
        dispatchCatalog({
            type: 'SET_CATALOG_CONFIGURATIONS',
            payload: [...catalogConfiguration, defaultRowData()]
        });
    };

    const handleDeleteRow = (id) => {
        const updatedConfiguration = catalogConfiguration.filter(config => config.id !== id);
        dispatchCatalog({
            type: 'SET_CATALOG_CONFIGURATIONS',
            payload: updatedConfiguration
        });
    };

    const handelSetData = useCallback(
        (c, newData) => {
            const updatedConfiguration = catalogConfiguration.map(config =>
                config.id === c.id
                    ? { ...config, configuration: config.configuration.map(data => data.entityId === newData.entityId ? newData : data) }
                    : config
            );
            dispatchCatalog({
                type: 'SET_CATALOG_CONFIGURATIONS',
                payload: updatedConfiguration
            });
        },
        [catalogConfiguration, dispatchCatalog]
    );

    const handleChangeQuantity = (c, quantity) => {
        const updatedConfiguration = catalogConfiguration.map(config => config.id === c.id ? { ...config, quantity } : config);
        dispatchCatalog({
            type: 'SET_CATALOG_CONFIGURATIONS',
            payload: updatedConfiguration
        });
    };

    const handlePaintDirectionChange = (config, direction) => {
        const updatedConfiguration = catalogConfiguration.map(conf =>
            conf.id === config.id
                ? { ...conf, paintDirection: direction }
                : conf
        );
        dispatchCatalog({
            type: 'SET_CATALOG_CONFIGURATIONS',
            payload: updatedConfiguration
        });
    };

    const handleRefClientChange = (config, refClient) => {
        const updatedConfiguration = catalogConfiguration.map(conf =>
            conf.id === config.id
                ? { ...conf, refClient: refClient }
                : conf
        );
        dispatchCatalog({
            type: 'SET_CATALOG_CONFIGURATIONS',
            payload: updatedConfiguration
        });
    };

    return (
        <Table className={classes.list} size="small">
            <TableHeader catalog={catalog} />
            <TableBody>
                {catalog && catalogConfiguration.map((config, index) => (
                    <TableRowContent
                        key={config.id}
                        index={index}
                        config={config}
                        catalog={catalog}
                        handleDeleteRow={handleDeleteRow}
                        handleAddRow={handleAddRow}
                        handelSetData={handelSetData}
                        handleChangeQuantity={handleChangeQuantity}
                        handlePaintDirectionChange={handlePaintDirectionChange}
                        handleRefClientChange={handleRefClientChange}
                    />
                ))}
            </TableBody>
        </Table>
    );
}

export default CatalogdrawingPannelEntitiesList;
