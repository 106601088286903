import React, { useCallback, useContext, useRef, useState, useEffect } from 'react'
import { ConfiguratorContext } from '../contexts/ConfiguratorContext'
import { createLayer, dxftoPolylines, dxftoSVG, getlayers, replaceLayer_ } from '../modules/dxf'
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./../../src/modules/worker";
import DialogShowDXFDropResult from './Dialog/DialogShowDXFDropResult';
import { useIsAuthenticated } from 'react-auth-kit';
import { useHistory } from 'react-router-dom';
import { useGet, usePost } from '../modules/request';
import { useAddItemBasket } from '../contexts/BasketContext';
import DialogShowDXFLayers from './Dialog/DialogShowDXFLayers';


function DropDXF({ setActiveStep, onClick, startDXF, setstartDXF, setdxfLoading }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const hiddenInputRef = useRef(null)
    const [price, setprice] = useState(null);
    const [svg, setsvg] = useState(null);
    const [open, setopen] = useState(false);
    const [length, setlength] = useState(0);
    const [nbChains, setnbChains] = useState(0);
    const [maxWidth, setmaxWidth] = useState(0);
    const [maxHeight, setmaxHeight] = useState(0);
    const [weight, setweight] = useState(0);
    const [dxf, setdxf] = useState('');
    const [file, setfile] = useState(null)
    const [fileSvg, setfileSvg] = useState(null)
    const [files, setfiles] = useState([]);
    const [currentFile, setcurrentFile] = useState(0);
    const [postBasketLoading, setpostBasketLoading] = useState(false)
    const [collision, setcollision] = useState(false);
    const [layers, setlayers] = useState([]);
    const [openDialogShowDXFLayers, setopenDialogShowDXFLayers] = useState(false);
    const [selectedLayers, setselectedLayers] = useState([]);
    const [gravedLayers, setgravedLayers] = useState([]);
    const [loading, setloading] = useState(false);
    const isAuth = useIsAuthenticated()
    const history = useHistory()
    const post = usePost()
    const get = useGet()
    const { addItemBasket } = useAddItemBasket()


    const reset = useCallback(() => {
        dispatch({
            type: 'RESET'
        });
        setActiveStep(0);
        setprice(null);
        setlength(0);
        setopen(false);
        setnbChains(0);
        setmaxHeight(0);
        setmaxWidth(0);
        setdxf('');
        setfile(null);
        setcollision(false);
        setcurrentFile(0);
        setstartDXF(false);
        setgravedLayers([]);
        setselectedLayers([]);
        setfiles([])
    }, [dispatch, setActiveStep, setstartDXF])

    const handleClose = useCallback(
        () => {
            window.parent.postMessage({ type: 'cancel-dxf-redirect' }, "*");
            reset();
            setopen(false)
        },
        [reset],
    )

    useEffect(() => {
        if (startDXF) {

            hiddenInputRef.current.click()
            setstartDXF(false)

        }
    }, [history, isAuth, setstartDXF, startDXF])



    const handelClickNext = useCallback(
        () => {
            setcurrentFile(currentFile + 1)
            setprice(null)
            setlength(0)
            setopen(false)
            setnbChains(0)
            setmaxHeight(0)
            setmaxWidth(0)
            setdxf('')
            setfile(null)
            setcollision(false)
            setgravedLayers([]);
            setselectedLayers([]);
        },
        [currentFile],
    )

    useEffect(() => {
        (async () => {
            if (currentFile >= files.length && files.length !== 0) {
                reset();
                window.parent.postMessage({ type: 'redirect' }, "*")
            } else {
                //setdxfLoading(true)
                try {
                    var rawDXF = await files[currentFile].text();
                    const layers = getlayers(rawDXF);
                    if (layers.length > 1) {
                        setdxf(rawDXF)
                        setlayers(layers);
                        setopenDialogShowDXFLayers(true)
                    } else {
                        handleClickValidateDxf(rawDXF)
                    }


                } catch (e) {
                    console.error('error dxf', e.message)
                }
                //setdxfLoading(false)
            }

        })()

    }, [files, currentFile]);

    const handleClickValidateDxf = useCallback((dxf) => {
        (async () => {
            setloading(true)
            setdxfLoading(true)
            const layers = getlayers(dxf);
            if (layers && layers.length) {

                const layerToSetAt0 = getlayers(dxf).filter(layer => !selectedLayers.includes(layer) && !gravedLayers.includes(layer) && layer !== '0');
                dxf = replaceLayer_(dxf, layerToSetAt0, '0')
                if (gravedLayers && gravedLayers.length) {

                    dxf = createLayer(dxf);
                    dxf = replaceLayer_(dxf, gravedLayers, 'gravure');
                }
            }
            const worker = new Worker()
            worker.postMessage({ type: 'DXF_CHECK', dxf })
            worker.onmessage = async (event) => {
                const { length, nbChains, maxWidth, maxHeight, collision } = event.data;
                if (!collision) {
                    setlength(length)
                    setnbChains(nbChains)
                    setmaxWidth(maxWidth)
                    setmaxHeight(maxHeight)
                    let url = `/calculate?length=${length}&materialId=${configurator.materialId}&shadeId=${configurator.shadeId}&thicknessId=${configurator.thicknessId}&nbChains=${nbChains}&nbFolding=0&maxWidth=${maxWidth}&maxHeight=${maxHeight}&quantity=${configurator.nbPiece}`;
                    if (window.sessionId) {
                        url += '&sessionId=' + window.sessionId;
                    }
                    const result = await get(url);
                    setprice(result.data.message.price)
                    setweight(result.data.message.weight)
                } else {
                    setcollision(true)
                }
                const svg = dxftoSVG(dxf);
                setsvg(svg);
                worker.postMessage({ type: 'DXF_IMPORT', dxfStr: dxf })
                worker.onmessage = async (event) => {
                    let { svg } = event.data;
                    svg = svg.replace("fill:none", "fill:#7b7b7b");
                    svg = svg.replace("stroke-width:0.25mm", "stroke-width:0mm");
                    svg = svg.replace("nonzero", "evenodd");
                    setsvg(svg);
                    setopenDialogShowDXFLayers(false)
                    setopen(true)
                    setdxf(dxf)
                    const dt = new Date();
                    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
                    const fileName = `P${padL(+dt.getHours())}${padL(dt.getMinutes())}${padL(dt.getSeconds())}-${dt.getFullYear()}${padL(dt.getMonth() + 1)}${padL(dt.getDate())}-${Math.floor(Math.random() * (1000 - 1)) + 1}`;

                    const parts = [
                        new Blob([dxf], { type: 'application/dxf' }),
                    ];
                    const newFile = new File(parts, fileName + '.dxf', {});


                    setfileSvg(svg);
                    setdxfLoading(false)
                    setloading(false);
                    setfile(newFile)
                }

            }

        })()
    }, [configurator.materialId, configurator.nbPiece, configurator.shadeId, configurator.thicknessId, get, gravedLayers, selectedLayers])

    const handelSelectFile = (e) => {
        setfiles(e.target.files)
    }


    const handelAddToBasket = useCallback(
        () => {

            async function convertSvgToPngAndPrepareFormData(svgString, fileName) {
                // Créer un élément "dummy" dans le DOM pour dessiner le SVG
                const svgContainer = document.createElement("div");
                svgContainer.innerHTML = svgString;
                const svgElement = svgContainer.querySelector("svg");

                // Créer un canevas HTML5 pour dessiner l'image
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                canvas.width = svgElement.width.baseVal.value;
                canvas.height = svgElement.height.baseVal.value;

                // Dessiner le SVG sur le canevas
                const svgUrl = `data:image/svg+xml;base64,${btoa(svgString)}`;
                const svgImage = new Image();
                svgImage.src = svgUrl;

                await new Promise((resolve, reject) => {
                    svgImage.onload = function () {
                        context.drawImage(svgImage, 0, 0);
                        resolve();
                    };
                    svgImage.onerror = reject;
                });

                // Convertir le canevas en une image PNG
                const pngUrl = canvas.toDataURL("image/png");

                // Créer un objet FormData pour envoyer l'image via Axios
                const formData = new FormData();
                const blob = await dataURItoBlob(pngUrl);
                formData.append("file", blob, fileName);

                return formData;

                function dataURItoBlob(dataURI) {
                    const byteString = atob(dataURI.split(",")[1]);
                    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
                    const arrayBuffer = new ArrayBuffer(byteString.length);
                    const intArray = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < byteString.length; i++) {
                        intArray[i] = byteString.charCodeAt(i);
                    }

                    const blob = new Blob([intArray], { type: mimeString });
                    return blob;
                }
            }


            (async () => {
                setpostBasketLoading(true)
                try {
                    let formData = new FormData()
                    formData.append('file', file)
                    let uploadResponse = await post('/upload/dxf', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    })
                    const filename = uploadResponse.data.message

                    //formData = new FormData()
                    //formData.append('file', fileSvg)
                    formData = await convertSvgToPngAndPrepareFormData(fileSvg, filename + '.png');
                    await post('/upload/image', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    })

                    await addItemBasket({
                        dxf: filename,
                        length,
                        nbChains,
                        materialId: configurator.materialId,
                        shadeId: configurator.shadeId,
                        thicknessId: configurator.thicknessId,
                        maxWidth,
                        maxHeight,
                        weight,
                        quantity: configurator.nbPiece,
                        nbFolding: 0
                    })

                    setopen(false)

                } catch (e) {
                    console.error('error')
                }
                setpostBasketLoading(false)
                if (currentFile <= files.length) {
                    setcurrentFile(currentFile + 1)
                }
            })()
        },
        [addItemBasket, weight, configurator.materialId, configurator.nbPiece, configurator.shadeId, configurator.thicknessId, currentFile, file, files.length, length, maxHeight, maxWidth, nbChains, post],
    )


    const handleCloseDialogShowDXFLayers = () => {
        setopenDialogShowDXFLayers(false);
        reset();

    }
    return (
        <>
            {!open && <input ref={hiddenInputRef} onChange={handelSelectFile} id='fileid' type='file' multiple hidden />}
            {open && <DialogShowDXFDropResult lastFile={currentFile === files.length - 1} handelClickNext={handelClickNext} qte={configurator.nbPiece} postBasketLoading={postBasketLoading} collision={collision} price={price} svg={svg} open={open} handleClose={handleClose} handelAddToBasket={handelAddToBasket} />}
            {openDialogShowDXFLayers && <DialogShowDXFLayers loading={loading} handleClose={handleCloseDialogShowDXFLayers} handleClickValidateDxf={handleClickValidateDxf} open={openDialogShowDXFLayers} dxf={dxf} layers={layers} selectedLayers={selectedLayers} setselectedLayers={setselectedLayers} gravedLayers={gravedLayers} setgravedLayers={setgravedLayers} />}
        </>
    )
}

export default DropDXF
