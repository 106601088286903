import React, { useCallback, useState } from 'react'
import { TableCell, Button, Grid } from '@material-ui/core'
import DialogSelectColor from './Dialog/DialogSelectColor'
import { makeStyles } from '@material-ui/styles'


const useStyle = makeStyles((theme) => ({
    colorPreview: {
        height: '25px',
        width: '25px',
        marginLeft: theme.spacing(1),
        backgroundColor: props => props.backgroundColor
    }
}))

function TableCellColorSelect({ onCickEdited, align, className, value }) {

    const classes = useStyle({ backgroundColor: value })

    const handleSelectColor = useCallback((color) => {
        onCickEdited(color)
        setopen(false)
    }, [onCickEdited])

    const [open, setopen] = useState(false);

    return (
        <TableCell align={align || "center"} className={className} scope="row">
            <Grid container alignItems='center' justify='center'>
                <Grid item>
                    <Button variant='contained' onClick={() => setopen(true)}>Choisir</Button>
                </Grid>
                <Grid item>
                    <div className={classes.colorPreview} onClick={() => setopen(true)}></div>
                </Grid>
                {open && <DialogSelectColor open={open} setOpen={setopen} onSelect={handleSelectColor} value={value} />}
            </Grid>
        </TableCell>
    )
}

export default TableCellColorSelect