import { Button, Checkbox, CircularProgress, Grid, IconButton, InputLabel, Menu, MenuItem, Paper, Typography, makeStyles } from '@material-ui/core'
import { DataGrid, GridFilterToolbarButton, getGridDateOperators, getGridStringOperators } from '@material-ui/data-grid'
import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useMemo } from 'react'
import CheckIcon from '@material-ui/icons/Check';
import DatePicker from "react-datepicker";

import { EstimateContext, useGetEstimates, useUpdateEstimate } from '../../contexts/EstimateContext'
import { displayFormatedPrice, convertDate } from '../../modules/utils'
import { useState } from 'react'
import DialogShowClientInformation from '../Dialog/DialogShowClientInformation'
import DialogShowBasketsDetails from '../Dialog/DialogShowBasketsDetails'
import { usePost } from '../../modules/request'
import { SnackbarContext } from '../../contexts/SnackbarContext'

const useStyle = makeStyles((theme) => ({

}))

const stringColumnType = {
    extendType: 'string',
    filterOperators: getGridStringOperators()
        .filter((operator) => operator.value === 'contains')
        .map((operator) => {
            return {
                ...operator,

            };
        }),
};


function EstimatesControl({
    mode = 'admin'
}) {

    const [estimates, dispatch] = useContext(EstimateContext);
    const [EstimateToShow, setEstimateToShow] = useState(null);
    const [showModalFormsInfos, setshowModalFormsInfos] = useState(false);
    const [clientToShow, setclientToShow] = useState(null);
    const [showModalClientInfos, setshowModalClientInfos] = useState(false);
    const [isGenerate, setIsGenerate] = useState(false);
    const [isTransfert, setIsTransfert] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedPdfInvoices, setSelectedPdfInvoices] = useState([]);
    const [selectedtoOrderInvoices, setSelectedtoOrderInvoices] = useState([]);
    const [filterModel, setFilterModel] = useState([]);
    const [page, setPage] = useState(0);
    const [, dispatchSnackbar] = useContext(SnackbarContext)

    const { getEstimates } = useGetEstimates();
    const { updateEstimate } = useUpdateEstimate();
    const post = usePost();

    useEffect(() => {
        getEstimates({ filterModel, page: page + 1, pageSize: 25 })
    }, [page, filterModel]);

    const handelClickShowInfoForms = estimate => {
        setEstimateToShow(estimate);
        setshowModalFormsInfos(true);
    };

    const hanclickShowInfoClient = client => {
        setclientToShow(client);
        setshowModalClientInfos(true);
    };

    const handleCloseInfoClient = () => setshowModalClientInfos(false);
    const handleCloseInfoForms = () => {
        setshowModalFormsInfos(false);
        setEstimateToShow(null);
    }


    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickGenerate = async () => {
        try {
            setIsGenerate(true);


            for (const estimate of selectedPdfInvoices) {

                const response = await post('/calculate/estimate', { estimateId: estimate.id }, {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `devis-${estimate.ref}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }

            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Edition effectué',
                    type: 'success',
                    duration: 4000
                }
            });
        } catch (e) {
            console.log(e);
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Erreur lors de l\'édition',
                    type: 'error',
                    duration: 4000
                }
            });
        } finally {
            setIsGenerate(false);
            setSelectedPdfInvoices([])
            handleCloseMenu()
        }
    };

    const handleTransfer = async () => {
        try {
            setIsTransfert(true);

            for (const estimate of selectedtoOrderInvoices) {
                await post(`/estimate/${estimate.id}/order`);

                dispatch({
                    type: 'REMOVE_ESTIMATE',
                    payload: {
                        id: estimate.id
                    }
                })
            }

            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Passage en commande effectué',
                    type: 'success',
                    duration: 4000
                }
            });
        } catch (e) {
            console.log(e);
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Erreur lors de l\'édition',
                    type: 'error',
                    duration: 4000
                }
            });
        } finally {
            setIsTransfert(false);
            setSelectedtoOrderInvoices([])
            handleCloseMenu()
        }
    }


    const handleCheckPdfSelect = (event, invoice) => {
        if (event.target.checked) {
            setSelectedPdfInvoices([...selectedPdfInvoices, invoice])
        } else {
            setSelectedPdfInvoices([...selectedPdfInvoices.filter(o => o.id !== invoice.id)])
        }
    }

    const handleChecktoOrderSelect = (event, invoice) => {
        if (event.target.checked) {
            setSelectedtoOrderInvoices([...selectedtoOrderInvoices, invoice])
        } else {
            setSelectedtoOrderInvoices([...selectedtoOrderInvoices.filter(o => o.id !== invoice.id)])
        }
    }


    const renderpdfHeaderName = () => {
        return <>
            {isGenerate ? <CircularProgress size={15} /> : selectedPdfInvoices?.length ? <div>
                <IconButton onClick={handleClickGenerate} color="secondary" variant="contained" size="small">
                    <CheckIcon color='primary' />
                </IconButton>
            </div> : 'PDF'}
        </>
    }

    const renderToOrderHeaderName = () => {
        return <>
            {selectedtoOrderInvoices?.length ? <div>
                <IconButton onClick={handleTransfer} color="secondary" variant="contained" size="small">
                    <CheckIcon color='primary' />
                </IconButton>
            </div> : 'Transfert commande'}
        </>
    }

    const handleFilterModelChange = (params) => {
        const filters = params.filterModel.items.map((item) => {
            return {
                columnField: item.columnField,
                operatorValue: item.operatorValue,
                value: item.value,
            };
        });

        setFilterModel(filters);
    };

    function EstimateDatePicker({ item, applyValue }) {
        return <>
            <InputLabel shrink >Date</InputLabel>
            <div style={{ marginTop: '16px' }}>
                <DatePicker selected={item.value} onChange={(date) => applyValue({ ...item, value: date })} />
            </div>


        </>
    }

    const handleEditCellChangeCommitted = ({ id, field, props }) => updateEstimate(id, { [field]: props.value })

    const columnsdef = [
        {
            field: "toOrder",
            headerName: renderToOrderHeaderName(),
            sortable: false,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: params => <Checkbox
                onChange={(event) => handleChecktoOrderSelect(event, params.row)}
                checked={selectedtoOrderInvoices.some(order => order.id === params?.row?.id)}
                color="primary"
            />
        },
        {
            field: "pdf",
            headerName: renderpdfHeaderName(),
            sortable: false,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: params => <Checkbox
                onChange={(event) => handleCheckPdfSelect(event, params.row)}
                checked={selectedPdfInvoices.some(order => order.id === params?.row?.id)}
                color="primary"
            />

        },
        { field: 'ref', headerName: 'REF', disableColumnMenu: true, width: 140, valueGetter: ({ row }) => row.clientRef || row.ref },
        {
            field: "date",
            headerName: "Date",
            type: 'dateTime',
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    convertDate(params.row.date)
                );
            },
        },
        {
            field: "content",
            headerName: "Contenu",
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            menu: false,
            disableClickEventBubbling: true,
            filterable: false,

            renderCell: (params) => {
                return (
                    <Grid container alignItems='center' justify='space-around'>
                        <Grid item style={{ cursor: "pointer" }} onClick={() => handelClickShowInfoForms(params.row)}>
                            {`${params.row.content.length} modèle(s)`}
                        </Grid>

                    </Grid>
                );
            }
        },
        {
            field: "user.discount",
            headerName: "Remise (%)",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            type: 'number',
            editable: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    params?.row?.client?.discount || 0
                );
            },

        },
        {
            field: "client",
            headerName: "Client",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Grid container alignItems='center' justify='space-around'>
                        <Grid item style={{ cursor: "pointer" }} onClick={() => hanclickShowInfoClient(params.row.client)}>
                            {`${params.row.client.lastName} ${params.row.client.firstName}`}
                        </Grid>
                    </Grid>
                );
            }
        },
        {
            field: "amount",
            headerName: "Prix HT commande",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>{`${params.row.amount} €`}</div>
                );
            },

        },
        {
            field: "deliveryPrice",
            headerName: "Prix HT livraison",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            type: 'number',
            editable: true,
            renderCell: (params) => {
                return (
                    <div>{`${params.row.deliveryPrice} €`}</div>
                );
            },

        },
        {
            field: "totalPriceWithVat",
            headerName: "Prix total TTC",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>{`${params.row.totalPriceWithVat} €`}</div>
                );
            },

        },

        {
            field: "deliveryMethode",
            headerName: "Livraison / retrait",
            sortable: false,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Typography variant="body1" gutterBottom align='center'>
                        {params.row.deliveryMethode === 'pickup' ? 'retrait' : 'livraison'}
                    </Typography>
                );
            },
        }
    ];

    const rows = useMemo(
        () => {
            return estimates.map(estimate =>
            ({
                id: estimate._id,
                ref: estimate.ref,
                date: estimate.estimateDate,
                content: estimate.baskets,
                client: estimate.user,
                amount: displayFormatedPrice(estimate.amount),
                deliveryPrice: displayFormatedPrice(estimate.deliveryPrice) || null,
                totalPriceWithVat: displayFormatedPrice(estimate.total),
                deliveryMethode: estimate.deliveryMethode,
                clientRef: estimate.clientRef
            })
            )
        },
        [estimates],
    );

    const columns = () => {
        if (columnsdef.length > 0) {

            const estimateDateColumn = columnsdef.find((column) => column.field === 'date');
            const estimateDateColIndex = columnsdef.findIndex((col) => col.field === 'date');

            const estimateDateFilterOperators = getGridDateOperators().map(
                (operator) => ({
                    ...operator,
                    InputComponent: EstimateDatePicker,
                }),
            );

            const mappedColumns = columnsdef.map((dataColumn) => {
                const mappedColumn = {
                    ...dataColumn,
                };

                if (mappedColumn.field === 'ref' || mappedColumn.field === 'client') {
                    mappedColumn.type = 'str';
                }
                return mappedColumn;
            });

            mappedColumns[estimateDateColIndex] = {
                ...estimateDateColumn,
                filterOperators: estimateDateFilterOperators,
            };
            return mappedColumns;
        }
        return [];
    };
    return (
        <>
            <Grid container justify="center" >
                <Grid item xs={12} sm={11}>
                    <Paper elevation={0} style={{ paddingTop: '1rem' }}>
                        <Typography align="center" variant="h5">Devis</Typography>
                        <div style={{ height: '80vh', width: '100%' }}>
                            <DataGrid
                                pageSize={25}
                                pagination
                                filterMode="server"
                                onFilterModelChange={handleFilterModelChange}
                                onEditCellChangeCommitted={handleEditCellChangeCommitted}
                                rows={rows}
                                columns={columns()}
                                localeText={{
                                    toolbarFilters: 'Filtrer',
                                    filterOperatorContains: 'contient',
                                    filterOperatorIs: 'égale',
                                    filterOperatorNot: 'différent',
                                    filterOperatorAfter: '>',
                                    filterOperatorOnOrAfter: '>=',
                                    filterOperatorBefore: '<',
                                    filterOperatorOnOrBefore: '<='

                                }}
                                components={{
                                    Toolbar: GridFilterToolbarButton,
                                }}

                                columnTypes={{ str: stringColumnType }}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <DialogShowClientInformation open={showModalClientInfos} handleClose={handleCloseInfoClient} client={clientToShow} />
            {EstimateToShow && <DialogShowBasketsDetails open={showModalFormsInfos} handleClose={handleCloseInfoForms} estimate={EstimateToShow} />}

        </>
    )
}

export default EstimatesControl
