import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, makeStyles, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { CatalogSvgDatasSchemas } from '../../modules/validation';

import TextFieldValidation from '../TextFieldValidation';

import { useDeleteAccessory, usePostAccessory, usePutAccessory } from '../../contexts/AccessoriesContext';
import DisplayAccessories from '../EndUser/Accessory/DisplayAccessories';
import { useContext } from 'react';
import { MaterialsContext } from '../../contexts/MaterialsContext';


const useStyles = makeStyles((theme) => ({
    section: {
        marginTop: theme.spacing(4)
    }
}));


function AccessoryControl() {

    const [materials] = useContext(MaterialsContext)

    const [newAccessoryData, setNewAccessoryData] = useState({ name: '', ref: '', file: '', price: {} });
    const [selectedFileName, setSelectedFileName] = useState('');
    const [formError, setformError] = useState(true);
    const [isEdit, setIsEdit] = useState(null);
    const [open, setOpen] = useState(false);

    const hiddenInputRef = useRef(null)

    const { postAccessory } = usePostAccessory()
    const { deleteAccessory } = useDeleteAccessory()
    const { putAccessory } = usePutAccessory()

    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNewAccessoryData({ name: '', file: '', ref: '', price: {} });
        setSelectedFileName('');
    };

    const handelSelectImage = (e) => {
        setNewAccessoryData({ ...newAccessoryData, file: e.target.files[0] })
        setSelectedFileName(e.target.files[0]?.name || '');
    }

    const handleChangeName = (e) => setNewAccessoryData({ ...newAccessoryData, name: e.target.value })

    const handleChangePrice = (e) => setNewAccessoryData({ ...newAccessoryData, price: { ...newAccessoryData.price, [`${e.target.name}`]: +e.target.value } });
    const handleChangeRef = (e) => setNewAccessoryData({ ...newAccessoryData, ref: e.target.value })

    const handleClickSelectImage = () => {

        hiddenInputRef.current.click()

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { file, ...rest } = newAccessoryData;
        if (isEdit) {
            await putAccessory(rest, file)
            setIsEdit(false)
        } else {
            await postAccessory(rest, file);
        }
        setNewAccessoryData({ name: '', file: '', ref: '', price: {} });
        setSelectedFileName('');
        handleClose();

    }

    const handleEditAccessory = (accessory) => {
        setIsEdit(true);
        setSelectedFileName(accessory.file);
        let a = { ...accessory };
        if (typeof accessory.price !== 'object') {
            a.price = {};
            const thicknesses = [];
            materials.forEach(material => material.shades.forEach(shade => shade.thicknesses.forEach(thickness => thicknesses.push(thickness.value))));
            [...new Set(thicknesses)].forEach(item => a.price[item] = accessory.price)

        }
        setNewAccessoryData({ ...a, file: null })
        handleOpen();
    }


    const handleValidateDelete = async (id) => {
        await deleteAccessory(id);
    }

    const getRows = () => {
        const thicknesses = [];
        materials.forEach(material => material.shades.forEach(shade => shade.thicknesses.forEach(thickness => thicknesses.push(thickness.value))));
        return [...new Set(thicknesses)].map(item =>
            <TableRow key={item}>
                <TableCell align="center" component="th" scope="row">
                    {item}
                </TableCell>
                <TableCell align="center"><TextField type="number" name={item} onChange={handleChangePrice} value={newAccessoryData.price[item]} /></TableCell>
            </TableRow>)
    }


    useEffect(() => {
        if (newAccessoryData.name.length > 2 && newAccessoryData.ref && ((!isEdit && newAccessoryData.file) || (isEdit))) {
            setformError(false)
        } else {
            setformError(true)
        }
    }, [newAccessoryData]);

    return (
        <>
            <Grid container justify="center"  >
                <Grid item xs={12} sm={11}>
                    <Paper elevation={0} >
                        <Grid container justify='center'>
                            <Grid item xs={10} className={classes.section}>
                                <Button color='primary' variant='contained' onClick={handleOpen}>
                                    Ajouter un accessoire
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={classes.section}>
                                <Typography variant="h5" align='center' color='primary' gutterBottom>
                                    Accessoires disponibles
                                </Typography>
                                <DisplayAccessories handleEditAccessory={handleEditAccessory} handleValidateDelete={handleValidateDelete} delectable />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {open && <Dialog maxWidth={'lg'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Typography style={{ marginBottom: '3rem' }} variant="h5" align='center' color='primary' gutterBottom>
                            {!isEdit ? 'Ajouter un accessoir' : `Editer ${newAccessoryData.name}`}
                        </Typography>
                        <Grid container justify='center' alignItems='center' spacing={2}>
                            <Grid item xs={12} sm={3} container justify="center" >
                                <Button onClick={handleClickSelectImage} color='primary' variant='contained'>Choisir une image</Button>
                                {selectedFileName && <div style={{ marginLeft: '10px' }}>{selectedFileName}</div>}
                            </Grid>
                            <Grid item sx={12} sm={3} >
                                <TextFieldValidation label="Référence" value={newAccessoryData.ref} onChange={handleChangeRef} fullWidth yupValidation={CatalogSvgDatasSchemas} />
                            </Grid>
                            <Grid item xs={12} sm={3} >
                                <TextFieldValidation label="Nom" value={newAccessoryData.name} onChange={handleChangeName} fullWidth yupValidation={CatalogSvgDatasSchemas} />
                            </Grid>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Epaisseur</TableCell>
                                        <TableCell align="center">PU HT</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        getRows()
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} color="primary">
                        Fermer
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} color="primary" disabled={formError}>
                        {isEdit ? 'Editer' : 'Ajouter'}
                    </Button>
                </DialogActions>
            </Dialog>}

            <input ref={hiddenInputRef} onChange={handelSelectImage} id='imageUpload' type='file' hidden />
        </>

    )
}

export default AccessoryControl