import { Grid, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import ChipTitle from '../../display/ChipTitle'
import CatalogCard from './CatalogCard'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'
import { MaterialsContext } from '../../../contexts/MaterialsContext'
import { CatalogContext } from '../../../contexts/CatalogContext'


function DisplayCatalog() {

    const [catalogsToDisplay, setcatalogsToDisplay] = useState([]);
    const [configurator] = useContext(ConfiguratorContext);
    const [{ catalogs }] = useContext(CatalogContext);
    const [materials] = useContext(MaterialsContext)

    const haveCompletedConfiguration = () => configurator.materialId && configurator.shadeId && configurator.thicknessId && configurator.nbPiece && configurator.size

    useEffect(() => {
        if (haveCompletedConfiguration()) {

            const selectedThickness = materials.find(material => material._id === configurator.materialId)?.shades.find(shade => shade._id === configurator.shadeId)?.thicknesses.find(thickness => thickness._id === configurator.thicknessId)
            if (selectedThickness) {
                setcatalogsToDisplay(
                    catalogs
                        .filter(catalog => catalog.thickness.includes(selectedThickness.value))
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(catalog => <Grid item xs={2}>
                            <CatalogCard key={catalog._id} data={catalog} />
                        </Grid>
                        ));
            } else {
                setcatalogsToDisplay([])
            }
        }
    }, [catalogs, configurator.materialId, configurator.shadeId, configurator.thicknessId, materials, haveCompletedConfiguration()])

    return haveCompletedConfiguration() && (
        <Grid container justifyContent="space-around" alignItems="center" direction="column" style={{ marginTop: '2rem' }} >
            <Grid item md={12} style={{ width: '100%' }}>
                {catalogsToDisplay.length <= 0 && <ChipTitle>Aucune formes disponible</ChipTitle>}

                <Grid style={{ padding: "2rem" }}>
                    {
                        catalogsToDisplay.length > 0
                            ?
                            <Grid container justify="space-around" spacing={2}>
                                {catalogsToDisplay}
                            </Grid>
                            :
                            <Typography variant="body1" gutterBottom>Aucune forme disponible pour cette épaisseur</Typography>
                    }

                </Grid>
            </Grid>
        </Grid>
    )
}

export default DisplayCatalog