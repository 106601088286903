import { useContext } from "react";
import { MaterialsContext } from "../contexts/MaterialsContext";

export const useMaterialDetails = () => {
  const [materials] = useContext(MaterialsContext);

  const getDetails = ({ materialId, shadeId, thicknessId }) => {
    const material = materials.find((material) => material._id === materialId);
    const shade = material
      ? material.shades.find((shade) => shade._id === shadeId)
      : null;
    const thickness = shade
      ? shade.thicknesses.find(
        (thickness) => thickness._id === thicknessId
      )
      : null;

    return { material: material?.name, shade: shade?.name, thickness: thickness?.value };
  }

  return { getDetails };
};