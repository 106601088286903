import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import CartLine from '../CartLine';
import CartLinePO from '../CartLinePO';


export default function DialogShowFormsInOrder({ baskets: { baskets, source } = {}, open, handleClose }) {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const getItems = useCallback(
    () => {
      if (source === 'pliageonline') {
        return baskets?.map(item => <Grid item><CartLinePO control={false} key={item._id} item={item} /></Grid>)
      }
      return baskets?.map(item => <Grid item>{item.type === 'accessory' ? <CartLine control={false} key={item._id} item={item} /> : <CartLinePO control={false} key={item._id} item={item} />}</Grid>)
    },
    [baskets, source],
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isDownSm}
      maxWidth={'xl'}
      scroll='body'
    >

      <DialogActions>
        <Button onClick={handleClose} variant='contained' color="primary">
          fermer
        </Button>

      </DialogActions>
      <DialogContent >
        <DialogContentText >
          <Grid container direction='column' spacing={2}>
            {getItems()}
          </Grid>
        </DialogContentText>
      </DialogContent>

    </Dialog>
  );
}
