import React, { useState, useEffect, useContext, useCallback } from 'react'
import DialogAddToCatalog from '../Dialog/DialogAddToCatalog'
import { Fab, Grid, makeStyles, Paper, Typography } from '@material-ui/core'

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DialogProcessCatalogDxf from '../Dialog/DialogProcessCatalogDxf';
import { CatalogContext, useGetCatalog } from '../../contexts/CatalogContext';
import DialogAddToCatalogManuel from '../Dialog/DialogAddToCatalogManuel';
import DialogEditCatalogForm from '../Dialog/DialogEditCatalogForm';
import emptyBox from '../../assets/images/empty-box.jpg'
import CatalogControlCard from './CatalogControlCard';

const useStyle = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
    },
    catalogsContainer: {
        [theme.breakpoints.down("xs")]: {
            padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
        },
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(5),
        },

    },
    actionsBar: {
        marginBottom: theme.spacing(5)
    },
    header: {
        paddingTop: theme.spacing(1),

    },
    emptybasket: {
        padding: theme.spacing(4)
    },
    emptyBasketImg: {
        height: '100px',
        margin: '0 auto',
        display: 'block'
    },
    title: {
        marginTop: '1rem',
    }

}))

function CatalogControl() {

    const { getCatalog } = useGetCatalog()
    const [{ catalogs }, dispatch] = useContext(CatalogContext);
    const classes = useStyle()
    const [dialogAddToCatalog, setdialogAddToCatalog] = useState(false);
    const [openDialogProcessCatalogDxf, setopenDialogProcessCatalogDxf] = useState(false);
    const [openDialogAddManuelToCatalog, setopenDialogAddManuelToCatalog] = useState(false);
    const [openDialogEditCatalog, setopenDialogEditCatalog] = useState(false)
    const [selectedCatalog, setselectedCatalog] = useState(null)
    const [files, setfiles] = useState([]);

    useEffect(() => {
        getCatalog({ field: '', value: '', page: 1 })
    }, [])

    useEffect(() => {
        if (files.length) {
            setopenDialogProcessCatalogDxf(true)
        }

    }, [files]);

    useEffect(() => {
        if (selectedCatalog) {
            setopenDialogEditCatalog(true)
        }
    }, [selectedCatalog, setselectedCatalog]);




    const getCatalogs = useCallback(
        () => {

            return catalogs.sort((a, b) => a.name.localeCompare(b.name)).map(catalog => <Grid key={catalog._id} item xs={11} sm={2}>
                <CatalogControlCard catalog={catalog} setopenDialogEditCatalog={setopenDialogEditCatalog} setselectedCatalog={setselectedCatalog} />
            </Grid>)
        },
        [catalogs],
    )

    const handelclickManualAdd = useCallback(
        () => {
            setopenDialogAddManuelToCatalog(true)
        },
        [],
    )

    const handelCloseDialogEditCatalog = useCallback(
        () => {
            setopenDialogEditCatalog(false)
            setselectedCatalog(null)
        },
        [],
    )


    return (
        <Grid container justify="center" className={classes.root}>
            <Grid item xs={12} sm={11}>
                <Paper elevation={0} >
                    <div className={classes.header}>
                        <Typography color='primary' align='center' variant="h4" className={classes.title} gutterBottom>
                            Catalogue pièces pliées
                        </Typography>

                    </div>
                    <div className={classes.catalogsContainer}>
                        {
                            catalogs.length ?
                                <Grid container justify="flex-end" className={classes.actionsBar} >
                                    <Grid item xs={12}>
                                        <Fab onClick={() => setopenDialogAddManuelToCatalog(true)} color={"secondary"} variant="extended">
                                            <AddCircleIcon />
                                            Ajouter
                                        </Fab>
                                    </Grid>
                                </Grid>
                                : null
                        }

                        {
                            !catalogs.length ?

                                <Grid className={classes.emptybasket} container direction='column' justify="center" alignItems='center'>
                                    <Grid item>
                                        <Typography align="center" variant="h6" gutterBottom>
                                            Pas de contenue
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <img className={classes.emptyBasketImg} src={emptyBox} alt='Panier vide' />
                                    </Grid>
                                    <Grid item>
                                        <Fab onClick={() => setopenDialogAddManuelToCatalog(true)} color={"secondary"} variant="extended">
                                            <AddCircleIcon />
                                            Ajouter
                                        </Fab>
                                    </Grid>
                                </Grid> :
                                <Grid container justify='center' spacing={3}>
                                    {getCatalogs()}
                                </Grid>
                        }

                        {openDialogAddManuelToCatalog ? <DialogAddToCatalogManuel open={openDialogAddManuelToCatalog} handleClose={() => setopenDialogAddManuelToCatalog(false)} /> : null}
                        {dialogAddToCatalog ? <DialogAddToCatalog setfiles={setfiles} open={dialogAddToCatalog} handelclickManualAdd={handelclickManualAdd} handleClose={() => setdialogAddToCatalog(false)} /> : null}
                        {openDialogProcessCatalogDxf ? <DialogProcessCatalogDxf files={files} open={openDialogProcessCatalogDxf} handleClose={() => setopenDialogProcessCatalogDxf(false)} /> : null}
                        {openDialogEditCatalog && selectedCatalog ? <DialogEditCatalogForm selectedCatalog={selectedCatalog} open={openDialogEditCatalog} handleClose={handelCloseDialogEditCatalog} /> : null}
                    </div>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default CatalogControl
