import React, { useCallback, useEffect, useContext, useState } from 'react'

import { DataGrid, GridFilterToolbarButton, getGridStringOperators } from '@material-ui/data-grid';

import { Grid, Paper } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import DialogShowClientInformation from '../Dialog/DialogShowClientInformation'
import { useEditUser, useGetUsers, UserContext } from '../../contexts/UserContext';
import { useAuthUser } from 'react-auth-kit';
import { useHistory } from 'react-router';

const stringColumnType = {
    extendType: 'string',
    filterOperators: getGridStringOperators()
        .filter((operator) => operator.value === 'contains')
        .map((operator) => {
            return {
                ...operator,

            };
        }),
};



function UsersControl() {
    const history = useHistory()
    const auth = useAuthUser()

    useEffect(() => {
        if (auth()?.role !== 0) {
            history.push('/')
        }
    }, []);

    const [users, dispatch] = useContext(UserContext);
    const [clientToShow, setclientToShow] = useState(null)
    const [showModalClientInfos, setshowModalClientInfos] = useState(false)
    const { getUsers } = useGetUsers()
    const { editUser } = useEditUser()
    const [editRowsModel, setEditRowsModel] = React.useState({});

    const handleEditCellChangeCommitted = useCallback(
        ({ id, field, props }) => {
            if (users.users.find(u => u._id === id)) {
                editUser(id, field, props.value)
            }

        },
        [editUser, users],
    )
    const hanclickShowInfoClient = useCallback(
        (client) => {
            setclientToShow(client)
            setshowModalClientInfos(true)
        },
        [],
    )
    const handleCloseInfoClient = useCallback(
        () => {
            setshowModalClientInfos(false)
        },
        [],
    )



    const columnsdef = [
        {
            field: 'idExtern',
            headerName: 'Identifiant Externe',
            disableColumnMenu: true,
            flex: 1, editable: true,

        },
        { field: 'lastName', headerName: 'Nom', disableColumnMenu: true, flex: 1, },
        { field: 'firstName', headerName: 'Prénom', disableColumnMenu: true, flex: 1, },
        { field: 'email', headerName: 'Email', disableColumnMenu: true, flex: 1, },
        { field: 'isPro', headerName: 'Client professionnel', disableColumnMenu: true, flex: 1, type: 'boolean' },
        { field: 'disabled', headerName: 'Désactiver', disableColumnMenu: true, flex: 1, type: 'boolean', editable: true },
        {
            field: "discount",
            headerName: "Remise (%)",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            type: 'number',
            editable: true,

        },
        {
            field: "informations",
            headerName: "Informations",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Grid container alignItems='center' justify='space-around'>
                        <Grid item style={{ cursor: "pointer" }}>
                            <Grid container alignItems='center'>
                                <InfoIcon onClick={() => hanclickShowInfoClient(params.row)} color='primary' />
                            </Grid>

                        </Grid>
                    </Grid>
                );
            }
        },
    ];


    const getRows = useCallback(
        () => {
            if (!!users?.users?.length) {

                return users.users.map(user =>
                ({
                    id: user._id,
                    idExtern: user.idExtern,
                    lastName: user.lastName,
                    firstName: user.firstName,
                    isPro: user.isPro,
                    informations: user,
                    email: user.email,
                    discount: user.discount,
                    disabled: user.disabled,


                })
                )
            }
            return [];
        },
        [users],
    )

    useEffect(() => {
        getUsers()
    }, []);

    const columns = () => {
        if (columnsdef.length > 0) {

            const mappedColumns = columnsdef.map((dataColumn) => {
                const mappedColumn = {
                    ...dataColumn,
                };

                if (mappedColumn.field === 'idExtern' || mappedColumn.field === 'lastName' || mappedColumn.field === 'firstName' || mappedColumn.field === 'client') {
                    mappedColumn.type = 'str';
                }
                return mappedColumn;
            });
            return mappedColumns;
        }
        return [];
    };

    return (
        <Grid container justify="center">
            <Grid item xs={12} sm={11}>
                <Paper elevation={0}>
                    <div style={{ height: '80vh', width: '100%' }}>
                        <DataGrid
                            pageSize={25}
                            pagination
                            editRowsModel={editRowsModel}

                            onEditCellChangeCommitted={handleEditCellChangeCommitted}
                            rows={getRows()}
                            columns={columns()}
                            localeText={{
                                toolbarFilters: 'Filtrer',
                                filterOperatorContains: 'contient',
                                filterOperatorIs: 'égale',
                                filterOperatorNot: 'different',
                                filterValueAny: 'Tout',
                                filterValueTrue: 'Vrai',
                                filterValueFalse: 'Faux',
                            }}
                            components={{
                                Toolbar: GridFilterToolbarButton,
                            }}

                            columnTypes={{ str: stringColumnType }}
                        />
                    </div>

                </Paper>
            </Grid>
            <DialogShowClientInformation open={showModalClientInfos} handleClose={handleCloseInfoClient} client={clientToShow} />
        </Grid>
    )
}

export default UsersControl
