import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import React, { useContext, useCallback } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import { MaterialsContext } from '../../../contexts/MaterialsContext';

function ConfigurationMenuThicknessSelector({ readOnly }) {
    const [materials] = useContext(MaterialsContext);
    const configurator = useContext(ConfiguratorContext)[0]
    const dispachConfig = useContext(ConfiguratorContext)[1]

    // const [value, setvalue] = useState('')

    const getOptions = useCallback(
        () => {
            return materials.find(material => material._id === configurator.materialId)
                ?.shades.find(shade => shade._id === configurator.shadeId)
                ?.thicknesses.map(thickness => <MenuItem key={thickness._id} value={thickness._id}>{thickness.value}</MenuItem>)
        },
        [configurator.materialId, configurator.shadeId, materials],
    )

    const onchange = useCallback(
        (e) => {
            dispachConfig({
                type: 'SET_VALUE',
                payload: {
                    key: 'thicknessId',
                    data: e.target.value
                }
            })
        },
        [dispachConfig],
    )

    return (
        <FormControl fullWidth style={{ marginBottom: '1rem' }}>
            <InputLabel id="configurator-select-thickness-label">Epaisseur (mm)</InputLabel>
            <Select
                labelId="configurator-select-thickness-label"
                id="configurator-thickness-material"
                value={configurator.thicknessId}
                onChange={onchange}
                label="Matériaux"
                fullWidth
                disabled={!configurator.shadeId || readOnly}
                inputProps={{ readOnly: readOnly || false }}
            >
                {getOptions()}
            </Select>
        </FormControl>
    )
}

export default ConfigurationMenuThicknessSelector
