import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Menu, MenuItem, Paper, Select, TextField, Tooltip, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { DataGrid, GridFilterToolbarButton, getGridStringOperators } from '@material-ui/data-grid'
import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useMemo } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { InvoiceContext, useGetInvoices, useUpdateInvoice } from '../../contexts/InvoiceContext'
import { displayFormatedPrice, convertDate } from '../../modules/utils'
import { useState } from 'react'
import DialogShowClientInformation from '../Dialog/DialogShowClientInformation'
import DialogShowBasketsDetails from '../Dialog/DialogShowBasketsDetails'
import { usePost } from '../../modules/request'
import { SnackbarContext } from '../../contexts/SnackbarContext'
import DialogShowFormsInOrder from '../Dialog/DialogShowFormsInOrder'

const useStyle = makeStyles((theme) => ({}))


function CommentDialog({ open, handleClose, data }) {
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const { updateInvoice } = useUpdateInvoice();
    const [currentComment, setCurrentComment] = useState("");

    useEffect(() => {
        setCurrentComment(data?.comment || "")
    }, [data])

    const handleSave = () => {
        updateInvoice(data.id, { comment: currentComment });
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={isDownSm}
            maxWidth={'xl'}
            scroll='body'
        >
            <DialogTitle>Commentaire facture {data.ref}</DialogTitle>
            <DialogContent >
                <DialogContentText >
                    <TextField
                        value={currentComment}
                        onChange={(e) => setCurrentComment(e.target.value)}
                        rows={10}
                        multiline
                        fullWidth
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='contained' color="secondary">
                    Fermer
                </Button>
                <Button onClick={handleSave} variant='contained' color="primary">
                    Sauvegarder
                </Button>
            </DialogActions>
        </Dialog>
    );
}



const stringColumnType = {
    extendType: 'string',
    filterOperators: getGridStringOperators()
        .filter((operator) => operator.value === 'contains')
        .map((operator) => {
            return {
                ...operator,

            };
        }),
};


function InvoicesControl() {

    const [invoices, dispatch] = useContext(InvoiceContext);
    const [InvoiceToShow, setInvoiceToShow] = useState(null);
    const [showModalFormsInfos, setshowModalFormsInfos] = useState(false);
    const [clientToShow, setclientToShow] = useState(null);
    const [showModalClientInfos, setshowModalClientInfos] = useState(false);
    const [showModalComment, setShowModalComment] = useState(false);
    const [modalCommentData, setModalCommentData] = useState({});
    const [isGenerate, setIsGenerate] = useState(false);
    const [isTransfert, setIsTransfert] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [selectedPdfInvoices, setSelectedPdfInvoices] = useState([]);
    const [isExported, setIsExported] = useState(false);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState([]);
    const [, dispatchSnackbar] = useContext(SnackbarContext)

    const { getInvoices } = useGetInvoices();
    const { updateInvoice } = useUpdateInvoice();

    const post = usePost();

    useEffect(() => {
        getInvoices({ filterModel, page: page + 1, pageSize: 25 })
    }, [page, filterModel]);

    const handelClickShowInfoForms = invoice => {
        setInvoiceToShow(invoice);
        setshowModalFormsInfos(true);
    };

    const hanclickShowInfoClient = client => {
        setclientToShow(client);
        setshowModalClientInfos(true);
    };

    const handleCloseInfoClient = () => setshowModalClientInfos(false);
    const handleCloseInfoForms = () => {
        setshowModalFormsInfos(false);
        setInvoiceToShow(null);
    }

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickGenerate = async () => {
        try {
            setIsGenerate(true);
            for (const invoice of selectedPdfInvoices) {

                const response = await post('/calculate/invoice', { invoiceId: invoice.id }, {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `facture ${invoice.ref}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

            }
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Edition effectué',
                    type: 'success',
                    duration: 4000
                }
            });
        } catch (e) {
            console.log(e);
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Erreur lors de l\'édition',
                    type: 'error',
                    duration: 4000
                }
            });
        } finally {
            setIsGenerate(false);
            setSelectedPdfInvoices([]);
            handleCloseMenu();
        }
    };

    const onEditCell = (params) => {
        const { id, field, props } = params;
        const newData = {
            [field]: props.value
        };
        updateInvoice(id, newData);
    }

    const handleToggleIsPaid = async (row) => {
        updateInvoice(row.id, { isPaid: !row.isPaid });
    };

    const handleOpenCommentDialog = (row) => {

        setShowModalComment(true)
        setModalCommentData(row)
    };

    const handleCloseModalComment = () => {
        setShowModalComment(false)
        setModalCommentData({})
    }

    const handleExport = async () => {
        try {
            setIsExported(true);


            const response = await post('/invoice/export', {
                invoiceIds: selectedInvoices.map(({ id }) => id)
            }, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export.zip');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Export CSV effectué',
                    type: 'success',
                    duration: 4000
                }
            });
        } catch (e) {
            console.log(e);
            dispatchSnackbar({
                type: 'SET_SNACKBAR',
                payload: {
                    open: true,
                    content: 'Erreur lors de l\'export CSV',
                    type: 'error',
                    duration: 4000
                }
            });
        } finally {
            setIsExported(false);
            setSelectedInvoices([])
            handleCloseMenu()
        }
    }

    const handleCheckSelect = (event, invoice) => {
        if (event.target.checked) {
            setSelectedInvoices([...selectedInvoices, invoice])
        } else {
            setSelectedInvoices([...selectedInvoices.filter(o => o.id !== invoice.id)])
        }
    }

    const handleCheckPdfSelect = (event, invoice) => {
        if (event.target.checked) {
            setSelectedPdfInvoices([...selectedPdfInvoices, invoice])
        } else {
            setSelectedPdfInvoices([...selectedPdfInvoices.filter(o => o.id !== invoice.id)])
        }
    }

    const rendercsvHeaderName = () => {
        return <>

            {selectedInvoices?.length ? <div>
                <IconButton onClick={handleExport} color="secondary" variant="contained" size="small">
                    <CheckIcon color='primary' />
                </IconButton>
            </div> : 'CSV'}
        </>
    }

    const renderpdfHeaderName = () => {
        return <>
            {isGenerate ? <CircularProgress size={15} /> : selectedPdfInvoices?.length ? <div>

                <IconButton onClick={handleClickGenerate} color="secondary" variant="contained" size="small">
                    <CheckIcon color='primary' />
                </IconButton>
            </div> : 'PDF'}
        </>
    }

    const handleFilterModelChange = (params) => {
        const filters = params.filterModel.items.map((item) => {
            return {
                columnField: item.columnField,
                operatorValue: item.operatorValue,
                value: item.value,
            };
        });

        setFilterModel(filters);
    };


    const columnsdef = [
        {
            field: "pdf",
            headerName: renderpdfHeaderName(),
            sortable: false,
            width: 75,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: params => <Checkbox
                onChange={(event) => handleCheckPdfSelect(event, params.row)}
                checked={selectedPdfInvoices.some(order => order.id === params?.row?.id)}
                color="primary"
            />
        },
        {
            field: "csv",
            headerName: rendercsvHeaderName(),
            sortable: false,
            width: 75,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            filterable: false,
            renderCell: params => params.row.client?.idExtern ? <Checkbox
                onChange={(event) => handleCheckSelect(event, params.row)}
                checked={selectedInvoices.some(order => order.id === params?.row?.id)}
                color="primary"
            /> : <Tooltip title="Le client n'a pas de référence">
                <IconButton aria-label="delete">
                    <HelpIcon />
                </IconButton>
            </Tooltip>
        },

        { field: 'ref', headerName: 'REF', disableColumnMenu: true, width: 140 },

        {
            field: "date",
            headerName: "Date",
            type: 'dateTime',
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    convertDate(params.row.date)
                );
            },
        },
        {
            field: "content",
            headerName: "Contenu",
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            menu: false,
            disableClickEventBubbling: true,
            filterable: false,

            renderCell: (params) => {
                return (
                    <Grid container alignItems='center' justify='space-around'>
                        <Grid item onClick={() => handelClickShowInfoForms({ baskets: params.row.content, source: params.row.source })} style={{ cursor: 'pointer' }}>
                            {`${params.row.content.length} modèle(s)`}
                        </Grid>

                    </Grid>
                );
            }
        },
        {
            field: "client",
            headerName: "Client",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Grid container alignItems='center' justify='space-around'>
                        <Grid item onClick={() => hanclickShowInfoClient(params.row.client)} style={{ cursor: 'pointer' }}>
                            {`${params.row.client.lastName} ${params.row.client.firstName}`}
                        </Grid>

                    </Grid>
                );
            }
        },
        {
            field: "isPaid",
            headerName: "Réglé",
            sortable: true,
            width: 100,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <IconButton>
                        {params.value ? <CheckIcon color='primary' /> : <CloseIcon color='secondary' />}
                    </IconButton>
                );
            },
        },
        {
            field: "paymentMethod",
            headerName: "Moyen de paiement",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,

            renderCell: (params) => {
                return (
                    <Select fullWidth value={params.row.paymentMethod} onChange={(e) => onEditCell({ id: params.row.id, field: 'paymentMethod', props: { value: e.target.value } })}>
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'CB'}>CB</MenuItem>
                        <MenuItem value={'bankCheck'}>Chèque</MenuItem>
                        <MenuItem value={'billOfExchange'}>Lettre de change</MenuItem>
                        <MenuItem value={'transfer'}>Virement</MenuItem>
                        <MenuItem value={'cash'}>Espèce</MenuItem>
                    </Select>
                );
            },
        },
        {
            field: "comment",
            headerName: "Commentaire",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                const comment = params.value;
                const truncatedComment = comment.length > 20 ? comment.substring(0, 20) + "..." : comment;

                return (
                    <div style={{ cursor: "pointer", width: '100%', height: '100%' }} onClick={() => handleOpenCommentDialog(params.row)}>
                        {truncatedComment}
                    </div>
                );
            }
        },
        {
            field: "paymentDate",
            headerName: "Date paiement",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            type: 'dateTime',
            editable: true,
            renderCell: (params) => {
                return params.row.paymentDate ? convertDate(params.row.paymentDate) : null
            },
        },
        {
            field: "nextReminderDate",
            headerName: "Prochaine relance",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            type: 'dateTime',
            editable: true,
            renderCell: (params) => {
                return convertDate(params.value);
            },
        },
        {
            field: "amount",
            headerName: "Prix HT commande",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>{`${params.row.amount} €`}</div>
                );
            },

        },
        {
            field: "deliveryPrice",
            headerName: "Prix HT livraison",
            sortable: true,
            flex: 1,
            editable: true,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            type: 'number',
            renderCell: (params) => {
                return (
                    <div>{`${params.row.deliveryPrice} €`}</div>
                );
            },

        },
        {
            field: "totalPriceWithVat",
            headerName: "Prix TTC",
            sortable: true,
            flex: 1,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>{`${params.row.totalPriceWithVat} €`}</div>
                );
            },

        },

    ];

    const rows = useMemo(
        () => {

            return invoices.map(invoice =>
            ({
                id: invoice._id,
                source: invoice.source,
                ref: invoice.ref,
                date: invoice.invoiceDate,
                content: invoice.baskets,
                client: invoice.user,
                amount: displayFormatedPrice(invoice.amount),
                deliveryPrice: displayFormatedPrice(invoice.deliveryPrice) || null,
                totalPriceWithVat: displayFormatedPrice(invoice.total),
                isPaid: invoice.isPaid,
                comment: invoice.comment,
                nextReminderDate: invoice.nextReminderDate,
                paymentDate: invoice.paymentDate,
                paymentMethod: invoice.paymentMethod

            })
            )
        },
        [invoices],
    );


    const isPaidInputComponent = (props) => {
        const { applyValue, item, ...others } = props;
        return <Select

            value={item.value}
            onChange={(e) => applyValue({ ...item, value: e.target.value })}

        >
            <MenuItem key="yes" value={true} >
                Oui
            </MenuItem>
            <MenuItem key="no" value={false} >
                Non
            </MenuItem>

        </Select>;
    };

    const paiedMethodeInputComponent = (props) => {
        const { applyValue, item, ...others } = props;
        return <Select

            value={item.value}
            onChange={(e) => applyValue({ ...item, value: e.target.value })}

        >
            <MenuItem value={''}></MenuItem>
            <MenuItem value={'CB'}>CB</MenuItem>
            <MenuItem value={'bankCheck'}>Chèque</MenuItem>
            <MenuItem value={'billOfExchange'}>Lettre de change</MenuItem>
            <MenuItem value={'transfer'}>Virement</MenuItem>
            <MenuItem value={'cash'}>Espèce</MenuItem>

        </Select>;
    };

    const columns = () => {
        if (columnsdef.length > 0) {

            const isPaidColumn = columnsdef.find((column) => column.field === 'isPaid');
            const isPaidColIndex = columnsdef.findIndex((col) => col.field === 'isPaid');

            const paiedMethodeColumn = columnsdef.find((column) => column.field === 'paymentMethod');
            const paiedMethodeColIndex = columnsdef.findIndex((col) => col.field === 'paymentMethod');


            const isPaidFilterOperators = getGridStringOperators().filter(
                (operator) => {
                    return operator.value === 'equals'
                }
            ).map(
                (operator) => ({
                    ...operator,
                    InputComponent: isPaidInputComponent,
                }),
            );

            const paiedMethodeOperators = getGridStringOperators().filter(
                (operator) => {
                    return operator.value === 'equals'
                }
            ).map(
                (operator) => ({
                    ...operator,
                    InputComponent: paiedMethodeInputComponent,
                }),
            );

            const mappedColumns = columnsdef.map((dataColumn) => {
                const mappedColumn = {
                    ...dataColumn,
                };

                if (mappedColumn.field === 'ref' || mappedColumn.field === 'client') {
                    mappedColumn.type = 'str';
                }
                return mappedColumn;
            });

            mappedColumns[isPaidColIndex] = {
                ...isPaidColumn,
                filterOperators: isPaidFilterOperators,
            };

            mappedColumns[paiedMethodeColIndex] = {
                ...paiedMethodeColumn,
                filterOperators: paiedMethodeOperators,
            };


            return mappedColumns;
        }
        return [];
    };


    return (
        <>
            <Grid container justify="center" >
                <Grid item xs={12} sm={11}>
                    <Paper elevation={0} style={{ paddingTop: '1rem' }}>
                        <Typography align="center" variant="h5">Factures</Typography>
                        <div style={{ height: '80vh', width: '100%' }}>
                            <DataGrid
                                pageSize={25}
                                pagination
                                onEditCellChangeCommitted={onEditCell}
                                filterMode="server"
                                rows={rows}
                                columns={columns()}
                                localeText={{
                                    toolbarFilters: 'Filtrer',
                                    filterOperatorContains: 'contient',
                                    filterOperatorIs: 'égale',
                                    filterOperatorNot: 'différent',
                                    filterOperatorAfter: '>',
                                    filterOperatorOnOrAfter: '>=',
                                    filterOperatorBefore: '<',
                                    filterOperatorOnOrBefore: '<='

                                }}
                                components={{
                                    Toolbar: GridFilterToolbarButton,
                                }}
                                onPageChange={(params) => setPage(params.page)}
                                onFilterModelChange={handleFilterModelChange}
                                columnTypes={{ str: stringColumnType }}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <DialogShowClientInformation open={showModalClientInfos} handleClose={handleCloseInfoClient} client={clientToShow} />
            <CommentDialog open={showModalComment} handleClose={handleCloseModalComment} data={modalCommentData} />
            {InvoiceToShow && <DialogShowFormsInOrder open={showModalFormsInfos} handleClose={handleCloseInfoForms} baskets={InvoiceToShow} />}

        </>
    )
}

export default InvoicesControl
