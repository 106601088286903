import React, { useContext, useReducer } from 'react';
import { useGet, usePut, useDelete, usePost } from '../modules/request';
import { SnackbarContext } from './SnackbarContext';


const BasketContext = React.createContext([[], () => { }]);

const initialState = []

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_BASKET":
      return action.payload;
    case "ADD_BASKET_ITEM":
      return [...state, { ...action.payload }];
    case "EDIT_BASKET_ITEM":
      return state.map(basket => basket._id != action.payload._id ? basket : action.payload);
    case "DELETE_BASKET_ITEM":
      return state.filter(item => item._id != action.payload);
    case "UPDATE_QUANTITY":
      const basketIda = action.payload.basketId;
      const quantitya = action.payload.quantity;

      return state.map(basket => basket._id != basketIda ? basket : { ...basket, quantity: +quantitya })

    case "UPDATE_ACCESSORY_QUANTITY":
      const accessoryId = action.payload.accessoryId;
      const basketId = action.payload.basketId;
      const quantity = action.payload.quantity;

      return state.map(basket => basket._id != basketId ? basket._id != accessoryId ? basket : { ...basket, quantity: +quantity } : { ...basket, accessories: basket.accessories.map(accessory => accessory._id != accessoryId ? accessory : { ...accessory, quantity: +quantity }) })
    default:
      throw new Error();
  }
};


const useGetBasket = () => {
  const [, dispatch] = useContext(BasketContext);
  const get = useGet()
  return {
    getBasket: async () => {

      const res = await get(`/basket/my`)
      dispatch({

        type: 'SET_BASKET',
        payload: res.data.message,
      })

    },
  };
}

const useEditBasketItem = () => {
  const [, dispatch] = useContext(BasketContext);
  const put = usePut()
  return {
    editItemBasket: async (data) => {
      const res = await put(`/basket/item/${data._id}`, data)
      dispatch({
        type: 'EDIT_BASKET_ITEM',
        payload: res.data.message,
      })
    },
  };
}

const useDeleteItemBasket = () => {
  const [, dispatch] = useContext(BasketContext);
  const del = useDelete()
  return {
    deleteItemBasket: async (id) => {
      try {
        const res = await del(`/basket/item/${id}`)
        if (res.status === 200)
          dispatch({
            type: 'DELETE_BASKET_ITEM',
            payload: id,
          })
      } catch (e) {

      }
    },
  };
}

const useAddAccessoriesBasket = () => {
  const [, dispatch] = useContext(BasketContext);
  const [, dispatchSnackbar] = useContext(SnackbarContext)
  const post = usePost()
  return {
    addAccessoriesBasket: async (data) => {
      try {
        let url = '/basket/accessories/add';
        if (window.sessionId) {
          url += '?sessionId=' + window.sessionId;
        }
        const res = await post(url, data);
        if (res.status === 201)
          dispatch({
            type: 'ADD_BASKET_ITEM',
            payload: res.data.message,
          })
        dispatchSnackbar({
          type: 'SET_SNACKBAR',
          payload: {
            open: true,
            content: 'Ajouté au panier !',
            type: 'success',
            duration: 6000
          }
        })
      } catch (e) {

      }
    },
  };
}

const useAddItemBasket = () => {
  const [, dispatch] = useContext(BasketContext);
  const [, dispatchSnackbar] = useContext(SnackbarContext)
  const post = usePost()
  return {
    addItemBasket: async (data) => {
      try {
        let url = '/basket/add';
        if (window.sessionId) {
          url += '?sessionId=' + window.sessionId;
        }
        const res = await post(url, data);
        if (res.status === 201)
          dispatch({
            type: 'ADD_BASKET_ITEM',
            payload: res.data.message,
          })
        dispatchSnackbar({
          type: 'SET_SNACKBAR',
          payload: {
            open: true,
            content: 'Ajouté au panier !',
            type: 'success',
            duration: 6000
          }
        })
      } catch (e) {

      }
    },
  };
}

function BasketProvider(props) {
  const [basket, dispatch] = useReducer(reducer, initialState);

  return (
    <BasketContext.Provider value={[basket, dispatch]}>
      {props.children}
    </BasketContext.Provider>
  );
};

export { BasketContext, BasketProvider, useGetBasket, useEditBasketItem, useDeleteItemBasket, useAddItemBasket, useAddAccessoriesBasket };