import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Grid } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export default function AlertDialog({open, setOpen}) {
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title" style={{textAlign: 'center'}}>Validation de votre inscription</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container justify='center'>
                <CheckBoxIcon style={{ color: green[500],fontSize: 80 }}/>
            </Grid>
            <Grid container justify='center'>
                <p>Votre inscription a bien été prise en compte.<br/>
                    Un e-mail de vérification vous a été envoyé, cliquez sur le lien à l'intérieure pour finaliser votre inscription.
                </p>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            J'ai compris
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
