import makerjs from 'makerjs'
import parse from 'parse-svg-path'
import pathThatSvg from 'path-that-svg'
import uniqid from 'uniqid'





const polylinesToModels = (polylines) => {
    let modelstab = { models: {} }
    polylines.forEach((polyline, index) => {
        let points = ''
        polyline.vertices.map(item => { return [+item[0].toFixed(3), +item[1].toFixed(3)] }).forEach((vertice, index) => points += vertice.join(' ') + ' ')
        const model = new makerjs.models.ConnectTheDots(false, points);
        modelstab.models[uniqid()] = model

        /* if ((polyline.rgb[0] === 0 && polyline.rgb[1] === 0 && polyline.rgb[2] === 255) || (polyline.rgb[0] === 255 && polyline.rgb[1] === 0 && polyline.rgb[2] === 0)) {
            nbFolding = nbFolding + 1
        } else {
            polyline.vertices.map(item => { return [+item[0].toFixed(3), +item[1].toFixed(3)] }).forEach((vertice, index) => points += vertice.join(' ') + ' ')
            const model = new makerjs.models.ConnectTheDots(false, points);
            modelstab.models[uniqid()] = model
        } */
    })

    return { modelstab }
}

const svgDataPathToModel = (paths) => {
    const model = {};
    paths.forEach(path => makerjs.model.addModel(model, makerjs.importer.fromSVGPathData(path), uniqid()))
    return model;
}

const measureModel = (model) => {
    const { height, width } = makerjs.measure.modelExtents(model);
    return { height, width }
}

const svgToModel = async (svg) => {
    let lines = svg.match(/(?:d=")[\s\S]*?(?=")/g).map(line => line.replace(/d="/, "")).filter(line => line[0] === 'M' || line[0] === 'm');
    const model = {
        models: {}
    }

    function fixPath(path) {
        var resolvedParts = [];

        parse(path).forEach(function (command) {
            resolvedParts.push(command.join(' '));
        });

        return resolvedParts.join(' ');
    }

    lines.forEach((line, index) => {
        const formatedLine = fixPath(line)
        model.models[index] = makerjs.importer.fromSVGPathData(formatedLine);

    })
    return model;
}

export { polylinesToModels, svgDataPathToModel, measureModel, svgToModel }