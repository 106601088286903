import { Paper } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ConfigurationManuShadeSelector from './ConfigurationManuShadeSelector';
import ConfigurationMenuThicknessSelector from './ConfigurationMenuThicknessSelector';
import ConfiguratorMenuMaterialSector from './ConfiguratorMenuMaterialSector';
import ConfiguratorMenuNumberPiece from './ConfiguratorMenuNumberPiece';
import ConfiguratorMenuLength from './ConfiguratorMenuLength';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        boxShadow: 'none'
    }
}))


function ConfiguratorMenu({ readOnly, withoutNbPiece, withoutThinckness, byCatalog }) {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={classes.paper}>
            <ConfiguratorMenuMaterialSector readOnly={readOnly} />

            <ConfigurationManuShadeSelector readOnly={readOnly} />
            {!withoutThinckness && <ConfigurationMenuThicknessSelector readOnly={readOnly} />}
            {!withoutNbPiece && <ConfiguratorMenuNumberPiece readOnly={readOnly} />}
            <ConfiguratorMenuLength readOnly={readOnly} />
        </Paper>
    )
}

export default ConfiguratorMenu
