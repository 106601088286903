import { FormControl, TextField } from '@material-ui/core';
import React, { useContext, useCallback, useState } from 'react'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';

function ConfiguratorMenuNumberPiece({ readOnly }) {
    const configurator = useContext(ConfiguratorContext)[0]
    const dispachConfig = useContext(ConfiguratorContext)[1]

    // const [value, setvalue] = useState('')



    const onchange = useCallback(
        (e) => {

            dispachConfig({
                type: 'SET_VALUE',
                payload: {
                    key: 'nbPiece',
                    data: e.target.value
                }
            })


        },
        [dispachConfig],
    )

    return (
        <FormControl >
            <TextField
                id="configurator-input-number-piece"
                value={configurator.nbPiece}
                onChange={onchange}

                disabled={!configurator.thicknessId || readOnly}
                inputProps={{ readOnly: readOnly || false }}
                type="number"
            />
        </FormControl>
    )
}

export default ConfiguratorMenuNumberPiece
