import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, ListItem, ListItemIcon, ListItemText, Paper, Slider, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import makerjs from 'makerjs'
import * as ImageTracer from "imagetracerjs";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./../../../src/modules/worker";
import { ConfiguratorContext } from '../../contexts/ConfiguratorContext';
import ScannerIcon from '@material-ui/icons/Scanner';
import { BallTriangle } from 'react-loader-spinner';
import { makeStyles } from '@material-ui/styles';
import { FixedSizeList } from 'react-window';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import { measureModel, svgDataPathToModel } from '../../modules/makerjs';
import GreenButton from '../Button/GreenButton';
import { Group, Layer, Rect, Shape, Stage } from 'react-konva';
import { PropertiesContext } from '../../contexts/PropertiesContext';

const useStyles = makeStyles((theme) => ({
    optionsTitle: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    }
}))

function RenderRow({ data, index, style }) {

    const { lines, toggleItemActive } = data;

    const handleMouseOver = useCallback(({ id }) => {
        /*  const item = document.getElementById(id);
         item.style.stroke = 'red' */

    }, [])

    const handleMouseLeave = useCallback(({ id, active }) => {
        /* const item = document.getElementById(id);
        item.style.stroke = active ? 'black' : 'white' */
    }, [])

    return (

        <ListItem style={style} onClick={() => toggleItemActive(lines[index].id)} onMouseLeave={() => handleMouseLeave(lines[index])} onMouseEnter={() => handleMouseOver(lines[index])} button key={lines[index].id}>
            <ListItemIcon>
                {lines[index].active ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </ListItemIcon>
            <ListItemText primary={`Contour ${index + 1}`} />
        </ListItem>

    )
}


function ModalProcessImageVecto({ open, handleClose, file, onValidate }) {

    const stageRef = useRef(null)
    const [svg, setsvg] = useState('');
    const [lines, setlines] = useState([]);
    const [numberofcolors, setnumberofcolors] = useState(2);
    const [qtres, setqtres] = useState(1);
    const [imgHeight, setimgHeight] = useState(0);
    const [imgWidth, setimgWidth] = useState(0);
    const [originalHeight, setoriginalHeight] = useState(null);
    const [originalWidth, setoriginalWidth] = useState(null);
    const [targetHeight, settargetHeight] = useState(null);
    const [targetWidth, settargetWidth] = useState(null);
    const [startProcess, setstartProcess] = useState(false);
    const [errorH, seterrorH] = useState(false);
    const [errorHText, seterrorHText] = useState('');
    const [errorW, seterrorW] = useState(false);
    const [errorWText, seterrorWText] = useState('');
    const [properties] = useContext(PropertiesContext);
    const [ratio, setratio] = useState(0);
    const [model, setmodel] = useState({});
    const [configurator, configuratorDispatch] = useContext(ConfiguratorContext)
    const theme = useTheme();
    const isDownmd = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    const process = useCallback(() => {

        setstartProcess(true)

    }, [])

    useEffect(() => {
        if (startProcess) {

            ImageTracer.loadImage(URL.createObjectURL(file), (canvas) => {

                const imagedata = ImageTracer.getImgdata(canvas)
                setimgHeight(imagedata.height);
                setimgWidth(imagedata.width);
                const worker = new Worker()
                worker.postMessage({ type: 'IMG_IMPORT', imagedata, numberofcolors, targetHeight, targetWidth, originalHeight, originalWidth, qtres });
                worker.onmessage = (event) => {
                    const { height, width, lines, model } = event.data;
                    const paths = []
                    lines.forEach(line => {
                        paths.push(<path fill="white" stroke='black' id={line.id} d={line.data} />)
                    })
                    setlines(lines);
                    setmodel(model);
                    settargetHeight('');
                    settargetWidth('');
                    setstartProcess(false);

                    worker.terminate();
                }
            })
        }
    }, [configuratorDispatch, file, numberofcolors, originalHeight, originalWidth, startProcess, targetHeight, targetWidth])



    const handleClickProcess = useCallback(() => {
        process();
    }, [process])

    const handleChangeNbOfColors = (e, v) => {
        setnumberofcolors(v)
    }

    const handleChangeQtres = (e, v) => setqtres(v);

    const handleChangeHeight = useCallback((e) => {

        if (e.target.value.match(/^[0-9]*$/)) {
            seterrorH(false)
            seterrorHText('')
            if (Number(e.target.value) < properties.machineMinLength || Number(e.target.value) > properties.machineMaxLength) {
                seterrorHText(`valeur comprisent entre ${properties.machineMinLength} et ${properties.machineMaxLength}`)
                seterrorH(true)
            }
            settargetHeight(+e.target.value)
        }

    }, [properties.machineMaxLength, properties.machineMinLength])

    const handleBlurHeight = useCallback(() => {
        const value = Math.ceil(targetHeight / ratio);
        seterrorW(false)
        seterrorWText('')
        if (Number(value) < properties.machineMinWidth || Number(value) > properties.machineMaxWidth) {
            seterrorWText(`valeur comprisent entre ${properties.machineMinWidth} et ${properties.machineMaxWidth}`)
            seterrorW(true)
        }
        settargetWidth(value)

    }, [properties.machineMaxWidth, properties.machineMinWidth, ratio, targetHeight])

    const handleChangeWidth = useCallback((e) => {
        if (e.target.value.match(/^[0-9]*$/)) {
            seterrorW(false)
            seterrorWText('')
            if (Number(e.target.value) < properties.machineMinWidth || Number(e.target.value) > properties.machineMaxWidth) {
                seterrorWText(`valeur comprisent entre ${properties.machineMinWidth} et ${properties.machineMaxWidth}`)
                seterrorW(true)
            }
            settargetWidth(+e.target.value)
        }

    }, [properties.machineMaxWidth, properties.machineMinWidth])

    const handleBlurWidth = useCallback(() => {


        seterrorH(false)
        const value = Math.ceil(targetWidth * ratio);
        seterrorHText('')
        if (Number(value) < properties.machineMinLength || Number(value) > properties.machineMaxLength) {
            seterrorHText(`valeur comprisent entre ${properties.machineMinLength} et ${properties.machineMaxLength}`)
            seterrorH(true)
        }
        settargetHeight(+value)
    }, [properties.machineMaxLength, properties.machineMinLength, ratio, targetWidth])

    /* const handleChangeHeight = useCallback((e) => {
        settargetHeight(e.target.value)
    }, [])

    const handleBlurHeight = useCallback(() => {
        settargetWidth(Math.ceil(targetHeight / ratio))
    }, [ratio, targetHeight])

    const handleChangeWidth = useCallback((e) => {
        settargetWidth(e.target.value)
    }, [])

    const handleBlurWidth = useCallback(() => {
        settargetHeight(Math.ceil(targetWidth * ratio))
    }, [ratio, targetWidth]) */


    const toggleItemActive = useCallback((id) => {


        setlines(lines.map(line => {
            if (line.id === id) {
                /* 
                                if (line.active) {
                                    const item = document.getElementById(line.id);
                
                                    item.style.stroke = 'white'
                                } else {
                                    const item = document.getElementById(line.id);
                                    item.style.stroke = 'black'
                                } */
                return { ...line, active: !line.active }
            } else {
                return line
            }
        }))


    }, [lines])

    useEffect(() => {
        const activeLines = lines.filter(line => line.active).map(line => line.data);
        if (activeLines && activeLines.length) {

            const model = svgDataPathToModel(activeLines);
            const measure = measureModel(model);

            const recalculateOriginHeight = Math.round(measure.height);
            const recalculateOriginWidth = Math.round(measure.width);
            setoriginalHeight(recalculateOriginHeight);
            setoriginalWidth(recalculateOriginWidth);
            settargetHeight('');
            settargetWidth('');
            let ratio = recalculateOriginHeight / recalculateOriginWidth;
            setratio(ratio)

            const paths = lines.map(line => <path fill={line.fill} stroke={line.stroke} strokeWidth={"4px"} key={line.id} id={line.id} d={line.data} />)
            setsvg(lines.length ? <svg viewBox={`0 0 ${recalculateOriginWidth} ${recalculateOriginHeight}`} width={"auto"} height={"100%"}>{paths}</svg> : null)
        }
    }, [lines])

    const handleClickValidate = () => {

        const l = lines.filter(line => line.active).map(line => line.data);
        let model = {
            models: {}
        }
        l.forEach((line, index) => {
            model.models[index] = makerjs.importer.fromSVGPathData(line);
        })
        const scropedSVG = makerjs.exporter.toSVG(model);
        const linesScroped = scropedSVG.match(/(?:path[ \s]d=")[\s\S]*?(?=")/g).map(line => line.replace(/path[ \s]d="/, "")).filter(line => line[0] === 'M' || line[0] === 'm');
        model = {
            models: {}
        }
        linesScroped.forEach((line, index) => {
            model.models[index] = makerjs.importer.fromSVGPathData(line);
        })
        onValidate({ lines: linesScroped, targetHeight: targetHeight || originalHeight, targetWidth: targetWidth || originalWidth, originalHeight, originalWidth, ratio, model })
    }

    const getScale = useCallback(() => {

        const clientHeight = stageRef?.current?.clientHeight - 50;
        const clientWidth = stageRef?.current?.clientWidth - 50;

        let r = 1;
        if (imgHeight > imgWidth) {
            r = clientHeight / imgHeight;
        } else {
            r = clientWidth / imgWidth;
        }
        return { x: r, y: r }

    }, [imgHeight, imgWidth])


    return (
        <Dialog fullWidth={true} fullScreen open={open} onClose={handleClose} >
            <DialogTitle style={{ textAlign: 'center' }} id="add-to-catalog-dialog">Vectorisation d'une image</DialogTitle>
            <DialogContent style={{ paddingTop: '1rem' }}>
                <Grid container justify='space-around'>
                    <Grid item xs={12} md={4}>
                        <Grid container justify='center' alignItems='center' direction='column'>
                            <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom display='inline'>
                                    Votre image
                                </Typography>
                            </Grid>
                            <Grid xs={6}>
                                <img style={{ maxHeight: '200px', maxWidth: '300px' }} src={URL.createObjectURL(file)} alt="" />
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: '1rem 0' }} />
                        <Grid container>

                            <Grid item xs={12}>
                                <Typography style={{ marginBottom: '1rem' }} variant="h6" gutterBottom display='block'>
                                    Réglages
                                </Typography>
                                {originalHeight && originalWidth && <> <Grid container >
                                    <Grid item xs={6}>
                                        <Typography className={classes.optionsTitle} variant="button" gutterBottom display='block'>
                                            Dimensions originales
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.optionsTitle} variant="button" gutterBottom display='block'>
                                            Dimensions souhaitées
                                        </Typography>
                                    </Grid>
                                </Grid>
                                    <Grid container alignItems='center' >
                                        <Grid item xs={6}>
                                            <Typography variant="body1" gutterBottom display='block'>
                                                Largeur  <small>(mm)</small> : {originalHeight}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container alignItems='center'>
                                                <Grid item xs={5}>

                                                    <Typography variant="body1" gutterBottom display='inline'>
                                                        Largeur  <small>(mm)</small> :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>

                                                    <TextField value={targetHeight} onBlur={handleBlurHeight} onChange={handleChangeHeight} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container alignItems='center' >
                                        <Grid item xs={6}>
                                            <Typography variant="body1" gutterBottom display='block'>
                                                Longueur<small>(mm)</small> : {originalWidth}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container alignItems='center'>
                                                <Grid item xs={5}>

                                                    <Typography variant="body1" gutterBottom display='inline'>
                                                        Longueur<small>(mm)</small> :
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>

                                                    <TextField value={targetWidth} onBlur={handleBlurWidth} onChange={handleChangeWidth} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </>}

                                <Typography variant="button" className={classes.optionsTitle} gutterBottom display='block'>
                                    Nombre de couleurs
                                </Typography>
                                <Slider
                                    defaultValue={2}
                                    getAriaValueText={(v) => v}
                                    onChangeCommitted={handleChangeNbOfColors}
                                    aria-labelledby="discrete-slider"
                                    step={1}
                                    marks
                                    min={1}
                                    max={20}
                                    valueLabelDisplay="auto"
                                />
                                <Typography variant="button" className={classes.optionsTitle} gutterBottom display='block'>
                                    Lissage des courbes
                                </Typography>
                                <Slider
                                    defaultValue={1}
                                    getAriaValueText={(v) => v}
                                    onChangeCommitted={handleChangeQtres}
                                    aria-labelledby="qtres-slider"
                                    step={1}
                                    marks
                                    min={1}
                                    max={20}
                                    valueLabelDisplay="auto"
                                />

                            </Grid>
                            <Typography variant="caption" gutterBottom display='block'>
                                {errorHText || errorWText}
                            </Typography>
                        </Grid>
                        <Divider style={{ margin: '1rem 0' }} />
                    </Grid>
                    <Divider flexItem orientation="vertical" />
                    <Grid item xs={12} md={7}>
                        <Grid container justify='center' >
                            <Grid direction='column' container item xs={12} md={4}>
                                {lines.length > 0 && !startProcess && <Accordion elevation={0}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <EditIcon color="primary" />
                                        <Typography>Editer les contours</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FixedSizeList itemData={{ toggleItemActive, lines }} height={isDownmd ? 100 : 500} width={200} itemSize={20} itemCount={lines.length}>
                                            {RenderRow}
                                        </FixedSizeList>
                                    </AccordionDetails></Accordion>}
                            </Grid>
                            {/* <Grid item xs={8} style={{ height: '80vh', overflow: 'auto' }}>
                                {startProcess ? <BallTriangle style={{ justifyContent: 'center' }} height={300} width={300} strokeWidth="1" ariaLabel="rotating-square" visible={true} color="#3A7297" />
                                    : <div >{svg}</div>}
                            </Grid> */}
                            <Grid ref={stageRef} item xs={12} md={8} style={{ height: '80vh', overflow: 'auto' }}>
                                <Stage width={imgWidth * getScale()?.x} height={imgHeight * getScale()?.y} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Layer>
                                        <Group
                                            x={0}
                                            y={0}
                                            scale={getScale()}
                                        >{lines.map(line => <Shape
                                            fill={line.active ? '#a1a0a0' : 'transparent'}
                                            stroke={line.active ? 'black' : 'transparent'}
                                            strokeWidth={2}

                                            sceneFunc={(ctx, shape) => {
                                                const p = new Path2D(line.data);
                                                ctx.beginPath();
                                                ctx.closePath();
                                                ctx.fillStrokeShape(shape);
                                                ctx._context.stroke(p);
                                                ctx._context.fill(p, 'evenodd');
                                            }}
                                        />)}

                                        </Group>
                                    </Layer>
                                </Stage>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item style={{ marginBottom: '1rem' }}>
                        <Button startIcon={<ScannerIcon />} endIcon={startProcess && <CircularProgress />} disabled={startProcess} onClick={handleClickProcess} color="primary" variant='contained'>Vectoriser</Button>
                    </Grid>
                    {svg && !startProcess && (originalHeight || targetHeight) && (originalWidth || targetWidth) && <Grid item style={{ marginLeft: '1rem' }}>
                        <GreenButton disabled={!targetHeight || !targetWidth || errorH || errorW} onClick={handleClickValidate} color="primary" variant='contained'>Valider</GreenButton>
                    </Grid>}
                    <Grid item style={{ marginLeft: 'auto' }}>
                        <Button onClick={handleClose} color="primary" variant='contained'>
                            Annuler
                        </Button>
                    </Grid>
                </Grid>

            </DialogActions>

        </Dialog >
    )
}

export default ModalProcessImageVecto