import React, { useCallback, useContext, useState, useEffect } from 'react'
import { AppBar, Box, Button, Drawer, Grid, IconButton, List, ListItem, ListItemText, makeStyles, Toolbar, useMediaQuery, useTheme } from '@material-ui/core'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import { useHistory } from 'react-router-dom';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import logo from '../assets/images/logo.png'
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { BasketContext } from '../contexts/BasketContext';
import { useGetBasket } from '../contexts/BasketContext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';

const useStyles = makeStyles((theme) => ({

  bar: {
    background: 'white',
    //boxShadow: "rgb(17 17 26 / 10%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 8px 24px, rgb(17 17 26 / 10%) 0px 16px 48px",
    //borderBottom: `5px solid ${theme.palette.primary.main}`
  },
  logo: {

    [theme.breakpoints.down("sm")]: {
      height: '20px',
      cursor: 'pointer'
    },
    [theme.breakpoints.up("md")]: {
      height: '50px',
      cursor: 'pointer'
    },
    [theme.breakpoints.up("lg")]: {
      height: '50px',
      cursor: 'pointer'
    },
  }

}))

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

function NavBar() {

  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated()
  const auth = useAuthUser()
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const [basket, dispatch] = useContext(BasketContext)

  const { getBasket } = useGetBasket()

  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handelClickLoginLogout = useCallback(
    () => {
      if (isAuthenticated()) {
        history.push('/logout')
      } else {
        history.push('/login')
      }
    },
    [history, isAuthenticated],
  )

  const handelClickCart = useCallback(
    () => {
      history.push('/cart')
    },
    [history],
  )

  useEffect(() => {
    getBasket()

  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handelClickMenuItem = useCallback(
    (target) => {
      setOpen(false)
      history.push('/control/' + target)
    },
    [history],
  )

  const itemInBasket = basket.reduce((a, c) => {
    if (c.type !== 'accessory') {
      return a + c.quantity
    }
    return a;
  }, 0)
  return (
    <AppBar position="relative" className={classes.bar} >
      <Toolbar >
        {
          isAuthenticated() && auth()?.role === 0
            ?
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
            >
              <MenuIcon color="primary" />
            </IconButton>
            : null
        }

        {!isDownSm && <img onClick={() => history.push('/')} style={{ height: '65px', cursor: 'pointer' }} src={logo} alt="logidriel" />}
        <Grid container spacing={1} alignItems="center" justify="flex-end">
          {isAuthenticated() && <Grid item>
            <Button onClick={() => history.push('/')} size="small" color='primary' variant="outlined">Créer un pièce</Button>

          </Grid>}
          <Grid item>
            <Button onClick={handelClickLoginLogout} size="small" color='primary' variant="outlined">{isAuthenticated() ? isDownSm ? <ExitToAppIcon /> : 'Deconnexion' : isDownSm ? <VpnKeyIcon /> : ' Se connecter'}</Button>
          </Grid>

          {
            isAuthenticated() ?
              <>
                <Grid item>
                  <Button color='primary' size="small" variant='outlined' onClick={() => history.push('/profil')}>Mon compte</Button>
                </Grid>
                <Grid item>
                  {auth().role === 0 ? <Button color='primary' size="small" variant='outlined' onClick={() => history.push('/control')}>{isDownSm ? <DashboardIcon /> : 'Dashboard'}</Button> : null}
                </Grid>
                <Grid item>
                  <IconButton style={{ marginLeft: 'auto', height: '35px' }} aria-label="cart" onClick={handelClickCart} >
                    <StyledBadge badgeContent={itemInBasket} color="primary">
                      <ShoppingBasketIcon />
                    </StyledBadge>
                  </IconButton>
                </Grid>
              </>
              : null

          }

        </Grid>
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
        >
          <div>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <List>
            <ListItem button onClick={() => handelClickMenuItem('estimates')}>
              <ListItemText primary="Devis" />
            </ListItem>
            <ListItem button onClick={() => handelClickMenuItem('orders')}>
              <ListItemText primary="Commandes" />
            </ListItem>
            <ListItem button onClick={() => handelClickMenuItem('invoices')}>
              <ListItemText primary="Factures" />
            </ListItem>
            <ListItem button onClick={() => handelClickMenuItem('param')}>
              <ListItemText primary="Personnalisation" />
            </ListItem>
            <ListItem button onClick={() => handelClickMenuItem('users')}>
              <ListItemText primary="Clients" />
            </ListItem>
            <ListItem button onClick={() => handelClickMenuItem('catalog')}>
              <ListItemText primary="Catalogue de formes" />
            </ListItem>
            <ListItem button onClick={() => handelClickMenuItem('accessory')}>
              <ListItemText primary="Accessoires" />
            </ListItem>
            <ListItem button onClick={() => handelClickMenuItem('clientContact')}>
              <ListItemText primary="Contact Client" />
            </ListItem>
          </List>
        </Drawer>
      </Toolbar>

    </AppBar>
  )
}

export default NavBar
