import React, { useContext, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import Paper from '@material-ui/core/Paper';
import { MaterialsContext } from '../contexts/MaterialsContext'
import { Tab, Tabs, Button, Grid, CircularProgress, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import TableCellEditable from './TableCellEditable';
import ModalAddMaterial from './ModalAddMaterial';
import ModalDeleteMaterial from './ModalDeleteMaterial';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { MaterialnameSchema, MaterialDensitySchema, ShadenameSchema, ThicknessValueSchema, MaxWidtdMaterialSchemas } from '../modules/validation'
import TableCellFileSelect from './TableCellFileSelect';
import TableCellColorSelect from './TableCellColorSelect';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DialogImagePreview from './Dialog/DialogImagePreview';
import { SnackbarContext } from '../contexts/SnackbarContext';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5)
  },
  titleTab: {
    marginTop: theme.spacing(3)
  },
  enptyMaterialText: {
    display: 'flex',
    alignItems: 'center'
  },
  tablePaper: {
    padding: theme.spacing(2)
  },
  tableTitle: {
    marginTop: theme.spacing(2)
  }
}));

function MaterialHandler() {

  const [materials, dispatch] = useContext(MaterialsContext);
  const [selectMaterialTab, setselectMaterialTab] = useState(null);
  const [selectShadesTab, setselectShadesTab] = useState(null);
  const [selectThicknessTab, setselectThicknessTab] = useState(null);
  const [selectThickness, setselectThickness] = useState(null);
  const [selectDeveloped, setselectDeveloped] = useState(null);
  const [materialName, setmaterialName] = useState('')
  const [materialDensity, setmaterialDensity] = useState('');
  const [materialAvailableWidth, setmaterialAvailableWidth] = useState([]);
  const [shadeName, setshadeName] = useState('');
  const [thicknessValue, setthicknessValue] = useState('')
  const [developedValue, setdevelopedValue] = useState('')
  const [workforceValue, setWorkforceValue] = useState('')
  const [priceValue, setPriceValue] = useState('')
  const [diagOpen, setdiagOpen] = useState(false);
  const [diagDelOpen, setdiagDelOpen] = useState(false);
  const [typeToAdd, settypeToAdd] = useState(null)
  const [typeToDel, settypeToDel] = useState(null)
  const [isImporting, setIsImporting] = useState(false)
  const [isModalPreviewImageOpen, setisModalPreviewImageOpen] = useState(false);
  const [fileNameForImagePreview, setfileNameForImagePreview] = useState('');
  const [, dispatchSnackbar] = useContext(SnackbarContext);
  const [, dispachMaterial] = useContext(MaterialsContext);

  const [openModal, setOpenModal] = useState(false);
  const [tempWidths, setTempWidths] = useState([]);

  const openWidthModal = (material) => {
    setTempWidths([...material.availableWidth]);
    setOpenModal(true);
  };

  const closeWidthModal = () => setOpenModal(false);

  const handleAddWidth = () => {
    setTempWidths([...tempWidths, 1000]); // Ou une autre valeur par défaut
  };

  const handleRemoveWidth = (index) => {
    const newWidths = [...tempWidths];
    newWidths.splice(index, 1);
    setTempWidths(newWidths);
  };

  const handleSaveWidths = () => {
    handelClickEditedMatrial('availableWidth', tempWidths);
    closeWidthModal();
  };

  const classes = useStyles();

  const handelChangeMaterialTab = useCallback(
    (e, n) => {
      setselectMaterialTab(n)
      setselectShadesTab(null)
      setselectThickness(null)
      setselectDeveloped(null);
      setselectThicknessTab(null)
    },
    [],
  )

  const handelChangeShadeTab = useCallback(
    (e, n) => {
      setselectShadesTab(n);
      setselectThickness(null);
      setselectDeveloped(null);
      setselectThicknessTab(null)
    },
    [],
  )
  const handelChangeThicknessTab = useCallback(
    (e, n) => {

      setselectThicknessTab(n)
      setselectDeveloped(null);
    },
    [],
  )

  const materialsTabs = useCallback(
    () => {
      return materials.length > 0 ? materials.map(material => <Tab key={material._id} label={material.name} value={material._id} />) : <Typography className={classes.enptyMaterialText} variant="subtitle2" align='center'>Aucun: cliquez sur "Ajouter"</Typography>
    },
    [classes.enptyMaterialText, materials],
  )

  const shadesTabs = useCallback(
    () => {
      const material = materials.find(material => material._id === selectMaterialTab)
      return material.shades.map((shade) => <Tab key={shade._id} label={shade.name} value={shade._id} />)
    },
    [materials, selectMaterialTab],
  )

  const thicknessTab = useCallback(
    () => {
      const material = materials.find(material => material._id === selectMaterialTab)
      const shade = material.shades.find((shade) => shade._id === selectShadesTab)
      return shade.thicknesses.map(thickness => <Tab key={thickness._id} label={thickness.value} value={thickness._id} />)
    },
    [materials, selectMaterialTab, selectShadesTab],
  )

  const developedTabs = useCallback(
    () => {
      const material = materials.find(material => material._id === selectMaterialTab)
      const shade = material.shades.find((shade) => shade._id === selectShadesTab)
      const thickness = shade.thicknesses.find((thickness) => thickness._id === selectThicknessTab)
      return thickness.developed.map(dev => <Tab key={dev._id} label={dev.value} value={dev._id} />)
    },
    [materials, selectMaterialTab, selectShadesTab, selectThicknessTab],
  )

  const handelChangeMaterialName = useCallback(
    (e) => {
      setmaterialName(e.target.value)
    },
    [],
  )

  const handelChangeMaterialDensity = useCallback(
    (e) => {
      setmaterialDensity(e.target.value)
    },
    [],
  )

  const handelChangeMaterialAvailableWidth = useCallback(
    (e) => {
      setmaterialAvailableWidth(e.target.value)
    },
    [],
  )

  const handelChangeShadelName = useCallback(
    (e) => {
      setshadeName(e.target.value)
    },
    [],
  )


  const handelChangeThicknessValue = useCallback(
    (e) => {
      setthicknessValue(e.target.value)
    },
    [],
  )

  const handelChangeDeveloppedValue = useCallback(
    (e) => {
      setdevelopedValue(e.target.value)
    }, [],
  )

  const handelChangeWorkforceValue = useCallback(
    (e) => {
      setWorkforceValue(e.target.value)
    }, [],
  )

  const handelChangePriceValue = useCallback(
    (e) => {
      setPriceValue(e.target.value)
    }, [],
  )

  const handelClickEditedMatrial = useCallback(
    (key, value) => {
      (async () => {
        const updateMaterial = { ...materials.find(material => material._id === selectMaterialTab), [key]: value }
        try {
          await axios.put(process.env.REACT_APP_BASE_API + '/material/' + selectMaterialTab, updateMaterial)
          dispatch({
            type: 'EDIT_MATERIAL_VALUE',
            payload: { id: selectMaterialTab, key, value }
          })
        } catch (e) {
          console.err('error POST')
        } finally {
          setmaterialName(null)
          setmaterialDensity(null)
        }

      })()


    },
    [dispatch, materials, selectMaterialTab],
  )

  const handelClickEditedShade = useCallback(
    (materialKey, key, value) => {

      (async () => {
        const materialToUpdate = materials.map(
          material => material._id !== materialKey ? material : {
            ...material, shades: material.shades.map(
              shade => shade._id !== selectShadesTab ? shade : { ...shade, [key]: value }
            )
          }
        ).find(material => material._id === materialKey)

        try {
          await axios.put(process.env.REACT_APP_BASE_API + '/material/' + selectMaterialTab, materialToUpdate)
          dispatch({
            type: 'SET_MATERIAL',
            payload: { idMaterial: selectMaterialTab, material: materialToUpdate }
          })
        } catch (e) {
          console.err('error POST')
        } finally {
          setshadeName(null)
        }

      })()
    }, [dispatch, materials, selectMaterialTab, selectShadesTab]
  )

  const handelClickEditedThickness = useCallback(
    (key, value) => {
      (async () => {
        const materialToUpdate = materials.map(
          material => material._id !== selectMaterialTab ? material : {
            ...material, shades: material.shades.map(
              shade => shade._id !== selectShadesTab ? shade : {
                ...shade, thicknesses: shade.thicknesses.map(
                  thickness => thickness._id !== selectThicknessTab ? thickness : { ...thickness, [key]: value }
                )
              }
            )
          }
        ).find(material => material._id === selectMaterialTab)
        try {
          await axios.put(process.env.REACT_APP_BASE_API + '/material/' + selectMaterialTab, materialToUpdate)
          dispatch({
            type: 'SET_MATERIAL',
            payload: { idMaterial: selectMaterialTab, material: materialToUpdate }
          })
        } catch (e) {
          console.err('error POST')
        } finally {
          setthicknessValue(null)
        }

      })()
    }, [dispatch, materials, selectMaterialTab, selectShadesTab, selectThicknessTab]
  )

  const handelClickEditedDeveloped = useCallback(
    (key, value) => {
      (async () => {
        const materialToUpdate = materials.map(
          material => material._id !== selectMaterialTab ? material : {
            ...material, shades: material.shades.map(
              shade => shade._id !== selectShadesTab ? shade : {
                ...shade, thicknesses: shade.thicknesses.map(
                  thickness => thickness._id !== selectThicknessTab ? thickness : {
                    ...thickness, developed: thickness.developed.map(
                      developed => developed._id !== selectDeveloped ? developed : { ...developed, [key]: value }
                    )
                  }
                )
              }
            )
          }
        ).find(material => material._id === selectMaterialTab)
        try {
          await axios.put(process.env.REACT_APP_BASE_API + '/material/' + selectMaterialTab, materialToUpdate)
          dispatch({
            type: 'SET_MATERIAL',
            payload: { idMaterial: selectMaterialTab, material: materialToUpdate }
          })
        } catch (e) {
          console.err('error POST')
        } finally {
          setWorkforceValue(null)
          setPriceValue(null)
          setdevelopedValue(null)
        }

      })()
    }, [dispatch, materials, selectDeveloped, selectMaterialTab, selectShadesTab, selectThicknessTab]
  )

  const getNbShades = useCallback(
    () => {
      return materials.find(material => material._id === selectMaterialTab).shades.length
    },
    [materials, selectMaterialTab],
  )

  const getNbThickness = useCallback(
    () => {
      return materials.find(material => material._id === selectMaterialTab).shades.find(shade => shade._id === selectShadesTab).thicknesses.length
    },
    [materials, selectMaterialTab, selectShadesTab],
  )
  const getNbDeveloped = useCallback(
    () => {
      return materials.find(material => material._id === selectMaterialTab).shades.find(shade => shade._id === selectShadesTab).thicknesses.find(thickness => thickness._id === selectThicknessTab).developed.length
    },
    [materials, selectMaterialTab, selectShadesTab, selectThicknessTab],
  )

  const openModalImagePreview = (fileName) => {
    if (fileName) {
      setfileNameForImagePreview(fileName)
      setisModalPreviewImageOpen(true)
    }
  }

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    // Vérifier si le fichier est un CSV
    if (file.type !== "text/csv") {
      return;
    }

    // Créer un FormData pour préparer le fichier pour l'upload
    const formData = new FormData();
    formData.append("file", file);
    setselectMaterialTab(null)
    setselectShadesTab(null)
    setselectThicknessTab(null)

    setselectThickness(null)
    setselectDeveloped(null);
    // Envoyer le fichier à l'API
    try {
      setIsImporting(true);
      const response = await axios.post(process.env.REACT_APP_BASE_API + '/material/importmaterials', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const materials = await axios.get(process.env.REACT_APP_BASE_API + '/material')

      dispachMaterial({
        type: 'SET_MATERIALS',
        payload: materials.data.message
      })

      dispatchSnackbar({
        type: 'SET_SNACKBAR',
        payload: {
          open: true,
          content: 'Importation réussie',
          type: 'success',
          duration: 4000
        }
      })

    } catch (error) {
    } finally {
      event.target.value = null;
      setIsImporting(false)
    }
  };


  const materialsInformations = useCallback(
    () => {
      const material = materials.find(material => material._id === selectMaterialTab)
      return material ? <TableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nom</TableCell>
              <TableCell align="center">Densité</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Longueurs max</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow>
              <TableCellEditable yupValidation={MaterialnameSchema} onCickEdited={() => handelClickEditedMatrial('name', materialName)} value={materialName} onChange={handelChangeMaterialName} icon={<EditIcon fontSize='small' />}>{material.name}</TableCellEditable>
              <TableCellEditable yupValidation={MaterialDensitySchema} onCickEdited={() => handelClickEditedMatrial('density', Number(materialDensity))} value={materialDensity} onChange={handelChangeMaterialDensity} icon={<EditIcon fontSize='small' />}>{material.density}</TableCellEditable>
              <TableCellFileSelect onCickEdited={(fileName) => handelClickEditedMatrial('image', fileName)}>{material.image && <VisibilityIcon onClick={() => openModalImagePreview(material.image)} />} </TableCellFileSelect>
              <TableCell>
                <Button variant='contained' onClick={() => openWidthModal(material)}>Configurer</Button>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer> : null
    },
    [handelChangeMaterialDensity, handelChangeMaterialName, handelClickEditedMatrial, materialDensity, materialName, materials, selectMaterialTab, materialAvailableWidth],
  )

  const shadesInformations = useCallback(
    () => {
      const material = materials.find(material => material._id === selectMaterialTab)
      const shade = material.shades.find((shade) => shade._id === selectShadesTab)
      return <TableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nom</TableCell>
              <TableCell align="center">Couleur</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow>
              <TableCellEditable yupValidation={ShadenameSchema} onCickEdited={() => handelClickEditedShade(selectMaterialTab, 'name', shadeName)} value={shadeName} onChange={handelChangeShadelName} icon={<EditIcon fontSize='small' />}>{shade.name}</TableCellEditable>
              <TableCellColorSelect onCickEdited={(color) => handelClickEditedShade(selectMaterialTab, 'color', color)} value={shade.color}></TableCellColorSelect>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    },
    [handelChangeShadelName, handelClickEditedShade, materials, selectMaterialTab, selectShadesTab, shadeName],
  )

  const thicknessInformation = useCallback(
    () => {
      const material = materials.find(material => material._id === selectMaterialTab)
      const shade = material.shades.find((shade) => shade._id === selectShadesTab)
      const thickness = shade.thicknesses.find(thickness => thickness._id === selectThicknessTab)

      return <TableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Epaisseur <small>(mm)</small></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow>
              <TableCellEditable onCickEdited={() => handelClickEditedThickness('value', thicknessValue)} yupValidation={ThicknessValueSchema} value={thicknessValue} onChange={handelChangeThicknessValue} icon={<EditIcon fontSize='small' />}>{thickness.value}</TableCellEditable>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    },
    [handelChangeThicknessValue, handelClickEditedThickness, materials, selectMaterialTab, selectShadesTab, selectThicknessTab, thicknessValue],
  )

  const developedInformation = useCallback(
    () => {
      const material = materials.find(material => material._id === selectMaterialTab)
      const shade = material.shades.find((shade) => shade._id === selectShadesTab)
      const thickness = shade.thicknesses.find(thickness => thickness._id === selectThicknessTab)
      const developped = thickness.developed.find(developed => developed._id === selectDeveloped)

      return <TableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Valeur</TableCell>
              <TableCell align="center">Prix</TableCell>
              <TableCell align="center">Main d'oeuvre</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow>
              <TableCellEditable onCickEdited={() => handelClickEditedDeveloped('value', developedValue)} yupValidation={ThicknessValueSchema} value={developedValue} onChange={handelChangeDeveloppedValue} icon={<EditIcon fontSize='small' />}>{developped.value}</TableCellEditable>
              <TableCellEditable onCickEdited={() => handelClickEditedDeveloped('price', priceValue)} yupValidation={ThicknessValueSchema} value={priceValue} onChange={handelChangePriceValue} icon={<EditIcon fontSize='small' />}>{developped.price}</TableCellEditable>
              <TableCellEditable onCickEdited={() => handelClickEditedDeveloped('workforce', workforceValue)} yupValidation={ThicknessValueSchema} value={workforceValue} onChange={handelChangeWorkforceValue} icon={<EditIcon fontSize='small' />}>{developped.workforce}</TableCellEditable>

            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    }, [developedValue, handelChangeDeveloppedValue, handelChangePriceValue, handelChangeWorkforceValue, handelClickEditedDeveloped, materials, priceValue, selectDeveloped, selectMaterialTab, selectShadesTab, selectThicknessTab, workforceValue]
  )

  return (
    <Paper className={classes.root} elevation={0}>
      <div>

        <Typography variant="h5" align='center' color='primary'>Gestion des matériaux</Typography>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}>

          <Button
            variant="outlined"
            color="primary"
            component="label"
            style={{ marginRight: '2rem' }}
            disabled={isImporting}
          >
            Importer
            {isImporting ? <CircularProgress style={{ marginLeft: '10px' }} size={22} /> : <ArrowUpwardIcon />}
            <input
              type="file"
              disabled={isImporting}
              hidden
              onChange={handleFileUpload}
            />
          </Button>
        </div>
        <form className={classes.root} noValidate autoComplete="off">
          <Paper elevation={0}>
            <Grid container direction="row" justify='space-between'>
              <Typography display="block" variant="h6" style={{ margin: '0 1rem', width: '100%' }} align='center'>Matériaux</Typography>

            </Grid>


            <Tabs
              value={selectMaterialTab || 0}
              onChange={handelChangeMaterialTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >


              {
                materialsTabs()
              }
            </Tabs>
            {
              selectMaterialTab ?
                materialsInformations()
                : null
            }
          </Paper>
          {

            selectMaterialTab ?
              <Paper elevation={0}>
                <Grid container direction="row" justify='space-between' className={classes.tableTitle}>
                  <Typography style={{ width: '100%' }} variant="h6" align='center'>RAL</Typography>
                </Grid>
                <Tabs
                  value={selectShadesTab || 0}
                  onChange={handelChangeShadeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  style={{ marginTop: '1rem' }}
                  variant="scrollable"
                  scrollButtons="auto"

                >
                  {
                    shadesTabs()
                  }

                </Tabs>
                {
                  selectShadesTab ?
                    shadesInformations()
                    : null
                }
              </Paper>
              : null
          }
          {
            selectShadesTab ?
              <Paper elevation={0}>
                <Grid container direction="row" justify='space-between' className={classes.tableTitle}>
                  <Typography style={{ width: '100%' }} variant="h6" align='center'>Epaisseurs</Typography>
                </Grid>
                <Tabs
                  value={selectThicknessTab || 0}
                  onChange={handelChangeThicknessTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  style={{ marginTop: '1rem' }}
                >
                  {
                    thicknessTab()
                  }
                </Tabs>
                {
                  selectThicknessTab ?
                    thicknessInformation()
                    : null
                }
              </Paper>
              : null
          }
          {
            selectThicknessTab ?
              <Paper elevation={0}>
                <Grid container direction="row" justify='space-between' className={classes.tableTitle}>
                  <Typography style={{ width: '100%' }} variant="h6" align='center'>Développé</Typography>
                </Grid>
                <Tabs
                  value={selectDeveloped || 0}
                  onChange={(e, n) => setselectDeveloped(n)}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="off"

                  style={{ marginTop: '1rem' }}
                >
                  {
                    developedTabs()
                  }
                </Tabs>
                {
                  selectDeveloped ?
                    developedInformation()
                    : null
                }
              </Paper>
              : null
          }



        </form>
      </div>
      {diagOpen && <ModalAddMaterial open={diagOpen} setdiagOpen={setdiagOpen} type={typeToAdd} materialId={selectMaterialTab} shadeId={selectShadesTab} />}
      {diagDelOpen && <ModalDeleteMaterial open={diagDelOpen} setselectThickness={setselectThickness} selectThickness={selectThickness} setselectMaterialTab={setselectMaterialTab} setselectShadesTab={setselectShadesTab} setdiagOpen={setdiagDelOpen} type={typeToDel} settypeToDel={settypeToDel} materialId={selectMaterialTab} shadeId={selectShadesTab} thicknessId={selectThickness} />}
      {isModalPreviewImageOpen && <DialogImagePreview open={isModalPreviewImageOpen} fileName={fileNameForImagePreview} setOpen={setisModalPreviewImageOpen} />}
      <Dialog open={openModal} onClose={closeWidthModal}>
        <DialogTitle>Configurer les longueurs disponibles</DialogTitle>
        <DialogContent>
          {tempWidths.map((width, index) => (
            <div key={index}>
              <TextField
                value={width}
                onChange={(e) => {
                  const newWidths = [...tempWidths];
                  newWidths[index] = e.target.value;
                  setTempWidths(newWidths);
                }}
                placeholder="Entrez une valeur"
              />
              <IconButton onClick={() => handleRemoveWidth(index)}><RemoveIcon /></IconButton>
            </div>
          ))}
          <IconButton onClick={handleAddWidth}><AddIcon /></IconButton>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeWidthModal}>Quitter</Button>
          <Button variant="outlined" onClick={handleSaveWidths} color="primary">Valider</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default MaterialHandler
