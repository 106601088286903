import { Button, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { Grid, makeStyles, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import React, { useCallback, useContext, useMemo } from 'react'
import { BasketContext } from '../contexts/BasketContext';
import CartLine from './CartLine';
import { useState } from 'react';
import CartLinePO from './CartLinePO';

const useStyle = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),

    },
    cardContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    priceContainer: {
        marginBottom: theme.spacing(4)
    },
    pricePaper: {
        padding: theme.spacing(2)
    }
}))

function OrderData({ setActiveStep, setConfirmationDialogOpen, setCartType, getTotalPrice, vatRate, loading }) {

    const [baskets, dispatch] = useContext(BasketContext);

    const filtredBaskets = useMemo(() => {
        return baskets.filter(b => b.type !== 'accessory')
    }, [baskets])

    const handleChange = (event) => {
        setCartType(event.target.value);
    };

    const handleClickButton = (type) => {
        setCartType(type);
        //setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setConfirmationDialogOpen(true);

    }


    const classes = useStyle()
    const getItems = useCallback(
        () => {

            return filtredBaskets?.map(item => <Grid xs={11} sm={4} item>{item.type === 'accessory' ? <CartLinePO control={false} key={item._id} item={item} /> : <CartLine control={false} key={item._id} item={item} />}</Grid>)
        },
        [filtredBaskets],
    )



    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper elevation={0} className={classes.paper}>
                    <Grid container spacing={2} justify='center' className={classes.cardContainer}>
                        {getItems()}
                    </Grid>

                    <Grid spacing={10} container justify="center" alignItems="baseline" className={classes.priceContainer} >
                        <Grid item>
                            <Typography align="center" variant="button" display="block" gutterBottom color="secondary">
                                Prix Total HT
                            </Typography>
                            <Typography variant="h5" align='center' gutterBottom display='block' color='primary'>
                                {`${Number(getTotalPrice()).toFixed(2)}`} €
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography align="center" variant="button" display="block" gutterBottom color="secondary">
                                Montant TVA {`${Number(vatRate)}`} %
                            </Typography>
                            <Typography variant="h5" align='center' gutterBottom display='block' color='primary'>
                                {`${Number((getTotalPrice() / 100) * vatRate).toFixed(2)}`} €
                            </Typography>

                        </Grid>
                        <Grid item>
                            <Typography align="center" variant="button" display="block" gutterBottom color="secondary">
                                Prix Total TTC
                            </Typography>
                            <Typography variant="h5" align='center' gutterBottom display='block' color='primary' style={{ fontWeight: 'bold' }}>
                                {`${Number((getTotalPrice()) * (1 + (vatRate / 100))).toFixed(2)}`} €
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justify='center' className={classes.cardContainer}>
                        <Grid item>
                            <Button color="primary" variant="outlined" onClick={() => setActiveStep((a) => a - 1)}>retour</Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={loading} color="primary" variant="contained" onClick={() => handleClickButton('estimate')}>Obtenir un devis</Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={loading} color="primary" variant="contained" onClick={() => handleClickButton('order')}>Passer Commande</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default OrderData
