import axios from "axios";

const getFileExtension = (filename) => {
  const [_, extension] = filename.match(/[^\\]*\.(\w+)$/)
  return extension
}


const displayFormatedPrice = (price) => Number(price).toFixed(2).replace(/\./, ',')

const calculPriceWithVat = (price, vat = 20) => price * (1 + (vat / 100));

const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const groupPiecesByProperties = (orders) => {

  const properties = ['materialId', 'shadeId', 'thicknessId'];
  const groupedPieces = {};

  for (const order of orders) {
    for (const piece of order.content) {
      const key = properties.map(prop => piece[prop]).join('-');

      if (!groupedPieces[key]) {
        groupedPieces[key] = [];
      }

      groupedPieces[key].push(piece);
    }
  }

  return groupedPieces;
}


const sendPiecesToApi = async (groupedPieces) => {
  const baseUrl = 'http://localhost:8081';
  const cutWidth = 0.3;
  const method = 'greedy';
  const panels = {
    Panel1: {
      width: 1000,
      height: 1000,
    },
  };

  for (const groupKey in groupedPieces) {
    const pieces = groupedPieces[groupKey];
    const items = {};

    for (const piece of pieces) {
      const itemId = `${groupKey}-${piece.name}`;
      items[itemId] = {
        width: +piece.maxWidth,
        height: +piece.maxHeight,
        can_rotate: false,
      };
    }

    const payload = {
      cut_width: cutWidth,
      min_initial_usage: true,
      panels,
      items,
    };

    try {
      await axios.post(`${process.env.REACT_APP_BASE_API}/calculate/nesting`, payload);
    } catch (error) {
      console.error(`Error while processing group ${groupKey}:`, error.message);
    }
  }
}

const convertDate = (inputFormat) => {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat)
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
}


const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')           // Remplace les espaces par des tirets
    .replace(/[^\w\-]+/g, '')       // Supprime tous les caractères non alphanumériques sauf les tirets
    .replace(/\-\-+/g, '-')         // Remplace les tirets multiples par un seul
    .replace(/^-+/, '')             // Supprime les tirets du début
    .replace(/-+$/, '');            // Supprime les tirets de la fin
}


export {
  getFileExtension,
  displayFormatedPrice,
  calculPriceWithVat,
  dataURLtoBlob,
  groupPiecesByProperties,
  sendPiecesToApi,
  convertDate,
  slugify
}