import { Helper } from 'dxf'


const parsedxf = (str) => {
    const helper = new Helper(str)
    return helper.parsed
};

const dxftoPolylines = (str) => {
    const helper = new Helper(str)
    return helper.toPolylines()
};

const dxftoSVG = (str) => {
    const helper = new Helper(str)
    return helper.toSVG()
};

const getlayers = (str) => {
    const helper = new Helper(str)
    const layers = helper?.parsed?.tables?.layers
    if (layers) return Object.keys(layers)
    else return []
};

const deleteLayersInHeader = (dxf, layers) => {
    dxf = dxf.replace(/\r?\n|\r/g, "\n");
    let layersSection = dxf.match(/(?:TABLE\n {2}2\nLAYER\n)(.|\n)*?(?=ENDTAB)/)[0]
    layersSection = layersSection.replace('TABLE\n  2\n');

    let sections = layersSection.match(/(?:LAYER)(.|\n)*?(?:\n {2}0\n)/g)
    layers.forEach(layer => {
        const regex = `  2\n${layer}\n`;
        sections = sections.filter(section => !section.match(new RegExp(regex)));
    })

    layersSection = sections.join('');
    return dxf.replace(/(?:TABLE\n {2}2\nLAYER\n)(.|\n)*?(?=ENDTAB)/, 'TABLE\n  2\n' + layersSection);
}

const deleteLayersfromDxf = (dxf, layers) => {
    dxf = dxf.replace(/\r?\n|\r/g, "\n");
    let entitesSection = dxf.match(/(?:ENTITIES\n)(.|\n)*?(?=ENDSEC)/)[0];
    entitesSection = entitesSection.replace('ENTITIES\n', '');
    let entities = entitesSection.match(/(?: {2}0)(.|\n)*?(?= {2}0)/gm)
    layers.forEach(layer => {
        const regex = `  8\n${layer}\n`
        entities = entities.filter(entity => !entity.match(new RegExp(regex)))

    })
    entitesSection = entities.join('');
    entitesSection += '  0\n';
    dxf = dxf.replace(/(?:ENTITIES\n)(.|\n)*?(?=ENDSEC)/, 'ENTITIES\n' + entitesSection)
    return dxf;
};

const createLayer = (dxf, layerName = 'gravure', color = 2) => {

    const layerToAdd = `LAYER
  2
${layerName}
 70
    0
 62
    ${color}
  6
CONTINUOUS
  0
`;

    dxf = dxf.replace(/\r?\n|\r/g, "\n");
    let layersSection = dxf.match(/(?:TABLE\n {2}2\nLAYER\n)(.|\n)*?(?=ENDTAB)/)[0];
    if (layersSection) {
        layersSection = layersSection.replace('TABLE\n  2\n');
        let sections = layersSection.match(/(?:LAYER)(.|\n)*?(?:\n {2}0\n)/g);
        sections.push(layerToAdd);
        layersSection = sections.join('');
        return dxf.replace(/(?:TABLE\n {2}2\nLAYER\n)(.|\n)*?(?=ENDTAB)/, 'TABLE\n  2\n' + layersSection);
    }
    return dxf;
};

const replaceLayer_ = (dxf, layersToReplace, replaceBy) => {
    if (layersToReplace && layersToReplace.length) {
        dxf = dxf.replace(/\r?\n|\r/g, "\n");

        dxf = deleteLayersInHeader(dxf, layersToReplace)
        layersToReplace.forEach(layerToReplace => {
            const regex = `  8\n${layerToReplace}\n`
            dxf = dxf.replace(new RegExp(regex, 'g'), `  8\n${replaceBy}\n`)
        })

    }
    return dxf
}

const getMText = (parsedDxf) => parsedDxf.entities.filter(entity => entity.type === 'MTEXT')


export { parsedxf, dxftoPolylines, dxftoSVG, getMText, getlayers, deleteLayersfromDxf, deleteLayersInHeader, createLayer, replaceLayer_ }