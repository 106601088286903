import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogValidation from '../../Dialog/DialogValidation';

const useStyles = makeStyles({
  root: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column'
  },
  subTitle: {
    fontWeight: 'bold'
  },
  media: {
    height: 140,
    objectFit: 'contain'
  },
  truncatedText: {
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  }
});

function AccessoryCard({ data, handleValidateDelete, delectable, selectable, onSelect, handleEditAccessory }) {

  const [isModelOpen, setisModelOpen] = useState(false);

  const classes = useStyles();

  const handleCancel = () => {
    setisModelOpen(false);
  }


  const handleClickDelete = async () => {
    setisModelOpen(true);
  }

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  const getTruncatedText = (text, length = 30) => {
    if (text.length > length) {
      return text.substring(0, length) + "...";
    }
    return text;
  };


  return (
    <>
      <Card className={classes.root}>
        <ConditionalWrapper condition={selectable} wrapper={children => <CardActionArea onClick={() => onSelect(data._id)}>{children}</CardActionArea>} >

          <CardMedia
            component="img"
            height="140"
            className={classes.media}
            image={`${process.env.REACT_APP_BASE_API}/download/svg/${data.file}`}
            title={`svg ${data.name}`}
          />
          <CardContent style={{ flex: '1' }}>
            <Typography
              gutterBottom
              variant="h6"
              className={classes.truncatedText}
              title={data.name} // Ceci affiche le nom complet lors du survol
            >
              {getTruncatedText(data.name)}
            </Typography>
          </CardContent>
          <CardActions>
            {delectable && <IconButton color="primary" onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>}
            <IconButton color="primary" onClick={() => handleEditAccessory(data)}>
              <EditIcon />
            </IconButton>
          </CardActions>
        </ConditionalWrapper>
      </Card >
      <DialogValidation open={isModelOpen} handleCancel={handleCancel} handleValidate={() => handleValidateDelete(data._id)} />
    </>
  )
}

export default AccessoryCard