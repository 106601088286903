import React, { useContext, useCallback, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'
import { makeStyles } from '@material-ui/core/styles';
import { useAddAccessoriesBasket, useAddItemBasket } from '../../../contexts/BasketContext';
import { grey } from '@material-ui/core/colors';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { slugify } from '../../../modules/utils';
import { CatalogContext } from '../../../contexts/CatalogContext';
import { AccessoriesContext } from '../../../contexts/AccessoriesContext';

const useStyles = makeStyles((theme) => ({
    priceContainer: {
        textAlign: 'center'
    },
    typoTotal: {
        fontWeight: 'bold'
    },
    buttonContainer: {
        marginTop: theme.spacing(2)
    }
}))

function ConfiguratorDisplayPrice({ refStage }) {

    const [configurator, dispatch] = useContext(ConfiguratorContext)
    const [{ catalogs, selectedCatalog, catalogConfiguration }] = useContext(CatalogContext);
    const { addItemBasket } = useAddItemBasket()
    const [{ accessories }] = useContext(AccessoriesContext);
    const { addAccessoriesBasket } = useAddAccessoriesBasket()
    const [basketLoading, setbasketLoading] = useState(false)
    const classes = useStyles()
    const handelAddBasket = useCallback(
        () => {
            (async () => {
                setbasketLoading(true)
                try {
                    const catalog = catalogs.find(catalog => catalog._id === selectedCatalog);

                    for (const contalogConfig of catalogConfiguration) {

                        const lengthEntity = contalogConfig.configuration.find(item => item.entity.type === 'longer');
                        if (lengthEntity && lengthEntity.value) {
                            const maxWidth = lengthEntity.value || 0;
                            let maxHeight = 0;
                            let nbFolding = 0;

                            contalogConfig.configuration.forEach(data => {

                                if (data.entity.type === 'line') {
                                    maxHeight += Number(data.value)
                                } else if (data.entity.type === 'angle') {
                                    nbFolding++
                                }
                            });

                            const length = (maxWidth * 2) + (maxHeight * 2)

                            const labelValueMap = contalogConfig.configuration.map(({ entity, value }) => {
                                return {
                                    name: entity.name,
                                    value
                                }
                            })


                            const accessoriesToAdd = [];

                            const filtredAccessories = Object.keys(contalogConfig.accessories).filter(a => contalogConfig.accessories[a].qte > 0)
                            for (const accessoryId of filtredAccessories) {
                                const accessory = accessories.find(({ _id }) => _id === accessoryId);

                                accessoriesToAdd.push({
                                    _id: accessory._id,
                                    extLibele: accessory.name,
                                    quantity: parseInt(contalogConfig.accessories[accessoryId].qte),
                                    //price: accessory.price,
                                    refExt: accessory.ref,
                                    image: accessory.file
                                })
                            }


                            await addItemBasket({
                                image: catalog.file,
                                length,
                                nbChains: 4,
                                materialId: configurator.materialId,
                                shadeId: configurator.shadeId,
                                thicknessId: configurator.thicknessId,
                                maxWidth,
                                maxHeight,
                                quantity: contalogConfig.quantity,
                                nbFolding,
                                catalogLabelValueMap: labelValueMap,
                                catalogExcel: catalog.excel,
                                clientRef: contalogConfig.refClient,
                                paintDirection: contalogConfig.paintDirection,
                                accessories: accessoriesToAdd
                            })
                        }
                    }

                    setbasketLoading(false)

                } catch (e) {

                }
            })()
        },
        [refStage, configurator.mainForm.width, configurator.isFromCatalog, configurator.model, configurator.length, configurator.nbChains, configurator.materialId, configurator.shadeId, configurator.thicknessId, configurator.realWidth, configurator.realHeight, configurator.nbPiece, configurator.nbFolding, configurator.realWeight, configurator.y3d, configurator.x3d, configurator.z3d, configurator.labelValueMap, configurator.excel, configurator.clientRef, configurator.accessories, addItemBasket, catalogs, selectedCatalog],
    )

    const isButtonDisabled = () => !!(!configurator.materialId || !configurator.shadeId || !configurator.thicknessId || !configurator.nbPiece)

    return (
        <Button id="add-to-cart" disabled={isButtonDisabled()} onClick={handelAddBasket} variant="contained" color='secondary' startIcon={<AddShoppingCartIcon />}>Ajouter au panier {basketLoading ? <CircularProgress color={grey[50]} size={20} /> : null} </Button>
    )
}

export default ConfiguratorDisplayPrice
