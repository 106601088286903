import React, { useEffect, useContext, useState, useRef, useMemo, useCallback } from 'react'
import uniqid from 'uniqid';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery, Grid, Paper } from '@material-ui/core';
import CatalogControlPannel from './CatalogControlPannel';
import { CatalogContext, useGetCatalog } from '../../../contexts/CatalogContext';
import CatalogDrawingPannel from './CatalogDrawingPannel';
import { AccessoriesContext, useGetAccessories } from '../../../contexts/AccessoriesContext';
import ConfiguratorSelectAccessories from '../Configurator/ConfiguratorSelectAccessories';
import DisplayCatalog from './DisplayCatalog';
import DialogConfigurationMenu from '../../Dialog/DialogConfigurationMenu';
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext';
import { MaterialsContext } from '../../../contexts/MaterialsContext';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0

    },
    mainGrid: {
        display: 'flex',
        height: '100%'
    },
    drawer: {
        //backgroundColor: '#f2f2f2',
        maxHeight: 'calc(100vh - 65px)',
        overflow: 'auto',
        width: () => theme.breakpoints.down('sm') ? '100%' : '33.33%'
    },
    drawerFull: {
        width: '66.66%'
    },
    sideDrawlerFull: {
        width: '33.33%'
    },
    sideDrawler: {
        position: 'absolute',
        zIndex: 9,
        height: '100vh',
        transform: 'translateX(-100%)',
        transition: 'all 500ms'
    },
    drawlerButton: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        background: 'white',
        borderRadius: '25%',
        padding: '4px',
        height: '35px',
        width: '35px'
    },
    drawlerButtonContainer: {
        zIndex: 10,
        top: '10px',
        left: '10px',
        background: 'white',
        padding: '5px',
        borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    controlPannelContainer: {
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },

        height: `calc(100vh${process.env.REACT_APP_IFRAME_MODE ? '' : ' - 69px'})`,
        overflow: 'auto',
        position: 'relative',
        backgroundImage: "radial-gradient( circle 343px at 46.3% 47.5%, rgba(242,242,242,1) 0%, rgba(241,241,241,1) 72.9% )"
    }

}))

// eslint-disable-next-line react-hooks/exhaustive-deps
const fontsList = [
    {
        key: "BlackOpsOne",
        name: "Black Ops One (Pochoir)"
    },
    {
        key: "EmblemaOne-Regular",
        name: "EmblemaOne Regular (Pochoir)"
    },
    {
        key: "old_school_united_stencil",
        name: "Old school united stencil (Pochoir)"
    },
    {
        key: "StardosStencil-Bold",
        name: "StardosStencil Bold (Pochoir)"
    },
    {
        key: "Behind-the-Painter",
        name: "Behind the Painter (Attachée)"
    },
    {
        key: "Cathrina-Belisha",
        name: "Cathrina Belisha (Attachée)"
    },
    {
        key: "MontserratSubrayada-Bold",
        name: "MontserratSubrayada Bold (Attachée)"
    },
    {
        key: "arial-black",
        name: "Arial Black (Découpée)"
    },
    {
        key: "Forte",
        name: "Forte (Découpée)"
    },

    {
        key: "NewRocker-Regular",
        name: "NewRocker Regular (Découpée)"
    },
    {
        key: "arial",
        name: "Arial (Gravée)"
    },
    {
        key: "comic",
        name: "Comic (Gravée)"
    },
    {
        key: "times",
        name: "Times (Gravée)"
    }
];


function Catalog() {
    const classes = useStyles();
    const [{ catalogs, step, selectedCatalog, catalogConfiguration }, dispatchCatalog] = useContext(CatalogContext)

    const [{ accessories }] = useContext(AccessoriesContext)
    const [configurator, dispatch] = useContext(ConfiguratorContext);
    const [materials] = useContext(MaterialsContext)

    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const [, setisMenuExpended] = useState(true);
    const [textModels, settextModels] = useState([]);
    const [openDialog, setopenDialog] = useState(true);
    const [firstVisit, setfirstVisit] = useState(true);
    const [catalog, setCatalog] = useState(null);
    const refStage = useRef(null);

    const { getCatalog } = useGetCatalog();
    const { getAccessories } = useGetAccessories()

    /* useEffect(() => {
        if (!configurator.isFromCatalog) {

            dispatch({
                type: 'RESET_SOFT'
            })
            dispatchConfigurator({
                type: 'RESET'
            })
        } else {
            dispatchConfigurator({
                type: 'RESET_SOFT'
            })
        }

    }, []); */

    const haveCompletedConfiguration = useCallback(
        () => {
            return configurator.materialId && configurator.shadeId && configurator.thicknessId && configurator.nbPiece && configurator.size
        },
        [configurator.materialId, configurator.nbPiece, configurator.shadeId, configurator.size, configurator.thicknessId],
    )

    const defaultRowData = useCallback(() => {
        const configuration = catalog?.entities.map(entity => {
            let currentValue = null;
            if (entity.type === 'longer') {
                currentValue = configurator.size;
            } else {
                currentValue = catalogConfiguration.find(config => config.entityId === entity._id)?.value;
            }
            return { entityId: entity._id, entity, value: currentValue || entity.default || entity.min };
        }) || [];
        return {
            id: uniqid(),
            quantity: 1,
            paintDirection: catalog.paintDirection || 'inside',
            accessories: [],
            configuration
        };
    }, [catalog, catalogConfiguration, configurator]);

    useEffect(() => {
        if (isDownSm) {
            setisMenuExpended(!!!selectedCatalog)
        }

    }, [isDownSm, selectedCatalog])

    useEffect(() => {
        if (!accessories || !accessories.length) {
            getAccessories()
        }
        getCatalog()
    }, []);

    useEffect(() => {
        if (haveCompletedConfiguration()) {
            setopenDialog(false)
            setfirstVisit(false)
        }
    }, [catalogs, configurator.materialId, configurator.shadeId, configurator.thicknessId, haveCompletedConfiguration, materials])

    useEffect(() => {
        const foundCatalog = catalogs.find(catalog => catalog._id === selectedCatalog);
        setCatalog(foundCatalog);
    }, [catalogs, selectedCatalog]);

    useEffect(() => {
        if (catalog && catalog.entities) {
            dispatchCatalog({
                type: 'SET_CATALOG_CONFIGURATIONS',
                payload: [defaultRowData()]
            });

            const nbFolding = catalog.entities.filter(entity => entity.type === 'angle').length;
            dispatch({
                type: 'SET_VALUE',
                payload: { key: 'nbFolding', data: nbFolding }
            });
        }
    }, [catalog])


    const accessoriesOfSelectedCatalog = useMemo(() => catalogs.find(({ _id }) => _id === selectedCatalog)?.accessories, [catalogs, selectedCatalog])
    return (
        <main className={classes.root}>

            <Grid container className={classes.mainGrid}>
                {step > 0 && <Grid xs={12} md={3} className={classes.controlPannelContainer}>
                    <CatalogControlPannel textModels={textModels} accessoriesOfSelectedCatalog={accessoriesOfSelectedCatalog} refStage={refStage} firstVisit={firstVisit} />
                </Grid>}
                <Grid xs={step > 0 ? 9 : 12} style={{ display: 'flex' }} >
                    <Paper elevation={0} className={classes.drawer}>
                        {step === 0 && <DisplayCatalog />}
                        {step === 1 && <CatalogDrawingPannel catalog={catalog} defaultRowData={defaultRowData} />}
                        {step === 2 && <ConfiguratorSelectAccessories accessoriesOfSelectedCatalog={accessoriesOfSelectedCatalog} />}

                    </Paper>
                </Grid>
            </Grid>
            <DialogConfigurationMenu open={openDialog} setopenDialog={setopenDialog} />
        </main >
    )
}

export default Catalog
