import React, { useContext } from 'react'
import { CatalogContext } from '../../../contexts/CatalogContext'
import { Divider, FormControl, Grid, Paper, TextField, Typography, makeStyles } from '@material-ui/core'
import { ConfiguratorContext } from '../../../contexts/ConfiguratorContext'
import AutoSelectTextField from '../../Input/AutoSelectTextField'

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        width: '100%',
        paddingTop: theme.spacing(2)
    },
    card: {
        padding: theme.spacing(2)
    },
    image: {
        height: '100px',
        width: '100px',
        objectFit: 'cover'

    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: theme.spacing(2)
    },
    quantity: {
        '& input': {
            textAlign: 'center',
        }
    },
    accessoryLine: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    accessoryLineDivider: {
        width: '100%',
        marginTop: theme.spacing(3),

    },
    accessoryLineTitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontWeight: 'bold',
        color: 'white',
    },
    accessoryLineTitleContainer: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: theme.spacing(3),
    },

    previewContainer: () => theme.breakpoints.down('xs') ? { textAlign: 'center' } : null
}))

const ConfiguratorSelectAccessories = ({ accessoriesOfSelectedCatalog }) => {

    const [configurator] = useContext(ConfiguratorContext);
    const [{ catalogConfiguration }, dispatchCatalog] = useContext(CatalogContext)


    const classes = useStyles()
    const handleChangeQuantity = (e, config) => {
        const value = e.target.value
        if (+value >= 0) {

            const accessoryId = e.target.name
            let accessories = { ...config.accessories };
            accessories = { ...accessories, [accessoryId]: { qte: +value } }


            const updatedConfiguration = catalogConfiguration.map(conf =>
                conf.id === config.id
                    ? { ...conf, accessories }
                    : conf
            );
            dispatchCatalog({
                type: 'SET_CATALOG_CONFIGURATIONS',
                payload: updatedConfiguration
            });
        }



    }

    const getList = () => {
        return catalogConfiguration.map((config, index) => (
            <Grid container className={classes.accessoryLine}>
                <Grid container direction="column" >
                    <Grid item className={classes.accessoryLineTitleContainer}>
                        <Typography className={classes.accessoryLineTitle} variant="button" align="center" display="block">{config.refClient || `Pièce ${index + 1}`} - (QTE : {config.quantity})</Typography>
                    </Grid>
                    <Grid item container justify="center" spacing={2}>
                        {getAccessoriesList(config)}
                    </Grid>
                </Grid>
                <Divider className={classes.accessoryLineDivider} />
            </Grid>

        ))
    }

    const getAccessoriesList = (config) => {

        if (accessoriesOfSelectedCatalog) {
            return accessoriesOfSelectedCatalog.map((accessory =>
                <Grid item xs={2}>
                    <Paper elevation={0} className={classes.card}>
                        <Grid container direction="column" alignItems="center">
                            <Grid container direction="column" alignItems="center" item xs={12}>

                                <Grid item xs={12}>
                                    <Typography className={classes.title} variant='body2'>
                                        {accessory.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <img className={classes.image} alt='' src={`${process.env.REACT_APP_BASE_API}/download/image/${accessory.file}`} />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} justify="center">

                                <Grid item xs={6}>
                                    <AutoSelectTextField className={classes.quantity} label="quantité" fullWidth InputLabelProps={{ shrink: true }} name={accessory._id} onChange={(e) => handleChangeQuantity(e, config)} value={config.accessories[accessory._id]?.qte} />
                                </Grid>

                            </Grid>
                            <Grid item>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            ))

        }

    }
    return (
        <Grid className={classes.paperContainer} >
            {!!accessoriesOfSelectedCatalog?.length && (<>

                <Grid container>
                    {getList()}
                </Grid>

            </>)}

        </Grid>


    )
}

export default ConfiguratorSelectAccessories